import { StateCreator } from 'zustand';
import { InvoicesSlice } from '../types/invoiceSliceTypes';

const InitialInvoicesFilters = {
  first_name: '',
  last_name: '',
  email: '',
  status: '',
  date_created: null,
  refunded: ''
};

const initialState = {
  filters: InitialInvoicesFilters
};

export const invoicesSlice: StateCreator<InvoicesSlice> = (set) => ({
  invoicesSlice: initialState,
  updateInvoicesSlice: (data: any) => {
    set((state) => ({
      invoicesSlice: {
        ...state.invoicesSlice,
        ...data
      }
    }));
  },
  resetInvoicesSlice: () => set({ invoicesSlice: initialState })
});
