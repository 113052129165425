import React from 'react';
import { BsBoxes } from 'react-icons/bs';
import { TbNumbers } from 'react-icons/tb';
import { FcInTransit } from 'react-icons/fc';
import { FaRegCheckCircle } from 'react-icons/fa';

const OrdersKPIs = ({
  ordersAnalytics,
  dataLoading
}: {
  ordersAnalytics: any;
  dataLoading: boolean;
}) => {
  const {
    total_order_count = 0,
    order_with_tracking_number = 0,
    order_count_in_transit = 0,
    total_order_delivered_count = 0
  } = ordersAnalytics ?? {};

  const details = [
    {
      label: 'Total Orders',
      value: total_order_count,
      icon: <BsBoxes className='text-brown' />
    },
    {
      label: 'Orders with Tracking Number',
      value: order_with_tracking_number,
      icon: <TbNumbers className='text-slate-600' />
    },
    {
      label: 'Orders in Transit',
      value: order_count_in_transit,
      icon: <FcInTransit />
    },
    {
      label: 'Total Orders Delivered',
      value: total_order_delivered_count,
      icon: <FaRegCheckCircle className='text-green' />
    }
  ];

  return (
    <div className='flex flex-row gap-4'>
      {details.map(({ label, value, icon }, index) => (
        <div
          key={index}
          className='flex flex-row gap-2 border border-orange bg-light-orange rounded-lg p-1.5'
        >
          <span className='text-3xl'>{icon}</span>
          <div className='flex flex-col justify-end'>
            <div className='font-semibold text-orange text-sm'>{label}</div>
            <div className='font-medium text-slate-600 text-sm text-end mx-2'>
              {dataLoading ? 'loading...' : value}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrdersKPIs;
