import { useAppStore } from 'store/store';

export const useInitialConsultationsSlice = () => {
  const initialConsultationsSlice = useAppStore(
    (state) => state.initialConsultationsSlice
  );
  const updateInitialConsultationsSlice = useAppStore(
    (state) => state.updateInitialConsultationsSlice
  );
  const resetInitialConsultationsSlice = useAppStore(
    (state) => state.resetInitialConsultationsSlice
  );

  return {
    initialConsultationsSlice,
    updateInitialConsultationsSlice,
    resetInitialConsultationsSlice
  };
};
