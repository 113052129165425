import { Input } from 'antd';
import Select from 'components/select/Select';
import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { PackagesFormInputs } from 'types/packages';
import { Controller, useForm } from 'react-hook-form';
import { medicationOptions } from 'constants/medicationOptions';
import {
  useCreatePackage,
  usePackagesNames,
  useUpdatePackage
} from '../api/useApi';

type Item = {
  name: string;
  selected: boolean;
  pb_package_id: string;
};

export default function PackageForm({ info, setIsClose }: any) {
  const formInitials = {
    pb_package_id:
      info?.pb_package_name
        .filter((item: Item) => item.selected)
        .map((item: Item) => item.pb_package_id) || '',
    medication: info?.medication || null,
    payment_plan_duration: info?.payment_plan_duration || null,
    payment_plan_amount: info?.payment_plan_amount || null,
    plan: info?.plan || '',
    invoice_amount: info?.invoice_amount || null,
    invoice_amount_redrock: info?.invoice_amount_redrock || null,
    payment_plan_amount_redrock: info?.payment_plan_amount_redrock || null,
    invoice_amount_vios: info?.invoice_amount_vios || null,
    payment_plan_amount_vios: info?.payment_plan_amount_vios || null,
    invoice_amount_starter: info?.invoice_amount_starter || null,
    invoice_amount_starter_redrock:
      info?.invoice_amount_starter_redrock || null,
    invoice_amount_starter_vios: info?.invoice_amount_starter_vios || null,
    invoice_amount_boothwyn: info?.invoice_amount_boothwyn || null,
    invoice_amount_starter_boothwyn:
      info?.invoice_amount_starter_boothwyn || null
  };

  const handleSuccess = () => {
    setIsClose();
    reset();
  };

  const { data: packagesNamesData } = usePackagesNames(!Boolean(info?.id));
  const { mutateAsync: updatePackage, isPending: isUpdatePending } =
    useUpdatePackage(info?.id, { onSuccess: handleSuccess });
  const { mutateAsync: addPackage, isPending: isAddPending } = useCreatePackage(
    { onSuccess: handleSuccess }
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields }
  } = useForm<PackagesFormInputs>({
    values: formInitials
  });

  const onSubmit = (data: any) => {
    const dirtyData: PackagesFormInputs = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof PackagesFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    if (info) {
      updatePackage(dirtyData);
    } else {
      addPackage(dirtyData);
    }
  };

  const isPending = isUpdatePending || isAddPending;

  return (
    <>
      <p className='text-base font-semibold'>Package Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6 h-[34rem] overflow-auto'>
          <div>
            <Label className='!font-normal'>Package Name</Label>
            <Controller
              name='pb_package_id'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  data={info?.pb_package_name || packagesNamesData}
                  labelKey='name'
                  valueKey='pb_package_id'
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Medication</Label>
            <Controller
              name='medication'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={medicationOptions}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>Plan</Label>
            <Controller
              name='plan'
              control={control}
              render={({ field }) => (
                <Select
                  placeholder='Select Plan'
                  {...field}
                  className='w-full h-[30px]'
                  options={[
                    { value: 'Month to month', label: 'Month To month' },
                    { value: '2 month', label: '2 month' },
                    { value: '3 month', label: '3 month' },
                    { value: '6 month', label: '6 month' },
                    { value: '9 month', label: '9 month' }
                  ]}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>
              Payment Plan Duration (Months)
            </Label>
            <Controller
              name='payment_plan_duration'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Invoice Amount ($)</Label>
            <Controller
              name='invoice_amount'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Payment Plan Amount ($)</Label>
            <Controller
              name='payment_plan_amount'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>
              Invoice Amount (Boothwyn)
            </Label>
            <Controller
              name='invoice_amount_boothwyn'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>
              Starter Invoice Amount (Boothwyn)
            </Label>
            <Controller
              name='invoice_amount_starter_boothwyn'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>
              Invoice Amount (Red Rock States)
            </Label>
            <Controller
              name='invoice_amount_redrock'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>
              Payment Plan Amount (Red Rock States)
            </Label>
            <Controller
              name='payment_plan_amount_redrock'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>
              Invoice Amount (Vios States)
            </Label>
            <Controller
              name='invoice_amount_vios'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>
              Payment Plan Amount (Vois States)
            </Label>
            <Controller
              name='payment_plan_amount_vios'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>
              Starter Invoice Amount
            </Label>
            <Controller
              name='invoice_amount_starter'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4 mb-4'>
            <Label className='!font-normal mb-1.5'>
              Invoice Amount Starter Redrock
            </Label>
            <Controller
              name='invoice_amount_starter_redrock'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4 mb-3'>
            <Label className='!font-normal mb-1.5'>
              Invoice Amount Starter Vios
            </Label>
            <Controller
              name='invoice_amount_starter_vios'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isPending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
