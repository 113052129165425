import React, { useMemo, useState } from 'react';
import Filters from './components/Filters';
import { usePbSessions } from './api/useApi';
import { useParams } from 'react-router-dom';
import Drawer from 'components/drawer/Drawer';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import { RowSelectionState } from '@tanstack/react-table';
import Pagination from 'components/pagination/Pagination';
import HeaderActionButtons from './components/HeaderActionButtons';
import VirtualFollowupsForm from 'pages/settings/pages/virtualFollowups/components/virtualFollowupForm/VirtualFollowupsForm';
import InitialConsultationsForm from 'pages/initialConsultations/components/initialConsultationForm/InitialConsultationsForm';
import {
  useArchiveData,
  useSyncVirtualFollowups
} from 'pages/settings/pages/virtualFollowups/api/useApi';
import { useFollowUpsSlice } from 'store/components/stateHooks/useFollowUpsSlice';
import { getPbSessions } from './api';
import {
  downloadXLSX,
  reorderObjectKeys,
  transformCSVData
} from 'utils/helpers/csvDownloader';
import paths from 'constants/path';

export default function Followups({
  isSingle,
  customersPageView,
  clientId
}: {
  isSingle?: boolean;
  customersPageView?: boolean;
  clientId?: string;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const { id, form_id, form_type } = useParams();
  const {
    followUpsSlice: { filters }
  } = useFollowUpsSlice();
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'date_created',
    sort_order: 'desc'
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const handleSuccess = () => {
    setRowSelection({});
  };

  const { data: PbSessionsData, isPending: sessionsLoading } = usePbSessions(
    currentPage,
    filters,
    sortingDetails,
    customersPageView ? id : undefined
  );
  const { isLoading: isSyncPending, refetch: syncVirtualFollowupsForms } =
    useSyncVirtualFollowups();
  const { mutateAsync: archiveData, isPending: isArchivePending } =
    useArchiveData({ queryKeys: ['pb_sessions'], onSuccess: handleSuccess });

  const pbSessionsMemorizedData = useMemo(() => {
    return PbSessionsData?.form_requests ?? [];
  }, [PbSessionsData?.form_requests]);

  const [downloadingData, setDownloadingData] = useState(false);
  const downloadTasksCSV = async () => {
    const keyOrder = ['first_name', 'last_name', 'email', 'placed_order'];
    setDownloadingData(true);
    let response = await getPbSessions(
      currentPage,
      filters,
      sortingDetails,
      '',
      true
    );
    if (response) {
      const reorderedResponse = reorderObjectKeys(response, keyOrder);
      downloadXLSX(transformCSVData(reorderedResponse), 'sessions');
    }
    setDownloadingData(false);
  };

  return (
    <div
      className={`${customersPageView ? 'bg-white pt-5' : 'p-5 bg-[#FAFBFC]'}`}
    >
      <div
        className={`border border-light-grey rounded-xl p-2.5 ${!customersPageView && 'bg-white'}`}
      >
        <div className='flex justify-between'>
          <h1
            className={`text-xl font-semibold ${customersPageView && 'mb-5'}`}
          >
            Follow-ups
          </h1>
          {!customersPageView && (
            <HeaderActionButtons
              archiveData={archiveData}
              selectedRows={selectedRows}
              isSyncPending={isSyncPending}
              isArchivePending={isArchivePending}
              syncVirtualFollowupsForms={syncVirtualFollowupsForms}
              downloadingData={downloadingData}
              downloadTasksCSV={downloadTasksCSV}
            />
          )}
        </div>
        {!customersPageView && <Filters setCurrentPage={setCurrentPage} />}

        <div
          className={`overflow-auto ${customersPageView ? 'h-[calc(100vh_-_430px)]' : 'h-[calc(100vh_-_300px)]'}`}
        >
          <DataTable
            rowSelection={rowSelection}
            isLoading={sessionsLoading}
            data={pbSessionsMemorizedData}
            setRowSelection={setRowSelection}
            columns={Columns(
              sortingDetails,
              setSortingDetails,
              archiveData,
              customersPageView,
              id
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={sessionsLoading}
        totalCount={PbSessionsData?.pagination.total_count}
        totalPage={PbSessionsData?.pagination.total_pages}
        perPage={PbSessionsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Drawer
        styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
        width='90%'
        open={Boolean(isSingle)}
        button={null}
        closeIcon={null}
      >
        {form_type === 'ic' ? (
          <InitialConsultationsForm
            isSingle={isSingle}
            formId={customersPageView ? String(form_id) : String(id)}
            backUrl={
              clientId ? `/customers/${clientId}/forms` : paths.followups
            }
          />
        ) : (
          <VirtualFollowupsForm
            isSingle={Boolean(isSingle)}
            formId={customersPageView ? String(form_id) : String(id)}
            backUrl={
              clientId ? `/customers/${clientId}/forms` : paths.followups
            }
          />
        )}
      </Drawer>
    </div>
  );
}
