import React from 'react';
import paths from 'constants/path';
import { Link } from 'react-router-dom';
import formatDate from 'utils/helpers/date';
import { SmsLogSortingDetails, SmsLogsResponse } from 'types/smsLogs';
import { ColumnDef } from '@tanstack/react-table';
import { FaSort } from 'react-icons/fa';

export const Columns = (
  sortingDetails: SmsLogSortingDetails,
  setSortingDetails: any
): ColumnDef<SmsLogsResponse>[] => [
  {
    header: 'To',
    accessorKey: 'to'
  },
  {
    header: 'From',
    accessorKey: 'from'
  },
  {
    header: 'Message',
    accessorKey: 'message'
  },
  {
    header: 'Status',
    accessorKey: 'status'
  },
  {
    header: 'Sms sid',
    accessorKey: 'sms_sid'
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Created At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Updated At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'updated_at',
              sort_order:
                sortingDetails?.sort_by === 'updated_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        <Link
          to={paths.customer(info.row.original.client_record_id)}
          className='text-orange underline underline-offset-4'
        >
          Customer
        </Link>
        <Link
          to={info.row.original.log_url}
          className='text-orange underline underline-offset-4'
          target='_blank'
        >
          Twilio
        </Link>
      </div>
    )
  }
];
