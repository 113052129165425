import { InvoicesFilterInput, InvoicesSortingDetails } from 'types/invoices';
import { request } from 'utils/api';

export const getInvoices = async (
  currentPage: number,
  params?: InvoicesFilterInput,
  sortingDetails?: InvoicesSortingDetails,
  clientId?: string
) => {
  const data = await request({
    url: '/invoices',
    method: 'GET',
    params: {
      page: currentPage,
      per_page: clientId ? 10 : null,
      ...params,
      ...sortingDetails,
      client_record_id: clientId || null
    }
  });
  return data;
};

export const syncInvoices = async () => {
  const data = await request({
    url: '/invoices/sync-invoices',
    method: 'GET'
  });
  return data;
};

export const handleInvoiceAutomation = async (id: string) => {
  const data = await request({
    url: `/invoices/automation/${id}`,
    method: 'PUT',
    data: {}
  });
  return data;
};

export const refreshInvoice = async (id: string) => {
  const data = await request({
    url: `/invoices/check-status/${id}`,
    method: 'PUT',
    data: {}
  });
  return data;
};
