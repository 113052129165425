import { getCookie } from 'utils/cookies';

export const getDefaultConsultantId = () => {
  const pbConsultantId = getCookie('pb_consultant_id');
  const role = getCookie('role');
  const default_pb_consultant_id =
    role === 'Super Admin' || !pbConsultantId || pbConsultantId === 'null'
      ? ''
      : String(pbConsultantId);
  return default_pb_consultant_id;
};
