import { useEffect, useMemo, useState } from 'react';
import { Input, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import Radio from 'components/radio/Radio';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import VFPackageDetails from './VFPackageDetails';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillSendPlusFill } from 'react-icons/bs';
import Textarea from 'components/textarea/Textarea';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'components/datepicker/DatePicker';
import { medicationOptions } from 'constants/medicationOptions';
import WysiwygEditor from 'components/wysiwygEditor/WysiwygEditor';
import { getAdjustDoseOptions } from '../helpers/adjustDoseOptions';
import { getDoseValueOptions } from 'utils/helpers/medicationDoseOptions';
import {
  useArchiveData,
  useCreateSessionNotes,
  useRefreshAIPrompts,
  useSendEmail,
  useUpdateReviewedVFs,
  useUpdateVirtualFollowups,
  useVirtualFollowup
} from '../../api/useApi';
import {
  VirtualFollowupsFormInputs,
  VirtualFollowupsResponse
} from 'types/virtualFollowups';
import {
  ArrowRightOutlined,
  EditOutlined,
  RedoOutlined
} from '@ant-design/icons';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ReviewedFormDetails from 'pages/initialConsultations/components/initialConsultationForm/ReviewedFormDetails';
import Checkbox from 'components/checkbox/Checkbox';
import { useUnlinkedInvoices } from 'pages/initialConsultations/api/useApi';
import formatDate from 'utils/helpers/date';

export default function VirtualFollowupsForm({
  isSingle,
  formId,
  backUrl
}: {
  isSingle: boolean;
  formId: string;
  backUrl: string;
}) {
  const navigate = useNavigate();
  const {
    data: VirtualFollowupData,
    isPending: loadingForm,
    isError
  } = useVirtualFollowup(formId, Boolean(isSingle));
  if (isError) {
    navigate(-1);
  }

  const virtualFollowUpMemorizedData = useMemo(() => {
    return VirtualFollowupData?.form_request ?? [];
  }, [VirtualFollowupData?.form_request]);

  const [formData, setFormData] = useState<
    VirtualFollowupsResponse | undefined
  >(virtualFollowUpMemorizedData);

  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    setFormData(virtualFollowUpMemorizedData);
  }, [virtualFollowUpMemorizedData]);

  const formInitials = formData?.id
    ? {
        medication: formData.medication,
        name: formData.name,
        pb_session_id: formData.pb_session_id,
        weight: formData.weight,
        dose: formData.dose,
        id: formData.id,
        satisfaction: formData.satisfaction,
        appetite_suppression: formData.appetite_suppression,
        new_dose: formData.new_dose,
        weight_trends: formData.weight_trends,
        side_effects: formData.side_effects,
        message_to_patient: formData.message_to_patient,
        vf_interval_history: formData.vf_interval_history,
        vf_weight_loss_history: formData.vf_weight_loss_history,
        current_package: formData.current_package,
        current_goals: formData.current_goals,
        switch_med_dose: '',
        goals: formData.goals,
        preferred_plan: '',
        adjust_plan: formData.new_plan ? 'Change Plan' : 'No change',
        discount: '',
        order_date: formData.order_date,
        invoice_paid_amount: formData.invoice_paid_amount,
        last_paid_invoice: formData.last_paid_invoice,
        next_invoice_amount: formData.next_invoice_amount,
        next_payment_date: formData.next_payment_date,
        plan_name: formData.plan_name,
        invoice: formData.pb_invoice?.id,
        new_plan: formData.new_plan,
        new_goals: formData.new_goals,
        no_order_required: formData.no_order_required,
        adjust_dose:
          formData.discontinue_medication === true
            ? 'discontinue_medication'
            : formData.new_dose && !formData.new_medication
              ? formData.new_dose
              : formData.new_medication
                ? 'switch_medication'
                : formData.dose,
        new_medication: formData.new_medication,
        send_review: formData.send_review,
        trustpilot_status:
          formData.trustpilot_status === 'Sent'
            ? 'Sending'
            : formData.trustpilot_status
      }
    : {
        medication: '',
        name: '',
        weight: '',
        pb_session_id: '',
        dose: '',
        id: null,
        satisfaction: '',
        appetite_suppression: '',
        new_dose: '',
        weight_trends: '',
        side_effects: '',
        message_to_patient: '',
        vf_interval_history: '',
        vf_weight_loss_history: '',
        switch_med_dose: '',
        current_package: '',
        current_goals: '',
        goals: '',
        preferred_plan: '',
        adjust_plan: 'No change',
        discount: '',
        order_date: '',
        invoice_paid_amount: '',
        last_paid_invoice: '',
        next_invoice_amount: '',
        next_payment_date: '',
        no_order_required: false,
        plan_name: '',
        invoice: '',
        new_plan: '',
        new_goals: '',
        adjust_dose: '',
        new_medication: '',
        send_review: false,
        trustpilot_status: 'Pending'
      };

  const [refreshingColumn, setRefreshingColumn] = useState('');
  const [isEditReviewedForm, setIsEditReviewedForm] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields }
  } = useForm<VirtualFollowupsFormInputs>({
    values: formInitials
  });
  const adjustMedicationDose = watch('adjust_dose');
  const is_without_order_date_checked = watch('no_order_required');
  const enteredOrderDate = watch('order_date');
  const selectedDose = watch('dose');
  const selectedMedication = watch('medication');
  const selectedNewMedication = watch('new_medication');
  const adjustPlan = watch('adjust_plan');
  const selectedInterval = watch('new_plan');
  const medicationDoseOptions = ['Semaglutide', 'Wegovy', 'Ozempic'].includes(
    String(selectedMedication)
  )
    ? ['0.25', '0.5', '1', '1.7', '2.4']
    : ['2.5', '5', '7.5', '10', '12.5', '15'];

  const isFormLocked =
    (formData?.status === 'Reviewed' && !isEditReviewedForm) || loadingForm;

  function onSuccess(data: any, type?: string) {
    switch (type) {
      case 'promptRefreshed':
        let updatedField = data.payload?.column_name;
        let updatedValue = data?.form_request?.[updatedField];
        setValue(updatedField, updatedValue);
        break;
      case 'sessionCreated':
        reset();
        setFormData(data.form_request);
        break;
      default:
        break;
    }
  }

  function validateFormData(data: VirtualFollowupsFormInputs) {
    data = data.order_date
      ? { ...data, order_date: dayjs(data.order_date).format('MM/DD/YYYY') }
      : { ...data };
    if (data.adjust_dose !== 'switch_medication') {
      data = { ...data, new_medication: null, new_dose: null };
      // delete data.new_medication
      // delete data.new_dose;
    }
    if (data.adjust_dose === 'discontinue_medication') {
      data = {
        ...data,
        discontinue_medication: true,
        new_medication: null,
        new_dose: null
      };
    }
    if (data.adjust_plan === 'No change') {
      data = { ...data, new_plan: null, new_goals: null };
      // delete data.new_plan;
      // delete data.new_goals;
    } else if (data.new_plan === 'Month to month') {
      data = { ...data, new_goals: null };
      // delete data.new_goals;
    }
    if (
      data.adjust_plan === 'Change Plan' ||
      data.adjust_dose === 'switch_medication' ||
      is_without_order_date_checked
    ) {
      data = { ...data, order_date: '' };
      // delete data.order_date;
    }
    if (data.adjust_dose === 'discontinue_medication') {
      data = { ...data, order_date: '' };
    }
    const baseData = {
      ...data,
      discount: 'No Discount',
      status: 'Reviewed'
    };
    data =
      data.adjust_dose !== 'switch_medication' &&
      data.adjust_dose !== 'discontinue_medication' &&
      data.dose !== data.adjust_dose
        ? { ...baseData, new_dose: data.adjust_dose }
        : baseData;
    delete data.adjust_dose;
    delete data.adjust_plan;
    return data;
  }

  const { mutateAsync: updateVirtualFollowups, isPending: isUpdatePending } =
    useUpdateVirtualFollowups(String(formData?.id));
  const { mutateAsync: updateReviewedVF, isPending: updatingReviewedVF } =
    useUpdateReviewedVFs(String(formData?.id));
  const { data: unlinkedInvoices, isPending: loadingInvoices } =
    useUnlinkedInvoices(String(formData?.client_record_id));

  const { mutateAsync: sendEmail, isPending: sendingEmail } = useSendEmail();

  const { mutateAsync: refreshAIPrompts, isPending: isRefreshPending } =
    useRefreshAIPrompts({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const { mutateAsync: archiveData, isPending: isArchivePending } =
    useArchiveData();

  const {
    mutateAsync: createSessionNotes,
    isPending: isSessionCreationPending
  } = useCreateSessionNotes({
    onSuccess: onSuccess,
    id: String(formData?.id)
  });

  const onSubmit = async (data: VirtualFollowupsFormInputs) => {
    let dirtyData: Partial<VirtualFollowupsFormInputs> = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof VirtualFollowupsFormInputs;
      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (
          typeof value === 'string' ||
          value === undefined ||
          typeof value === 'boolean'
        ) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        } else if (value === null) {
          dirtyData[objKey] = '';
        }
      }
    });
    if (formData) {
      let validData = await validateFormData(data);
      if (isEditReviewedForm) {
        await updateReviewedVF(validData);
        setIsEditReviewedForm(false);
      } else {
        await updateVirtualFollowups(validData);
        !isSaveClicked && createSessionNotes({ status: 'Reviewed' });
      }
    }
  };

  const isPending = isUpdatePending || isSessionCreationPending;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-row'>
        <div className='w-1/2 border-r'>
          <div className='border-b flex justify-between'>
            {loadingForm ? (
              <span>Loading details...</span>
            ) : (
              <div className='flex text-base font-semibold mr-9 pb-2 gap-2'>
                <span>Patient Name -</span>
                <a
                  href={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}`}
                  rel='noopener noreferrer'
                  target='_blank'
                  className='flex items-center gap-2'
                >
                  <p className='text-orange'>{formData?.name}</p>
                </a>
              </div>
            )}
            {!formData?.completed &&
              (formData?.status === 'Pending' ||
                formData?.status === 'Form Pending') && (
                <button
                  disabled={sendingEmail}
                  type='button'
                  className='mx-3 flex text-white border border-orange bg-orange rounded-xl p-1 mb-2 disabled:opacity-50 w-[11rem] flex justify-center'
                  onClick={() => sendEmail(String(formData?.id))}
                >
                  {sendingEmail ? (
                    <Spin className='' />
                  ) : (
                    <>
                      Send Reminder Email
                      <BsFillSendPlusFill className='mx-1 mt-1' />
                    </>
                  )}
                </button>
              )}
            {!isEditReviewedForm && formData?.status === 'Reviewed' && (
              <button
                className='text-orange mx-3'
                onClick={() => setIsEditReviewedForm(true)}
              >
                <EditOutlined className='mr-1' />
                Edit
              </button>
            )}
          </div>
          <div className={`m-3 ml-0 ${isFormLocked && 'opacity-50'}`}>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-2 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>Interval History</span>
                  {isRefreshPending &&
                  refreshingColumn === 'vf_interval_history' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('vf_interval_history');
                        setRefreshingColumn('vf_interval_history');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='vf_interval_history'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-2 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>Weight Loss History</span>
                  {isRefreshPending &&
                  refreshingColumn === 'vf_weight_loss_history' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('vf_weight_loss_history');
                        setRefreshingColumn('vf_weight_loss_history');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='vf_weight_loss_history'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
          </div>
        </div>
        <div className='w-1/2 pl-4'>
          <div className='border-b flex flex-row justify-between'>
            <div className='flex flex-row gap-3'>
              <span className='text-base font-semibold pb-2'>Review</span>
              {formData?.status === 'Duplicate' && (
                <span className='border border-sky-500 bg-sky-100 !rounded-xl px-5 mb-2 font-[500]'>
                  Duplicate Form
                </span>
              )}
            </div>
            <Link to={backUrl}>
              <ArrowRightOutlined
                className='text-lg pr-4 pb-3'
                onClick={() => setIsEditReviewedForm(false)}
              />
            </Link>
          </div>
          {formData?.status === 'Reviewed' && (
            <ReviewedFormDetails formData={formData} />
          )}
          <div className='mt-3.5 mb-20'>
            <div className={`${isFormLocked && 'opacity-50'}`}>
              <div
                className={`flex flex-row gap-2 ${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
              >
                <div className='w-[7rem]'>
                  <Label className='!font-normal'>Medication</Label>
                  <Controller
                    name='medication'
                    control={control}
                    rules={{ required: 'Medication is Required' }}
                    render={({ field }) => (
                      <Select
                        placeholder='Select Medication'
                        {...field}
                        className='w-[7rem] mt-2'
                        options={medicationOptions}
                      />
                    )}
                  />
                  <p className='text-red text-xs'>
                    {errors?.medication?.message}
                  </p>
                </div>
                <div className='w-[6rem]'>
                  <Label className='!font-normal'>Dose</Label>
                  <Controller
                    name='dose'
                    control={control}
                    rules={{
                      validate: (value) =>
                        selectedMedication
                          ? medicationDoseOptions.includes(String(value)) ||
                            'Invalid Dose'
                          : true
                    }}
                    render={({ field }) => (
                      <Select
                        placeholder='Select Dose'
                        {...field}
                        className='w-[6rem] mt-2'
                        options={getDoseValueOptions(
                          String(selectedMedication)
                        )}
                      />
                    )}
                  />
                  {errors.dose && (
                    <p className='text-red text-xs'>{errors.dose.message}</p>
                  )}
                </div>
                <div className='w-[7rem]'>
                  <Label className='!font-normal'>Satisfaction</Label>
                  <Controller
                    disabled
                    name='satisfaction'
                    control={control}
                    render={({ field }) => (
                      <Input
                        type='text'
                        className=' mt-2 w-full h-[30px]'
                        {...field}
                      />
                    )}
                  />
                </div>
                <div>
                  <Label className='!font-normal'>Appetite suppression</Label>
                  <Controller
                    disabled
                    name='appetite_suppression'
                    control={control}
                    render={({ field }) => (
                      <Input
                        type='text'
                        className=' mt-2 w-full h-[30px]'
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className='w-[10rem]'>
                  <Label className='!font-normal'>Weight Trends</Label>
                  <Controller
                    name='weight_trends'
                    disabled
                    control={control}
                    render={({ field }) => (
                      <Textarea
                        type='text'
                        row={1}
                        className='mt-2 cursor-not-allowed'
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                className={`mb-2 ${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
              >
                <Label className='!font-normal'>Side Effects</Label>
                <Controller
                  name='side_effects'
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      key={formData?.id}
                      type='text'
                      row={2}
                      className='mt-2'
                      {...field}
                    />
                  )}
                />
              </div>
              {formData?.status === 'Reviewed' && !formData?.pb_invoice?.id && (
                <div className='mt-3.5 w-[35rem]'>
                  <Label className='!font-normal mb-1.5'>Link Invoice</Label>
                  <Select
                    disabled={loadingInvoices || isFormLocked}
                    placeholder='Select Invoice'
                    className='w-full h-[30px]'
                    options={unlinkedInvoices?.map((invoice: any) => {
                      return {
                        value: invoice.id,
                        label: (
                          <span>
                            <b>{formatDate(invoice.date_created)}</b> -{' '}
                            {invoice.invoice_number}: ${invoice.total_amount}
                          </span>
                        )
                      };
                    })}
                    allowClear={true}
                    onChange={async (val) => {
                      await updateVirtualFollowups({
                        pb_invoice_id: val,
                        hide_notification: false
                      });
                      setIsEditReviewedForm(false);
                    }}
                  />
                </div>
              )}
              <div
                className={`${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
              >
                <VFPackageDetails formData={formData} control={control} />
              </div>
              <div
                className={`mt-3 grid grid-cols-3 ${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
              >
                <div className='mb-4'>
                  <Label className='!font-normal'>Adjust Plan</Label>
                  <Controller
                    name='adjust_plan'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={[
                          {
                            label: 'No change',
                            value: 'No change'
                          },
                          {
                            label: 'Change Plan',
                            value: 'Change Plan'
                          }
                        ]}
                      />
                    )}
                  />
                </div>
                {adjustPlan === 'Change Plan' && (
                  <div className='w-[10rem] mb-3'>
                    <Label className='!font-normal'>Interval</Label>
                    <Controller
                      name='new_plan'
                      control={control}
                      rules={{ required: 'New Plan is Required.' }}
                      render={({ field }) => (
                        <Radio
                          className='w-full mt-2 h-[38px]'
                          {...field}
                          options={[
                            { value: '3 month', label: '3 month' },
                            { value: '6 month', label: '6 month' },
                            { value: 'Month to month', label: 'Month to month' }
                          ]}
                        />
                      )}
                    />
                    <p className='text-red text-sm'>
                      {errors?.new_plan?.message}
                    </p>
                  </div>
                )}
                {adjustPlan === 'Change Plan' &&
                  (selectedInterval === '3 month' ||
                    selectedInterval === '9 month') && (
                    <div className='mx-4'>
                      <Label className='!font-normal mb-1.5'>Goals</Label>
                      <Controller
                        name='new_goals'
                        control={control}
                        rules={{ required: 'New Goals is Required' }}
                        render={({ field }) => (
                          <Select
                            placeholder='Select Goal'
                            {...field}
                            className='w-full h-[30px]'
                            options={[
                              { value: 'Maintenance', label: 'Maintenance' },
                              { value: 'Escalation', label: 'Escalation' },
                              { value: 'No preference', label: 'No preference' }
                            ]}
                          />
                        )}
                      />
                      <p className='text-red text-sm'>
                        {errors?.new_goals?.message}
                      </p>
                    </div>
                  )}
              </div>
              <div
                className={`mt-3 grid ${adjustMedicationDose === 'switch_medication' ? 'grid-cols-3' : 'grid-cols-2'} ${isFormLocked && 'pointer-events-none'}`}
              >
                <div className='mb-4'>
                  <Label className='!font-normal'>Adjust Medication Dose</Label>
                  <Controller
                    name='adjust_dose'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={[
                          ...getAdjustDoseOptions(
                            String(selectedMedication),
                            Number(selectedDose)
                          ),
                          {
                            label: 'Switch Medication',
                            value: 'switch_medication'
                          },
                          {
                            label: 'Discontinue Medication',
                            value: 'discontinue_medication'
                          }
                        ]}
                      />
                    )}
                  />
                </div>
                {adjustMedicationDose === 'switch_medication' && (
                  <div className='w-[8rem]'>
                    <Label className='!font-normal'>Select Medication</Label>
                    <Controller
                      name='new_medication'
                      rules={{ required: 'New Medication is Required' }}
                      control={control}
                      render={({ field }) => (
                        <Radio
                          className='w-full mt-2 h-[38px]'
                          {...field}
                          options={medicationOptions}
                        />
                      )}
                    />
                    <p className='text-red text-sm'>
                      {errors?.new_medication?.message}
                    </p>
                  </div>
                )}
                {adjustMedicationDose === 'switch_medication' &&
                  selectedNewMedication && (
                    <div>
                      <Label className='!font-normal'>Dose</Label>
                      <Controller
                        name='new_dose'
                        control={control}
                        rules={{ required: 'New Dose is Required' }}
                        render={({ field }) => (
                          <Radio
                            className='w-full mt-2 h-[38px]'
                            {...field}
                            options={getDoseValueOptions(
                              String(selectedNewMedication)
                            )}
                          />
                        )}
                      />
                      <p className='text-red text-sm'>
                        {errors?.new_dose?.message}
                      </p>
                    </div>
                  )}
              </div>
              <div
                className={`grid grid-cols-3 mb-6 mt-3.5 ${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
              >
                <div>
                  <Label className='!font-normal'>Send Review</Label>
                  <Controller
                    name='trustpilot_status'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={[
                          { value: 'Sending', label: 'Yes' },
                          { value: 'Pending', label: 'No' }
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
              {!(
                adjustPlan === 'Change Plan' ||
                adjustMedicationDose === 'switch_medication' ||
                adjustMedicationDose === 'discontinue_medication'
              ) && (
                <>
                  {!is_without_order_date_checked && (
                    <div
                      className={`w-[14rem] mb-3 ${isFormLocked && 'pointer-events-none opacity-50'}`}
                    >
                      <Label className='!font-normal'>Order Date</Label>
                      <Controller
                        control={control}
                        name='order_date'
                        rules={
                          !is_without_order_date_checked
                            ? { required: 'Order Date is Required' }
                            : undefined
                        }
                        render={({ field }) => (
                          <DatePicker
                            className='!px-2.5 !py-1.5'
                            minDate={dayjs()}
                            format='MM-DD-YYYY'
                            {...field}
                            onChange={(date: Dayjs | null) => {
                              field.onChange(
                                date ? date.format('MM/DD/YYYY') : null
                              );
                            }}
                            value={field.value ? dayjs(field.value) : null}
                          />
                        )}
                      />
                      <span className='text-red text-xs'>
                        {errors?.order_date?.message}
                      </span>
                    </div>
                  )}
                  {formData?.id && !enteredOrderDate && (
                    <div className='flex items-center my-5 gap-2'>
                      <Controller
                        name='no_order_required'
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            className='!w-5 !h-5 text-sm'
                            id='no_order_required'
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              if (e.target.checked) {
                                clearErrors('order_date');
                              }
                            }}
                            ref={field.ref}
                          />
                        )}
                      />
                      <Label
                        htmlFor='no_order_required'
                        className='text-grey !font-normal'
                      >
                        No order needed today.
                      </Label>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={`mb-2 ${isFormLocked && 'opacity-50'}`}>
              <Label className='!font-normal mb-2'>
                <Label
                  className={`!font-normal mb-2 ${isFormLocked && 'pointer-events-none'}`}
                >
                  <span className='flex flex-row'>
                    <span>Message to Patient</span>
                    {isRefreshPending &&
                    refreshingColumn === 'message_to_patient' ? (
                      <span className='text-orange px-2 text-sm mt-[3px]'>
                        Refreshing...
                      </span>
                    ) : (
                      <RedoOutlined
                        className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                        onClick={() => {
                          refreshAIPrompts('message_to_patient');
                          setRefreshingColumn('message_to_patient');
                        }}
                      />
                    )}
                  </span>
                </Label>
              </Label>
              <Controller
                name='message_to_patient'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
          </div>
          {!isFormLocked && !isEditReviewedForm ? (
            <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
              {!['Duplicate'].includes(String(formData?.status)) && (
                <button
                  type='submit'
                  className='!w-fit py-3 px-8 bg-orange text-white py-2 rounded-lg font-semibold'
                  onClick={() => {
                    setIsSaveClicked(false);
                  }}
                >
                  {isPending && !isSaveClicked ? <Spin /> : 'Submit'}
                </button>
              )}
              {!['Duplicate'].includes(String(formData?.status)) && (
                <button
                  type='submit'
                  className='!w-fit py-3 px-8 bg-green text-white rounded-lg font-semibold'
                  onClick={() => {
                    setIsSaveClicked(true);
                  }}
                >
                  {isPending && isSaveClicked ? <Spin /> : 'Save'}
                </button>
              )}
              <Link to={backUrl}>
                <button className='!w-fit px-8 py-3 bg-grey text-white rounded-lg font-semibold'>
                  Cancel
                </button>
              </Link>
              {!['Reviewed', 'Duplicate'].includes(
                String(formData?.status)
              ) && (
                <button
                  type='button'
                  className='!w-fit py-3 px-8 bg-grey text-white py-2 rounded-lg font-semibold'
                  onClick={() => {
                    archiveData({ ids: [formData?.id], status: 'Duplicate' });
                  }}
                >
                  {isArchivePending ? <Spin /> : 'Mark Duplicate'}
                </button>
              )}
            </div>
          ) : (
            <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full text text-orange h-14 pr-9'>
              {(isEditReviewedForm || updatingReviewedVF) && (
                <button
                  type='submit'
                  className='!w-fit px-8 bg-orange text-white rounded-lg font-semibold disabled:cursor-not-allowed w-36'
                >
                  {updatingReviewedVF ? <Spin /> : 'Save'}
                </button>
              )}
              {formData?.order_note_id && (
                <Link
                  className='flex flex-row gap-1 font-semibold mt-1'
                  to={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}/notes/${formData?.order_note_id}/edit`}
                  target='_blank'
                >
                  Order Notes
                  <FaExternalLinkAlt className='text-sm cursor-pointer mt-[2.5px]' />
                </Link>
              )}
              {formData?.session_note_id && (
                <Link
                  className='flex flex-row gap-1 font-semibold mt-1'
                  to={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}/notes/${formData?.session_note_id}/edit`}
                  target='_blank'
                >
                  Session Notes
                  <FaExternalLinkAlt className='text-sm cursor-pointer mt-[2.5px]' />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
