import { slices } from './components/slices';
import { create, StateCreator } from 'zustand';
import { logger } from './components/helpers/logger';
import { Store } from './components/types';

const createStore: StateCreator<Store> = (...args) =>
  slices.reduce<Partial<Store>>(
    (acc, slice) => ({ ...acc, ...slice(...args) }),
    {}
  ) as Store;

export const useAppStore = create<Store>(
  process.env.NODE_ENV === 'development' ? logger(createStore) : createStore
);
