import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import { OrderFilterInput } from 'types/order';
import Button from 'components/buttons/Button';
import { useOrderStatuses } from '../apis/useApi';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import RangePicker from 'components/rangePicker/RangePicker';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useOrdersSlice } from 'store/components/stateHooks/useOrdersSlice';

interface FiltersProps {
  setCurrentPage: any;
}

export default function Filters({ setCurrentPage }: FiltersProps) {
  const { data: orderStatuses } = useOrderStatuses();
  const {
    ordersSlice: { filters },
    updateOrdersSlice,
    resetOrdersSlice
  } = useOrdersSlice();

  const { control, handleSubmit, reset } = useForm<OrderFilterInput>({
    defaultValues: {
      ...filters,
      start_order_date: dayjs().subtract(2, 'month').format('MM/DD/YYYY'),
      end_order_date: dayjs().format('MM/DD/YYYY')
    }
  });

  const onSubmit: SubmitHandler<OrderFilterInput> = (data) => {
    data.start_order_date = data.order_date?.[0];
    data.end_order_date = data.order_date?.[1];
    data.start_created_at = data.created_at?.[0];
    data.end_created_at = data.created_at?.[1];
    const updatedData = {
      ...data,
      order_date: data.order_date || [],
      created_at: data.created_at || [],
      start_order_date: data.order_date?.[0] || '',
      end_order_date: data.order_date?.[1] || ''
    };

    updateOrdersSlice({ filters: { ...updatedData } });
    setCurrentPage(1);
  };
  useEffect(() => {
    reset({ ...filters });
  }, [filters, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-[1.5px] items-center my-4'>
        <div>
          <Label className='text-sm !font-normal m-1'>Email</Label>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input className='h-[2rem]' type='email' {...field} />
            )}
          />
        </div>
        <div className='w-[17rem]'>
          <Label className='text-sm !font-normal m-1'>Tracking Number</Label>
          <Controller
            name='tracking_number'
            control={control}
            render={({ field }) => (
              <Input type='text' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Order Date</Label>
          <Controller
            control={control}
            name='order_date'
            render={({ field }) => (
              <RangePicker
                className='!px-2.5 !py-1.5 h-[2rem]'
                placeholder={['Start Date', 'End Date']}
                format='MM/DD/YYYY'
                {...field}
                onChange={(_, dateStrings: [string, string]) => {
                  console.log(dateStrings);
                  field.onChange(dateStrings);
                }}
                value={
                  field.value
                    ? [
                        field.value[0] ? dayjs(field.value[0]) : null,
                        field.value[1] ? dayjs(field.value[1]) : null
                      ]
                    : null
                }
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Created At</Label>
          <Controller
            control={control}
            name='created_at'
            render={({ field }) => (
              <RangePicker
                className='!px-2.5 !py-1.5 h-[2rem]'
                placeholder={['Start Date', 'End Date']}
                format='MM/DD/YYYY'
                {...field}
                onChange={(_, dateStrings: [string, string]) => {
                  console.log(dateStrings);
                  field.onChange(dateStrings);
                }}
                value={
                  field.value
                    ? [
                        field.value[0] ? dayjs(field.value[0]) : null,
                        field.value[1] ? dayjs(field.value[1]) : null
                      ]
                    : null
                }
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Status</Label>
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[120px] h-[2rem]'
                options={orderStatuses?.ups_status.map((val: string) => ({
                  label: val,
                  value: val
                }))}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Order Source</Label>
          <Controller
            name='order_source'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[120px] h-[2rem]'
                options={[
                  { label: 'Sheet', value: 'Sheet' },
                  { label: 'System Generated', value: 'System Generated' },
                  {
                    label: 'Manual-System Generated',
                    value: 'System Generated - Manual'
                  },
                  {
                    label: 'IC-System Generated',
                    value: 'System Generated - IC'
                  },
                  {
                    label: 'VF-System Generated',
                    value: 'System Generated - VF'
                  },
                  {
                    label: 'DSC-System Generated',
                    value: 'System Generated - DSC'
                  }
                ]}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Delivered</Label>
          <Controller
            name='order_delivered'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[100px] h-[2rem]'
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Sheet Addition</Label>
          <Controller
            name='added_to_sheet'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[120px] h-[2rem]'
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Pharmacy</Label>
          <Controller
            name='pharmacy'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[100px] h-[2rem]'
                options={[
                  { label: 'Hallandale', value: 'Hallandale' },
                  { label: 'Vios', value: 'Vios' },
                  { label: 'Red Rock', value: 'Red Rock' },
                  { label: 'Boothwyn', value: 'Boothwyn' }
                ]}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Send to Pharmacy</Label>
          <Controller
            name='pharmacy_order_id'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[150px] h-[2rem]'
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Order Note</Label>
          <Controller
            name='order_note'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[100px] h-[2rem]'
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
              />
            )}
          />
        </div>
        <Button className='w-max px-3 h-[35px] mt-6'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() => resetOrdersSlice()}
          className='border border-orange rounded py-2 px-2 mt-6'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
