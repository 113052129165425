import dayjs from 'dayjs';
import { StateCreator } from 'zustand';
import { OrdersSlice } from '../types/ordersSliceTypes';

const InitialOrdersFilters = {
  tracking_number: '',
  email: '',
  status: '',
  order_delivered: '',
  order_source: '',
  created_at: [],
  order_date: [
    dayjs().subtract(2, 'month').format('MM/DD/YYYY'),
    dayjs().format('MM/DD/YYYY')
  ],
  start_order_date: dayjs().subtract(2, 'month').format('MM/DD/YYYY'),
  end_order_date: dayjs().format('MM/DD/YYYY'),
  added_to_sheet: '',
  order_note: '',
  pharmacy_order_id: '',
  pharmacy: ''
};

const initialState = {
  filters: InitialOrdersFilters
};

export const ordersSlice: StateCreator<OrdersSlice> = (set) => ({
  ordersSlice: initialState,
  updateOrdersSlice: (data: any) => {
    set((state) => ({
      ordersSlice: {
        ...state.ordersSlice,
        ...data
      }
    }));
  },
  resetOrdersSlice: () => set({ ordersSlice: initialState })
});
