import { useAppStore } from 'store/store';

export const useCustomersSlice = () => {
  const customersSlice = useAppStore((state) => state.customersSlice);
  const updateCustomersSlice = useAppStore(
    (state) => state.updateCustomersSlice
  );
  const resetCustomersSlice = useAppStore((state) => state.resetCustomersSlice);

  return { customersSlice, updateCustomersSlice, resetCustomersSlice };
};
