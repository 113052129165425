import { StateCreator } from 'zustand';
import { TasksSlice } from '../types/tasksSliceTypes';
import { getDefaultConsultantId } from 'utils/helpers/defaultConsultantId';

const InitialTaskFilters = {
  name: '',
  email: '',
  completed: '',
  consultant_id: getDefaultConsultantId()
};

const initialState = {
  filters: InitialTaskFilters
};

export const tasksSlice: StateCreator<TasksSlice> = (set) => ({
  tasksSlice: initialState,
  updateTasksSlice: (data: any) => {
    set((state) => ({
      tasksSlice: {
        ...state.tasksSlice,
        ...data
      }
    }));
  },
  resetTasksSlice: () => set({ tasksSlice: initialState })
});
