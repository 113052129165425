import React from 'react';
import paths from 'constants/path';
import { FaSort } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import formatDate from 'utils/helpers/date';
import Tooltip from 'components/tooltip/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import { InitialConsultationsResponse } from 'types/initialConsultations';
import IndeterminateCheckbox from 'components/indeterminateCheckbox/IndeterminateCheckbox';
import { EyeOutlined, FolderAddOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { TbMessage2Share } from 'react-icons/tb';

export const columns = (
  archiveData: (id: string[]) => void,
  setSortingDetails: any,
  sortingDetails: any,
  setProcessingModalId: any,
  clientId?: string,
  updateHelper?: any
): ColumnDef<InitialConsultationsResponse>[] => {
  const allColumns: ColumnDef<InitialConsultationsResponse>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler()
          }}
        />
      ),
      cell: ({ row }) => (
        <div className='px-1'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler()
            }}
          />
        </div>
      )
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (info) => (
        <a
          href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
          rel='noopener noreferrer'
          target='_blank'
          className='flex items-center gap-2'
        >
          <p className='underline text-orange underline-offset-4 text-nowrap'>
            {info.getValue<string>()}
          </p>
        </a>
      )
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (info) => (
        <Link
          to={paths.customer(String(info.row.original.client_record_id))}
          onClick={() => {
            updateHelper({ customerSourceRoute: paths.initialConsultations });
          }}
          className='text-orange underline underline-offset-4'
        >
          {info.getValue<string>()}
        </Link>
      )
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Date Submitted</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'completed_at',
                sort_order:
                  sortingDetails?.sort_by === 'completed_at' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'completed_at',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: 'Service',
      accessorKey: 'service',
      cell: (info) =>
        info.getValue() ? (
          <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize w-20'>
            {info.getValue() as string}
          </p>
        ) : (
          '---'
        )
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Preferred Medication</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'preferred_medications',
                sort_order:
                  sortingDetails?.sort_by === 'preferred_medications' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'preferred_medications',
      cell: (info) =>
        info.getValue() ? (
          <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
            {capitalizeFirstLetter(info.getValue() as string)}
          </p>
        ) : (
          '---'
        )
    },
    {
      header: 'Preferred Plan',
      accessorKey: 'preferred_plan',
      cell: (info) =>
        info.getValue() && (
          <span className='text-nowrap'>
            <Tooltip title={String(info.getValue())}>
              {String(info.getValue()).length < 30
                ? String(info.getValue())
                : `${String(info.getValue()).slice(0, 30)}...`}
            </Tooltip>
          </span>
        )
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Draft Date</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'created_at',
                sort_order:
                  sortingDetails?.sort_by === 'created_at' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'created_at',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: 'Consultant',
      accessorKey: 'consultant'
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Status</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'status',
                sort_order:
                  sortingDetails?.sort_by === 'status' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'status',
      cell: (info) =>
        info.getValue() && (
          <p
            className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
          >
            {info.getValue() as string}
          </p>
        )
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Form Completed</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'completed',
                sort_order:
                  sortingDetails?.sort_by === 'completed' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'completed',
      cell: (info) => (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
        >
          {info.getValue() ? 'Yes' : 'No'}
        </p>
      )
    },
    {
      header: 'Action',
      accessorKey: 'id',
      cell: (info) => (
        <div className='flex items-center gap-3'>
          <Link
            to={
              clientId
                ? paths.customerICForm(
                    clientId,
                    String(info.row.original.id),
                    'ic'
                  )
                : paths.initialConsultation(String(info.row.original.id))
            }
            className='text-orange underline underline-offset-4'
          >
            <Tooltip title='Review details.'>
              <EyeOutlined className='cursor-pointer text-[1rem]' />
            </Tooltip>
          </Link>
          <button className='text-orange cursor-pointer whitespace-nowrap font-medium mr-0.5'>
            <Tooltip title='Send email.'>
              <TbMessage2Share
                className='cursor-pointer text-[1rem]'
                onClick={() => setProcessingModalId(String(info.getValue()))}
              />
            </Tooltip>
          </button>
          {info.row.original.status !== 'Archive' && (
            <PopupConfirm
              title='Archive Record'
              description='Are you sure you want to archive?'
              onConfirm={() => archiveData([String(info.getValue())])}
            >
              <Tooltip title='Move to archives.'>
                <FolderAddOutlined className='text-orange cursor-pointer text-[1rem]' />
              </Tooltip>
            </PopupConfirm>
          )}
          <Link
            to={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}/forms/${info.row.original.form_request_id}`}
            className='text-orange underline underline-offset-4'
            target='_blank'
          >
            <Tooltip title='View form in PB.'>
              <FaExternalLinkAlt className='text-sm cursor-pointer' />
            </Tooltip>
          </Link>
        </div>
      )
    }
  ];

  return clientId
    ? allColumns.filter(
        (column) =>
          column.id !== 'select' &&
          column.header !== 'Name' &&
          column.header !== 'Email'
      )
    : allColumns;
};
