import React, { useMemo, useState } from 'react';
import { useApiLogs } from './api/useApi';
import Filters from './components/Filters';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';

export default function ApiLogsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [apiFilters, setApiFilters] = useState({
    path: '',
    tracking_number: '',
    log_type: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const { data: apiLogsData, isLoading } = useApiLogs(
    currentPage,
    apiFilters,
    sortingDetails
  );
  const memoizedUPSLogsData = useMemo(() => {
    return apiLogsData?.api_logs ?? [];
  }, [apiLogsData?.api_logs]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>API Logs</h1>
        <Filters
          setApiFilters={setApiFilters}
          setCurrentPage={setCurrentPage}
          apiFilters={apiFilters}
        />
        <div className='overflow-auto h-[calc(100vh_-_350px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedUPSLogsData}
            columns={Columns(sortingDetails, setSortingDetails)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={apiLogsData?.pagination.total_count}
        totalPage={apiLogsData?.pagination.total_pages}
        perPage={apiLogsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
