import React from 'react';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';

interface FormFooterButtonsProps {
  isPending: boolean;
  backUrl: string;
  followUpClicked: boolean;
  showSubmitButton: boolean;
  setFollowUpClicked: (value: boolean) => void;
  formCount: number;
  submitForm: any;
  clientRecordId?: string;
}

const FormFooterButtons: React.FC<FormFooterButtonsProps> = ({
  isPending,
  backUrl,
  followUpClicked,
  showSubmitButton,
  setFollowUpClicked,
  formCount,
  submitForm,
  clientRecordId
}) => {
  return (
    <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
      <Link to={backUrl}>
        <button className='!w-fit py-3 px-8 bg-grey text-white py-2 rounded-lg font-semibold'>
          Cancel
        </button>
      </Link>
      {showSubmitButton &&
        (formCount > 1 ? (
          <PopupConfirm
            title='Warning'
            description={
              <span>
                <p>This patient has multiple forms. Click OK to submit form.</p>
                <p>
                  <Link
                    to={`/customers/${clientRecordId}/forms`}
                    className='text-orange'
                  >
                    Click here
                  </Link>{' '}
                  to check forms in patient profile.{' '}
                </p>
              </span>
            }
            onConfirm={() => submitForm()}
          >
            <button
              disabled={isPending}
              type='button'
              className='!w-fit py-3 px-8 bg-orange text-white py-2 rounded-lg font-semibold disabled:cursor-not-allowed w-36'
              onClick={() => setFollowUpClicked(false)}
            >
              {!followUpClicked && isPending ? <Spin /> : 'Submit'}
            </button>
          </PopupConfirm>
        ) : (
          <button
            disabled={isPending}
            type='submit'
            className='!w-fit py-3 px-8 bg-orange text-white py-2 rounded-lg font-semibold disabled:cursor-not-allowed w-36'
            onClick={() => setFollowUpClicked(false)}
          >
            {!followUpClicked && isPending ? <Spin /> : 'Submit'}
          </button>
        ))}
      <button
        disabled={isPending}
        type='submit'
        className='!w-fit py-3 px-8 bg-yellow-500 text-white py-2 rounded-lg font-semibold disabled:cursor-not-allowed'
        onClick={() => setFollowUpClicked(true)}
      >
        {followUpClicked && isPending ? <Spin /> : 'Follow-up'}
      </button>
    </div>
  );
};

export default FormFooterButtons;
