import React from 'react';
import formatDate from 'utils/helpers/date';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import { Controller, Control } from 'react-hook-form';
import { VirtualFollowupsResponse } from 'types/virtualFollowups';

interface VFPackageDetailsProps {
  formData: VirtualFollowupsResponse | undefined;
  control: Control;
}

const VFPackageDetails = ({ formData, control }: VFPackageDetailsProps) => {
  const fieldsLeft = [
    {
      label: 'Last Invoice Created At',
      value: formData?.invoice_created_at
        ? formatDate(formData.last_paid_invoice)
        : 'N/A'
    },
    {
      label: 'Last Invoice Paid At',
      value: formData?.last_paid_invoice
        ? formatDate(formData.last_paid_invoice)
        : 'N/A'
    },
    {
      label: 'Last Paid Amount',
      value: formData?.invoice_paid_amount
        ? `$${formData.invoice_paid_amount}`
        : 'N/A'
    }
  ];

  const fieldsRight = [
    {
      label: 'Last Order Date',
      value: formData?.last_order_date
        ? formatDate(formData.last_order_date)
        : 'N/A'
    },
    {
      label: 'Next Payment Date',
      value: formData?.next_payment_date
        ? formatDate(formData.next_payment_date)
        : 'N/A'
    },
    {
      label: 'Next Invoice Amount',
      value: formData?.next_invoice_amount
        ? `$${formData.next_invoice_amount}`
        : 'N/A'
    }
  ];

  return (
    <div className='border border-1 rounded p-2 my-3 mx-5 bg-light-orange'>
      <div className='font-medium border-b m-2 pb-1 text-xs flex justify-between'>
        <div className='w-2/3'>
          <span className='mr-1'>Current Plan:</span>
          {formData?.plan_name || 'N/A'}
        </div>
        <div className='flex w-1/3'>
          <Label className='m-2 font-semibold'>Goals</Label>
          <Controller
            name='goals'
            control={control}
            render={({ field }) => (
              <Select
                placeholder='Select Goal'
                {...field}
                className='w-full h-[30px]'
                options={[
                  { value: 'Maintenance', label: 'Maintenance' },
                  { value: 'Escalation', label: 'Escalation' }
                ]}
              />
            )}
          />
        </div>
      </div>
      <div className='flex text-xs'>
        {[fieldsLeft, fieldsRight].map((fields, index) => (
          <div
            key={index}
            className={`flex flex-col w-1/2 ${index === 0 ? 'border-r mr-3' : ''}`}
          >
            {fields.map((field, i) => (
              <span key={i} className='flex'>
                <span className='font-medium'>{field.label}:</span>
                <span className='ml-1'>{field.value}</span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VFPackageDetails;
