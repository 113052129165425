import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Textarea from 'components/textarea/Textarea';
import Input from 'components/input/Input';
import { BlacklistedFormInputs, BlacklistedResponse } from 'types/blacklisted';
import { useCreateBlacklisted, useUpdateBlacklisted } from '../api/useApi';
import Select from 'components/select/Select';

export default function BlacklistedForm({
  info,
  setIsClose
}: {
  info?: BlacklistedResponse;
  setIsClose?: any;
}) {
  const formInitials: BlacklistedFormInputs = {
    id: info?.id ?? '',
    category: info?.category ?? '',
    value: info?.value ?? '',
    address: info?.address,
    description: info?.description
  };

  const handleSuccess = () => {
    setIsClose();
    reset();
  };

  const { mutateAsync: updateBlacklistedItem, isPending: isUpdatePending } =
    useUpdateBlacklisted({ onSuccess: handleSuccess });
  const { mutateAsync: addBlacklistedItem, isPending: isAddPending } =
    useCreateBlacklisted({ onSuccess: handleSuccess });

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors }
  } = useForm<BlacklistedFormInputs>({
    defaultValues: formInitials
  });
  const selectedCategory = useWatch({ control, name: 'category' });
  const onSubmit = (data: any) => {
    const dirtyData: BlacklistedFormInputs = {};
    data.category === 'Address' && delete data.value;
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof BlacklistedFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    if (info) {
      updateBlacklistedItem({ ...dirtyData, id: info.id });
    } else {
      addBlacklistedItem(dirtyData);
    }
  };

  const isPending = isUpdatePending || isAddPending;

  return (
    <>
      <p className='text-base font-semibold'>Package Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6'>
          <div>
            <Label className='!font-normal'>Category</Label>
            <Controller
              name='category'
              rules={{ required: 'Category is required' }}
              disabled={Boolean(info?.id)}
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    { value: 'Email', label: 'Email' },
                    { value: 'Mobile', label: 'Mobile' },
                    { value: 'Address', label: 'Address' }
                  ]}
                />
              )}
            />
            <p className='text-red text-sm'>{errors?.category?.message}</p>
          </div>
          {selectedCategory !== 'Address' && (
            <div>
              <Label className='!font-normal'>Value</Label>
              <Controller
                name='value'
                rules={{ required: 'Value is required' }}
                control={control}
                render={({ field }) => (
                  <Input type='text' className='mt-2 h-[38px]' {...field} />
                )}
              />
              <p className='text-red text-sm'>{errors?.value?.message}</p>
            </div>
          )}
          {selectedCategory === 'Address' && (
            <>
              <div>
                <Label className='!font-normal'>Street</Label>
                <Controller
                  name='address.street'
                  rules={{ required: 'Street is required' }}
                  control={control}
                  render={({ field }) => (
                    <Input type='text' className='mt-2 h-[38px]' {...field} />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.address?.street?.message}
                </p>
              </div>
              <div>
                <Label className='!font-normal'>City</Label>
                <Controller
                  name='address.city'
                  rules={{ required: 'City is required' }}
                  control={control}
                  render={({ field }) => (
                    <Input type='text' className='mt-2 h-[38px]' {...field} />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.address?.city?.message}
                </p>
              </div>
              <div>
                <Label className='!font-normal'>State</Label>
                <Controller
                  name='address.state'
                  rules={{ required: 'State is required' }}
                  control={control}
                  render={({ field }) => (
                    <Input type='text' className='mt-2 h-[38px]' {...field} />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.address?.state?.message}
                </p>
              </div>
              <div>
                <Label className='!font-normal'>Country</Label>
                <Controller
                  name='address.country'
                  rules={{ required: 'Country is required' }}
                  control={control}
                  render={({ field }) => (
                    <Input type='text' className='mt-2 h-[38px]' {...field} />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.address?.country?.message}
                </p>
              </div>
              <div>
                <Label className='!font-normal'>Postal Code</Label>
                <Controller
                  name='address.postal_code'
                  rules={{ required: 'Postal code is required' }}
                  control={control}
                  render={({ field }) => (
                    <Input type='text' className='mt-2 h-[38px]' {...field} />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.address?.postal_code?.message}
                </p>
              </div>
            </>
          )}
          <div>
            <Label className='!font-normal'>Description</Label>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <Textarea
                  placeholder='Add description.'
                  row={1}
                  type='text'
                  className=' w-full mt-2 h-[38px]'
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isPending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
