import React, { useMemo, useState } from 'react';
import { useSmsLogs } from './api/useApi';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { useParams } from 'react-router-dom';
import Filters from './components/Filters';

export default function SmsLogsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();
  const [SmsLogFilters, setSmsLogFilters] = useState({
    to_number: '',
    status: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const { data: smsLogsData, isLoading } = useSmsLogs(
    currentPage,
    SmsLogFilters,
    sortingDetails,
    id
  );

  const memoizedSmsLogsData = useMemo(() => {
    return smsLogsData?.sms_logs ?? [];
  }, [smsLogsData?.sms_logs]);

  return (
    <div className={`${id ? 'bg-white' : 'p-5 bg-[#FAFBFC]'}`}>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Sms Logs</h1>
        {!id && (
          <Filters
            setFilters={setSmsLogFilters}
            setCurrentPage={setCurrentPage}
            filters={SmsLogFilters}
          />
        )}
        <div
          className={`overflow-auto ${id ? 'h-[calc(100vh_-_280px)]' : 'h-[calc(100vh_-_350px)]'}`}
        >
          <DataTable
            isLoading={isLoading}
            data={memoizedSmsLogsData}
            columns={Columns(sortingDetails, setSortingDetails)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={smsLogsData?.pagination.total_count}
        totalPage={smsLogsData?.pagination.total_pages}
        perPage={smsLogsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
