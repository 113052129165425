import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { getCookie } from 'utils/cookies';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { useUsers } from 'pages/settings/pages/users/api/useApi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { InitialConsultationsFilter } from 'types/initialConsultations';
import RangePicker from 'components/rangePicker/RangePicker';
import { useInitialConsultationsSlice } from 'store/components/stateHooks/useInitialConsultationsSlice';

interface FiltersProps {
  setCurrentPage: any;
}

export default function Filters({ setCurrentPage }: FiltersProps) {
  const role = getCookie('role');
  const { data: usersData } = useUsers(1, 30);
  const {
    initialConsultationsSlice: { filters },
    updateInitialConsultationsSlice,
    resetInitialConsultationsSlice
  } = useInitialConsultationsSlice();

  const { control, handleSubmit, reset } = useForm<InitialConsultationsFilter>({
    defaultValues: { ...filters }
  });
  const onSubmit: SubmitHandler<InitialConsultationsFilter> = (data) => {
    data.completed_at_gte = data.completed_at?.[0];
    data.completed_at_lte = data.completed_at?.[1];
    const updatedData = {
      ...data,
      session_date: data.completed_at || []
    };
    updateInitialConsultationsSlice({ filters: { ...updatedData } });
    setCurrentPage(1);
  };

  useEffect(() => {
    reset({ ...filters });
  }, [filters, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center my-4'>
        <div>
          <Label className='text-sm !font-normal m-1'>First Name</Label>
          <Controller
            name='first_name'
            control={control}
            render={({ field }) => (
              <Input type='text' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Last Name</Label>
          <Controller
            name='last_name'
            control={control}
            render={({ field }) => (
              <Input className='h-[2rem]' type='text' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Email</Label>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input type='email' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Submitted At</Label>
          <Controller
            control={control}
            name='completed_at'
            render={({ field }) => (
              <RangePicker
                className='!px-2.5 !py-1'
                format='MM/DD/YYYY'
                placeholder={['Start Date', 'End Date']}
                {...field}
                onChange={(_, dateStrings: [string, string]) => {
                  console.log(dateStrings);
                  field.onChange(dateStrings);
                }}
                value={
                  field.value
                    ? [
                        field.value[0] ? dayjs(field.value[0]) : null,
                        field.value[1] ? dayjs(field.value[1]) : null
                      ]
                    : null
                }
              />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Status</Label>
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Select
                placeholder='Select Status'
                {...field}
                className='w-[150px] h-[1.9rem]]'
                options={[
                  { label: 'Pending', value: 'Pending' },
                  { label: 'Reviewed', value: 'Reviewed' },
                  { label: 'Archive', value: 'Archive' },
                  { label: 'Follow-up', value: 'Follow-up' },
                  { label: 'All', value: '' }
                ]}
              />
            )}
          />
        </div>
        {role === 'Super Admin' && (
          <div>
            <Label className='text-sm !font-normal m-1'>Consultant</Label>
            <Controller
              name='consultant_id'
              control={control}
              render={({ field }) => (
                <Select
                  options={usersData?.users
                    ?.filter(
                      (data: { pb_consultant_id: string }) =>
                        !!data.pb_consultant_id
                    )
                    .map(
                      (data: {
                        pb_consultant_id: string;
                        first_name: string;
                        last_name: string;
                      }) => ({
                        value: data.pb_consultant_id,
                        label: `${data.first_name} ${data.last_name}`
                      })
                    )}
                  className='w-[190px] h-[1.9rem]'
                  {...field}
                />
              )}
            />
          </div>
        )}
        <div>
          <Label htmlFor='completed' className='text-sm !font-normal m-1'>
            Completed
          </Label>
          <Controller
            name='completed'
            control={control}
            render={({ field }) => (
              <Select
                id='completed'
                options={[
                  { value: 'true', label: 'Yes' },
                  { value: 'false', label: 'No' }
                ]}
                className='w-[150px]'
                {...field}
              />
            )}
          />
        </div>
        <Button className='w-max px-3.5 h-[36px] mt-6'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() => resetInitialConsultationsSlice()}
          className='border border-orange rounded py-2 px-3 mt-6'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
