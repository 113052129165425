import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { Controller, useForm } from 'react-hook-form';
import { DiscountFormInputs, DiscountsResponse } from 'types/discounts';
import { useCreateDiscount, useUpdateDiscount } from '../api/useApi';
import Textarea from 'components/textarea/Textarea';
import Input from 'components/input/Input';

export default function DiscountForm({
  info,
  setIsClose
}: {
  info?: DiscountsResponse;
  setIsClose?: any;
}) {
  const formInitials: DiscountFormInputs = {
    amount: info?.amount ?? '',
    code: info?.code ?? '',
    description: info?.description ?? '',
    id: info?.id ?? '',
    name: info?.name ?? '',
    percentage: info?.percentage ?? '',
    status: info?.status ?? ''
  };

  const handleSuccess = () => {
    setIsClose();
    reset();
  };

  const { mutateAsync: updateDiscount, isPending: isUpdatePending } =
    useUpdateDiscount({ onSuccess: handleSuccess });
  const { mutateAsync: addDiscount, isPending: isAddPending } =
    useCreateDiscount({ onSuccess: handleSuccess });

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields }
  } = useForm<DiscountFormInputs>({
    values: formInitials
  });

  const onSubmit = (data: any) => {
    const dirtyData: DiscountFormInputs = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof DiscountFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    if (info) {
      updateDiscount({ ...dirtyData, id: info.id });
    } else {
      addDiscount(dirtyData);
    }
  };

  const isPending = isUpdatePending || isAddPending;

  return (
    <>
      <p className='text-base font-semibold'>Package Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6'>
          <div>
            <Label className='!font-normal'>Name</Label>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <Input type='text' className='mt-2' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Code</Label>
            <Controller
              name='code'
              control={control}
              render={({ field }) => (
                <Input type='text' className='mt-2' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Amount ($)</Label>
            <Controller
              name='amount'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Description</Label>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <Textarea
                  placeholder='Enter description...'
                  row={1}
                  type='text'
                  className='mt-2'
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isPending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
