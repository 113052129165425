import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { BiSolidError } from 'react-icons/bi';

export default function ErrorPage() {
  return (
    <div className='bg-[#FAFBFC] flex flex-col items-center pt-20 min-h-screen'>
      <img src={logo} alt='Logo' className='w-52 h-36' />
      <div className='flex flex-col items-center bg-light-orange rounded-2xl p-8 max-w-sm shadow-lg'>
        <BiSolidError className='w-20 h-20 text-orange mb-4' />
        <p className='text-center text-gray-700 mb-6'>
          Oops! you are trying to access wrong url or you don't have permission
          to access it.
        </p>
        <p className='text-center text-gray-700 mb-6 font-semibold'>
          If the URL is correct, please contact admin or check the logs.
        </p>
        <Link
          to='/'
          className='bg-orange text-white py-2 px-6 rounded-lg hover:bg-orange-dark transition duration-200'
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
}
