import { EmailLogFilters, EmailLogSortingDetails } from 'types/emailLogs';
import { request } from 'utils/api';

export const getEmailLogs = async (
  currentPage: number,
  emailLogFilters: EmailLogFilters,
  sortingDetails: EmailLogSortingDetails,
  clientId?: string
) => {
  const data = await request({
    url: '/email-logs',
    method: 'GET',
    params: {
      page: currentPage,
      ...emailLogFilters,
      ...sortingDetails,
      per_page: clientId ? 20 : null,
      client_record_id: clientId || null
    }
  });
  return data;
};
