import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import {
  getDiscounts,
  handleAddDiscount,
  handleDeleteDiscount,
  handleUpdateDiscount
} from '.';
import { DiscountFormInputs, DiscountsFilterInput } from 'types/discounts';

export const useDiscounts = (
  currentPage: number,
  discountsFilters: DiscountsFilterInput,
  sortingDetails: any
) => {
  return useQuery({
    queryKey: ['discounts', currentPage, discountsFilters, sortingDetails],
    queryFn: () => getDiscounts(currentPage, discountsFilters, sortingDetails)
  });
};

export const useUpdateDiscount = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, DiscountFormInputs, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: DiscountFormInputs) =>
      handleUpdateDiscount(data.id, data),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['discounts'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });
  return mutation;
};

export const useCreateDiscount = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, DiscountFormInputs, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: DiscountFormInputs) => handleAddDiscount(data),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['discounts'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });
  return mutation;
};

export const useDeleteDiscount = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, string, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => handleDeleteDiscount(id),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['discounts'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });
  return mutation;
};
