import { StateCreator } from 'zustand';
import { FollowUpsSlice } from '../types/followUpsSliceTypes';
import { getDefaultConsultantId } from 'utils/helpers/defaultConsultantId';
import dayjs from 'dayjs';

const InitialFollowUpsFilters = {
  email: '',
  last_name: '',
  first_name: '',
  has_virtual_form: '',
  consultant_id: getDefaultConsultantId(),
  completed: '',
  status: '',
  session_type: '',
  session_date: [dayjs().format('MM/DD/YYYY'), dayjs().format('MM/DD/YYYY')],
  session_date_gte: dayjs().format('MM/DD/YYYY'),
  session_date_lte: dayjs().format('MM/DD/YYYY'),
  has_pb_invoice: '',
  has_form: '',
  has_order_placed: ''
};

const initialState = {
  filters: InitialFollowUpsFilters
};

export const followUpsSlice: StateCreator<FollowUpsSlice> = (set) => ({
  followUpsSlice: initialState,
  updateFollowUpsSlice: (data: any) => {
    set((state) => ({
      followUpsSlice: {
        ...state.followUpsSlice,
        ...data
      }
    }));
  },
  resetFollowUpsSlice: () => set({ followUpsSlice: initialState })
});
