import React from 'react';
import Input from 'components/input/Input';
import { Label } from 'components/label/Label';
import Button from 'components/buttons/Button';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { DocumentsFilterInput } from 'types/documents';

type FiltersProps = {
  setDocumentsFilters: (data: DocumentsFilterInput) => void;
  setCurrentPage: (page: number) => void;
  documentsFilters: Partial<DocumentsFilterInput>;
};

export default function Filters({
  setDocumentsFilters,
  setCurrentPage,
  documentsFilters
}: FiltersProps) {
  const { control, handleSubmit, reset } = useForm<DocumentsFilterInput>({
    defaultValues: {
      ...documentsFilters
    }
  });

  const onSubmit: SubmitHandler<DocumentsFilterInput> = (data) => {
    setDocumentsFilters(data);
    setCurrentPage(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center mb-5'>
        <div className='w-40'>
          <Label className='text-sm !font-normal m-1'>Name</Label>
          <Controller
            name='file_name'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <Button type='submit' className='w-max px-3.5 h-[38px] mt-6'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() =>
            reset({
              file_name: ''
            })
          }
          className='border border-orange rounded py-2 px-3 mt-6'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
