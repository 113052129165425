import { Order } from 'types/order';

export default function PharmacyPayload({
  info,
  setIsClose
}: {
  info: Order;
  setIsClose: any;
}) {
  if (!info.order_json && !info.order_json) {
    return <span className='font-semibold'>No data to show.</span>;
  }
  return (
    <div>
      {info.order_json && (
        <>
          <span className='text-lg font-semibold text-orange'>
            Pharmacy Payload
          </span>
          <div className='border rounded-lg p-3 bg-light-grey flex flex-col h-[30rem] overflow-auto m-2'>
            {RenderList(JSON.parse(info.order_json))}
          </div>
        </>
      )}
    </div>
  );
}

type NestedObject = {
  [key: string]: string | number | boolean | NestedObject | null;
};

const RenderList = (data: NestedObject): JSX.Element[] => {
  return Object.entries(data).map(([key, value]) => (
    <li key={key} className='flex items-start gap-2'>
      <strong className='text-gray-700 capitalize'>
        {key.replace(/_/g, ' ')}:
      </strong>
      <span className='text-gray-600'>
        {value && typeof value === 'object' ? (
          <ul className='pl-4'>{RenderList(value)}</ul>
        ) : value !== null ? (
          value.toString()
        ) : (
          'null'
        )}
      </span>
    </li>
  ));
};
