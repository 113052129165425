import { request } from 'utils/api';
import { DiscountFormInputs, DiscountsFilterInput } from 'types/discounts';

export const getDiscounts = async (
  currentPage: number,
  discountsFilters: DiscountsFilterInput,
  sortingDetails: any
) => {
  const data = await request({
    url: '/discounts',
    method: 'GET',
    params: { page: currentPage, ...discountsFilters, ...sortingDetails }
  });
  return data;
};

export const handleUpdateDiscount = async (
  id: string | undefined,
  payload: DiscountFormInputs
) => {
  const response = await request({
    url: `/discounts/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export const handleAddDiscount = async (payload: DiscountFormInputs) => {
  const response = await request({
    url: '/discounts',
    method: 'POST',
    data: payload
  });
  return response;
};

export const handleDeleteDiscount = async (id: string) => {
  const response = await request({
    url: `/discounts/${id}`,
    method: 'DELETE',
    data: {}
  });
  return response;
};
