import { StateCreator } from 'zustand';
import { CustomersSlice } from '../types/customersSliceTypes';

const InitialCustomersFilters = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_phone: '',
  tags: []
};

const initialState = {
  filters: InitialCustomersFilters
};

export const customersSlice: StateCreator<CustomersSlice> = (set) => ({
  customersSlice: initialState,
  updateCustomersSlice: (data: any) => {
    set((state) => ({
      customersSlice: {
        ...state.customersSlice,
        ...data
      }
    }));
  },
  resetCustomersSlice: () => set({ customersSlice: initialState })
});
