import { Input, Spin } from 'antd';
import { Link } from 'react-router-dom';
import Radio from 'components/radio/Radio';
import formatDate from 'utils/helpers/date';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import ICPackageDetails from './ICPackageDetails';
import { FaExternalLinkAlt } from 'react-icons/fa';
import FormFooterButtons from './FormFooterButtons';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { medicationOptions } from 'constants/medicationOptions';
import WysiwygEditor from 'components/wysiwygEditor/WysiwygEditor';
import { getICPackageStatus } from 'utils/helpers/icPackageStatus';
import { getDoseValueOptions } from 'utils/helpers/medicationDoseOptions';
import {
  ArrowRightOutlined,
  EditOutlined,
  RedoOutlined
} from '@ant-design/icons';
import {
  InitialConsultationFormInputs,
  InitialConsultationsResponse
} from 'types/initialConsultations';
import {
  useCreateICInvoice,
  useCreateSessionNotes,
  useDropdownValues,
  useInitialConsultation,
  useRecreateOrder,
  useRefreshAIPrompts,
  useUnlinkedInvoices,
  useUpdateInitialConsultations,
  useUpdateReviewedICs
} from 'pages/initialConsultations/api/useApi';
import ReviewedFormDetails from './ReviewedFormDetails';
import paths from 'constants/path';

type Item = {
  name: string;
  selected: boolean;
};

export default function InitialConsultationsForm({
  isSingle,
  formId,
  backUrl
}: {
  isSingle?: boolean;
  formId: string;
  backUrl: string;
}) {
  const { data: InitialConsultationData, isPending: isFormLoading } =
    useInitialConsultation(formId, Boolean(isSingle));

  const initialConsultationMemorizedData = useMemo(() => {
    return InitialConsultationData?.form_request ?? [];
  }, [InitialConsultationData?.form_request]);

  const [formData, setFormData] = useState<
    InitialConsultationsResponse | undefined
  >(initialConsultationMemorizedData);
  const [refreshingColumn, setRefreshingColumn] = useState('');
  const [followUpClicked, setFollowUpClicked] = useState(false);
  const [isEditReviewedForm, setIsEditReviewedForm] = useState(false);
  const [isRecreateOrder, setIsRecreateOrder] = useState(false);

  const { data: dropdownValues, isPending: valuesLoading } = useDropdownValues(
    String(formData?.id),
    formData?.preferred_plan,
    formData?.discount
  );
  const { data: unlinkedInvoices, isPending: loadingInvoices } =
    useUnlinkedInvoices(String(formData?.client_record_id));

  useEffect(() => {
    setFormData(initialConsultationMemorizedData);
  }, [initialConsultationMemorizedData]);

  const formInitials = formData?.id
    ? {
        completed_at: formatDate(formData.completed_at),
        medication: formData.medication,
        name: formData.name,
        preferred_medications: formData.preferred_medications,
        preferred_plan: valuesLoading
          ? 'Loading...'
          : getSelectedNames(dropdownValues?.preffered_plan),
        submit: formData.submit,
        weight: formData.weight,
        dose: formData.dose,
        id: formData.id,
        message_to_patient: formData.message_to_patient,
        ic_assessment_and_plan: formData.ic_assessment_and_plan,
        ic_hpi: formData.ic_hpi,
        ic_pmh: formData.ic_pmh,
        discount: valuesLoading
          ? 'Loading...'
          : dropdownValues?.discount
              .filter((item: any) => item.selected)
              .map((item: any) => item.id)[0] || '',
        send_review: formData.send_review,
        plan: formData.plan,
        goals: formData.goals,
        form_name: formData.form_name,
        promo_code: formData.promo_code ?? 'Not Applied!',
        starter_pack: formData.starter_pack ?? false,
        trustpilot_status:
          formData.trustpilot_status === 'Sent'
            ? 'Sending'
            : formData.trustpilot_status
      }
    : {
        completed_at: '',
        medication: '',
        name: '',
        preferred_medications: '',
        preferred_plan: '',
        submit: false,
        weight: '',
        dose: '',
        id: null,
        message_to_patient: '',
        ic_assessment_and_plan: '',
        ic_hpi: '',
        ic_pmh: '',
        discount: '',
        send_review: false,
        plan: '',
        goals: '',
        trustpilot_status: 'Pending',
        form_name: '',
        promo_code: '',
        starter_pack: false
      };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields, errors }
  } = useForm<InitialConsultationFormInputs>({
    values: formInitials
  });
  const selectedMedication = watch('medication');
  const selectedPreferredPlan = useWatch({ control, name: 'preferred_plan' });

  const updateField = (fieldName: any, value: any) => {
    const aiPromptFields = {
      ic_hpi: watch('ic_hpi'),
      ic_pmh: watch('ic_pmh'),
      message_to_patient: watch('message_to_patient'),
      ic_assessment_and_plan: watch('ic_assessment_and_plan')
    };
    if (fieldName === 'starter_pack' && value) {
      updateInitialConsultations({
        ...{
          [fieldName]: value,
          medication: selectedMedication,
          dose: getDoseValueOptions(String(selectedMedication))?.[0]?.value
        },
        ...aiPromptFields
      });
    } else if (fieldName === 'medication' && formData?.starter_pack) {
      updateInitialConsultations({
        ...{
          [fieldName]: value,
          dose: getDoseValueOptions(String(value))?.[0]?.value
        },
        ...aiPromptFields
      });
    } else {
      !isEditReviewedForm ||
      fieldName === 'preferred_plan' ||
      fieldName === 'starter_pack'
        ? updateInitialConsultations({
            ...{ [fieldName]: value },
            ...aiPromptFields
          })
        : setValue(fieldName, value);
    }
  };

  const onSuccess = (data: any, type?: string) => {
    switch (type) {
      case 'promptRefreshed':
        let updatedField = data.payload?.column_name;
        let updatedValue = data?.form_request[updatedField];
        setValue(updatedField, updatedValue);
        return null;
      case 'sessionCreated':
        setFormData(data.form_request);
        setFollowUpClicked(false);
        return null;
      default:
        setFormData(data.form_request);
    }
  };

  const {
    mutateAsync: updateInitialConsultations,
    isPending: isUpdatePending
  } = useUpdateInitialConsultations({
    onSuccess: onSuccess,
    id: String(formData?.id)
  });

  const { mutateAsync: recreateOrder, isPending: isOrderRecreation } =
    useRecreateOrder({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const { mutateAsync: createICInvoice, isPending: creatingICInvoice } =
    useCreateICInvoice();

  const { mutateAsync: updateReviewedICs, isPending: updatingReviewedIC } =
    useUpdateReviewedICs({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const { mutateAsync: createSessionNotes, isPending: isCreationPending } =
    useCreateSessionNotes({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const { mutateAsync: refreshAIPrompts, isPending: isRefreshPending } =
    useRefreshAIPrompts({
      onSuccess: onSuccess,
      id: String(formData?.id)
    });

  const onSubmit = async (data: InitialConsultationFormInputs) => {
    let dirtyData: Partial<InitialConsultationFormInputs> = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof InitialConsultationFormInputs;

      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (typeof value === 'string' || value === undefined) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        }
      }
    });
    if (formData) {
      dirtyData = followUpClicked
        ? { ...dirtyData, status: 'Follow-up' }
        : { ...dirtyData, status: 'Reviewed' };
      if (isEditReviewedForm) {
        isRecreateOrder
          ? await recreateOrder({ ...data, status: 'Reviewed' })
          : await updateReviewedICs({ ...data, status: 'Reviewed' });
        setIsEditReviewedForm(false);
      } else {
        await updateInitialConsultations(dirtyData);
        createSessionNotes({ status: dirtyData.status });
      }
    }
  };

  const isPending = isUpdatePending || updatingReviewedIC;
  const isFormLocked =
    (formData?.status === 'Reviewed' && !isEditReviewedForm) || isFormLoading;

  function getSelectedNames(data: Item[]): string | undefined {
    if (data) {
      return data
        .filter((item) => item.selected)
        .map((item) => item.name)
        .join(', ');
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-row'>
        <div className='w-1/2 border-r'>
          {isFormLoading ? (
            <span>Loading details...</span>
          ) : (
            <div className='flex flex-row justify-between text-base font-semibold mr-9 pb-2 border-b'>
              <div className='flex  gap-2 '>
                <a
                  href={paths.customer(formData?.client_record_id)}
                  target='_blank'
                  rel='noreferrer'
                >
                  Patient Name -
                </a>
                <a
                  href={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}`}
                  rel='noopener noreferrer'
                  target='_blank'
                  className='flex items-center gap-2'
                >
                  <p className='text-orange'>
                    {formData?.name} (State: {formData?.state})
                  </p>
                </a>
              </div>
              {!isEditReviewedForm && formData?.status === 'Reviewed' && (
                <button
                  className='text-orange mx-1'
                  onClick={() => setIsEditReviewedForm(true)}
                >
                  <EditOutlined className='mr-1' />
                  Edit
                </button>
              )}
            </div>
          )}
          <div className={`m-3 ml-0 ${isFormLocked && 'opacity-50'}`}>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>HPI</span>
                  {isRefreshPending && refreshingColumn === 'ic_hpi' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_hpi');
                        setRefreshingColumn('ic_hpi');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_hpi'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>PMH</span>
                  {isRefreshPending && refreshingColumn === 'ic_pmh' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_pmh');
                        setRefreshingColumn('ic_pmh');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_pmh'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
          </div>
        </div>
        <div className='w-1/2 pl-4'>
          <div className='border-b flex flex-row justify-between'>
            <div className='flex flex-row gap-3'>
              <span className='text-base font-semibold pb-2 flex flex-row gap-3'>
                {isFormLocked ? <>Details</> : 'Edit Details'}
              </span>
              {formData?.form_count && formData?.form_count > 1 && (
                <span className='text-sm text-grey border border-grey bg-light-grey px-2 h-6 rounded-xl text-center'>
                  Duplicate Form
                </span>
              )}
            </div>
            <Link to={backUrl}>
              <ArrowRightOutlined
                className='text-lg pr-4 pb-3'
                onClick={() => setIsEditReviewedForm(false)}
              />
            </Link>
          </div>
          {formData?.status === 'Reviewed' && (
            <ReviewedFormDetails formData={formData} />
          )}
          <div className={`mt-3.5 gap-6 mb-20 ${isFormLocked && 'opacity-50'}`}>
            <div className='mr-6 mb-3'>
              <Label className='!font-normal'>Form Name</Label>
              <Controller
                disabled
                name='form_name'
                control={control}
                render={({ field }) => (
                  <Input
                    type='text'
                    className=' mt-2 w-full h-[30px]'
                    {...field}
                  />
                )}
              />
            </div>
            <div className='flex flex-row justify-between mb-7 '>
              <div className='mr-6 w-[10rem]'>
                <Label className='!font-normal'>Preferred Medication</Label>
                <Controller
                  disabled
                  name='preferred_medications'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-[10rem] h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div
                className={`mr-4 ${(valuesLoading || selectedPreferredPlan !== 'Month to month') && 'w-[7.5rem]'}`}
              >
                <Label className='!font-normal mb-2'>Plan</Label>
                <Controller
                  name='preferred_plan'
                  disabled={
                    valuesLoading ||
                    (formData?.status === 'Reviewed' && !isEditReviewedForm)
                  }
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Plan'
                      {...field}
                      className='w-full h-[30px]'
                      options={[
                        { value: 'Month to month', label: 'Month To Month' },
                        { value: '2 month', label: '2 Months' },
                        { value: '3 month', label: '3 Months' },
                        { value: '6 month', label: '6 Months' },
                        { value: '9 month', label: '9 Months' }
                      ]}
                      onChange={(val) => {
                        updateField('preferred_plan', val);
                      }}
                    />
                  )}
                />
              </div>
              {selectedPreferredPlan !== 'Month to month' && !valuesLoading && (
                <div className='mx-4 w-[7.5rem]'>
                  <Label className='!font-normal mb-2'>Goals</Label>
                  <Controller
                    disabled={isFormLocked}
                    name='goals'
                    rules={{ required: 'Goals is Required' }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder='Select Goal'
                        {...field}
                        className='w-full h-[30px]'
                        options={[
                          { value: 'Maintenance', label: 'Maintenance' },
                          { value: 'Escalation', label: 'Escalation' }
                        ]}
                        onChange={(val) => {
                          updateField('goals', val);
                        }}
                      />
                    )}
                  />
                  <p className='text-red text-sm'>{errors?.goals?.message}</p>
                </div>
              )}
              <div className='mr-1 w-[8rem]'>
                <Label className='!font-normal'>Promo Code</Label>
                <Controller
                  disabled
                  name='promo_code'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            {formData?.status === 'Reviewed' && !formData?.pb_invoice?.id && (
              <div className='mt-3.5 w-[35rem]'>
                <Label className='!font-normal mb-1.5'>Link Invoice</Label>
                <Select
                  disabled={loadingInvoices}
                  placeholder='Select Invoice'
                  className='w-full h-[30px]'
                  options={unlinkedInvoices?.map((invoice: any) => {
                    return {
                      value: invoice.id,
                      label: (
                        <span>
                          <b>{formatDate(invoice.date_created)}</b> -{' '}
                          {invoice.invoice_number}: ${invoice.total_amount} (
                          {invoice.status})
                        </span>
                      )
                    };
                  })}
                  allowClear={true}
                  onChange={async (val) => {
                    await updateInitialConsultations({
                      pb_invoice_id: val,
                      hide_notification: false
                    });
                    setIsEditReviewedForm(false);
                  }}
                />
              </div>
            )}
            <div
              className={` flex flex-row gap-10 ${formData?.status === 'Reviewed' && !isEditReviewedForm && 'pointer-events-none opacity-50'}`}
            >
              <div className='mt-3.5 w-[35rem]'>
                <Label className='!font-normal mb-1.5'>Discount</Label>
                <Controller
                  name='discount'
                  control={control}
                  disabled={valuesLoading}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Discount'
                      showSearch={true}
                      {...field}
                      className='w-full h-[30px]'
                      data={dropdownValues?.discount}
                      labelKey='name'
                      valueKey='id'
                      allowClear={true}
                      onChange={(val) => {
                        updateField('discount', val ?? '');
                      }}
                    />
                  )}
                />
              </div>
              <div className='w-[25rem] mt-4'>
                <Label className='!font-normal'>Is Starter</Label>
                <Controller
                  name='starter_pack'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                      ]}
                      onChange={(e) => {
                        updateField('starter_pack', e?.target?.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {/* {formData?.discount_dict && (
              <ICDiscountDetails currentDiscount={formData?.discount_dict} />
            )} */}
            {formData?.package && (
              <ICPackageDetails
                getSelectedNames={getSelectedNames}
                clientState={formData.state}
                currentPackage={formData?.package}
                isStarterPack={formData.starter_pack}
                isPackageAvailable={getICPackageStatus(
                  formData.state,
                  formData.form_id
                )}
                formId={formData?.form_id}
              />
            )}
            <div
              className={`grid grid-cols-3 mb-6 mt-3.5 ${isFormLocked && 'cursor-not-allowed'}`}
            >
              <div className='w-[8rem]'>
                <Label className='!font-normal'>Order Medication</Label>
                <Controller
                  name='medication'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        ...medicationOptions,
                        { value: 'No Order', label: 'No Order' }
                      ]}
                      onChange={(e) => {
                        updateField('medication', e?.target?.value);
                      }}
                    />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.medication?.message}
                </p>
              </div>
              {selectedMedication !== 'No Order' && (
                <div className='w-[25rem]'>
                  <Label className='!font-normal'>Dose</Label>
                  <Controller
                    name='dose'
                    disabled={formData?.starter_pack}
                    rules={{ required: 'Dose is Required' }}
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={getDoseValueOptions(
                          String(selectedMedication)
                        )}
                        onChange={(e) => {
                          updateField('dose', e?.target?.value);
                        }}
                      />
                    )}
                  />
                  <p className='text-red text-sm'>{errors?.dose?.message}</p>
                </div>
              )}
            </div>
            <div
              className={`grid grid-cols-3 mb-6 mt-3.5 ${formData?.status === 'Reviewed' && 'pointer-events-none opacity-50'}`}
            >
              <div>
                <Label className='!font-normal'>Send Review</Label>
                <Controller
                  name='trustpilot_status'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        { value: 'Sending', label: 'Yes' },
                        { value: 'Pending', label: 'No' }
                      ]}
                      onChange={(e) => {
                        updateField('trustpilot_status', e?.target?.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className='mb-6'>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>Message to Patient</span>
                  {isRefreshPending &&
                  refreshingColumn === 'message_to_patient' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('message_to_patient');
                        setRefreshingColumn('message_to_patient');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='message_to_patient'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
            <div>
              <Label
                className={`!font-normal mb-1.5 ${isFormLocked && 'pointer-events-none'}`}
              >
                <span className='flex flex-row'>
                  <span>Assessment & Plan</span>
                  {isRefreshPending &&
                  refreshingColumn === 'ic_assessment_and_plan' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_assessment_and_plan');
                        setRefreshingColumn('ic_assessment_and_plan');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_assessment_and_plan'
                control={control}
                render={({ field }) => (
                  <WysiwygEditor readOnly={isFormLocked} {...field} />
                )}
              />
            </div>
          </div>
          {!isFormLocked && !isEditReviewedForm ? (
            <FormFooterButtons
              backUrl={backUrl}
              followUpClicked={followUpClicked}
              setFollowUpClicked={setFollowUpClicked}
              isPending={isPending || isCreationPending}
              showSubmitButton={Boolean(
                formData?.package?.id || selectedMedication === 'No Order'
              )}
              formCount={formData?.form_count ?? 1}
              submitForm={handleSubmit(onSubmit)}
              clientRecordId={formData?.client_record_id}
            />
          ) : (
            <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full text text-orange h-14 pr-9'>
              {isEditReviewedForm &&
                formData?.send_to_pharmacy === false &&
                formData?.package?.id && (
                  <button
                    disabled={isPending}
                    type='submit'
                    onClick={() => setIsRecreateOrder(true)}
                    className='!w-fit px-8 bg-grey text-white rounded-lg font-semibold disabled:cursor-not-allowed w-36'
                  >
                    {isOrderRecreation ? <Spin /> : 'Re-Create Order'}
                  </button>
                )}
              {isEditReviewedForm && formData?.package?.id && (
                <button
                  disabled={isPending}
                  type='submit'
                  className='!w-fit px-8 bg-orange text-white rounded-lg font-semibold disabled:cursor-not-allowed w-36'
                >
                  {isPending ? <Spin /> : 'Update'}
                </button>
              )}
              {formData?.order_note_id && (
                <Link
                  className='flex flex-row gap-1 font-semibold mt-1'
                  to={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}/notes/${formData?.order_note_id}/edit`}
                  target='_blank'
                >
                  Order Notes
                  <FaExternalLinkAlt className='text-sm cursor-pointer mt-[2.5px]' />
                </Link>
              )}
              {formData?.session_note_id && (
                <Link
                  className='flex flex-row gap-1 font-semibold mt-1'
                  to={`https://my.practicebetter.io/#/p/clients/${formData?.client_record_id}/notes/${formData?.session_note_id}/edit`}
                  target='_blank'
                >
                  Session Notes
                  <FaExternalLinkAlt className='text-sm cursor-pointer mt-[2.5px]' />
                </Link>
              )}
              {formData?.pb_invoice?.id ? (
                <Link
                  className='flex flex-row gap-1 font-semibold mt-1'
                  to={formData?.pb_invoice?.pb_link}
                  target='_blank'
                >
                  Invoice Created at :{' '}
                  {formatDate(formData?.pb_invoice?.updated_at)}
                  <FaExternalLinkAlt className='text-sm cursor-pointer mt-[2.5px]' />
                </Link>
              ) : (
                formData?.package?.id && (
                  <button
                    type='button'
                    onClick={() => createICInvoice(String(formData?.id))}
                    className='!w-32 pt-1 bg-orange text-white py-2 rounded-lg font-semibold'
                  >
                    {creatingICInvoice ? <Spin /> : 'Create Invoice'}
                  </button>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
