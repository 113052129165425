import { request } from 'utils/api';
import { useParams } from 'react-router-dom';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import Button from 'components/buttons/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { Controller, useForm } from 'react-hook-form';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getDoseValueOptions } from 'utils/helpers/medicationDoseOptions';
import { usePharmacyButton } from 'pages/orders/components/usePharmacyButton';
import { useState } from 'react';

interface OrderFormInputs {
  plan_name: string;
  goals: string;
  medication: string;
  initial_dose: string;
  order_note: string;
}

const handleAddOrder = async (payload: OrderFormInputs, clientId: string) => {
  const response = await request({
    url: '/orders/create-manual-order',
    method: 'POST',
    data: { ...payload, client_record_id: clientId }
  });
  return response;
};

export default function OrderForm({ setIsClose, setAddOrderModal }: any) {
  const { id } = useParams();
  const [isSend, setIsSend] = useState(false);
  const { contextHolder, sendToPharmacy, sendingToPharmacy } =
    usePharmacyButton();
  const [isSending, setIsSending] = useState(sendingToPharmacy);
  const initialValues = {
    plan_name: '',
    goals: '',
    medication: '',
    initial_dose: '',
    order_note: ''
  };

  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, dirtyFields }
  } = useForm<OrderFormInputs>({
    values: initialValues
  });
  const selectedMedication = watch('medication');
  const selectedPlan = watch('plan_name');

  const { mutateAsync: addOrder, isPending: isAddPending } = useMutation({
    mutationFn: (data: OrderFormInputs) => handleAddOrder(data, String(id)),
    onSuccess(data) {
      reset(initialValues);
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        setAddOrderModal(false);
      }
    }
  });

  const onSubmit = async (data: any) => {
    const dirtyData: OrderFormInputs = { ...initialValues };
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof OrderFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    if (isSend) {
      setIsSending(true);
      let res = await handleAddOrder(dirtyData, String(id));
      await sendToPharmacy(res?.order?.id, res?.order?.pharmacy);
      reset(initialValues);
      setAddOrderModal(false);
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    } else {
      addOrder(dirtyData);
    }
    setIsSend(false);
    setIsSending(false);
  };

  return (
    <>
      <p className='text-base font-semibold'>Orders Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6'>
          <div>
            <Label className='!font-normal'>Medication</Label>
            <Controller
              name='medication'
              control={control}
              rules={{ required: 'Medication is Required' }}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  options={[
                    { value: 'Semaglutide', label: 'Semaglutide' },
                    { value: 'Tirzepatide', label: 'Tirzepatide' }
                  ]}
                />
              )}
            />
            <p className='text-red text-sm'>{errors?.medication?.message}</p>
          </div>
          <div>
            <div className='flex flex-row'>
              <Label className='!font-normal'>Dose</Label>
              {!selectedMedication && (
                <Tooltip title='Please select medication first.'>
                  <InfoCircleOutlined className='mx-1 mt-[2px]' />
                </Tooltip>
              )}
            </div>
            <Controller
              name='initial_dose'
              disabled={!selectedMedication}
              control={control}
              rules={{ required: 'Dose is Required' }}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  options={getDoseValueOptions(String(selectedMedication))}
                />
              )}
            />
            <p className='text-red text-sm'>{errors?.initial_dose?.message}</p>
          </div>
          <div>
            <Label className='!font-normal mb-1.5'>Plan</Label>
            <Controller
              name='plan_name'
              control={control}
              rules={{ required: 'Plan is Required' }}
              render={({ field }) => (
                <Select
                  placeholder='Select Plan'
                  {...field}
                  className='w-full h-[38px]'
                  options={[
                    { value: 'Month to month', label: 'Month To Month' },
                    { value: '2 month', label: '2 Months' },
                    { value: '3 month', label: '3 Months' },
                    { value: '6 month', label: '6 Months' },
                    { value: '9 month', label: '9 Months' }
                  ]}
                />
              )}
            />
            <p className='text-red text-sm'>{errors?.plan_name?.message}</p>
          </div>
          {!['Month to month'].includes(selectedPlan) && (
            <div>
              <div className='flex flex-row'>
                <Label className='!font-normal mb-1.5'>Goals</Label>
                {!selectedMedication && (
                  <Tooltip title='Please select plan first.'>
                    <InfoCircleOutlined className='mx-1 mb-1' />
                  </Tooltip>
                )}
              </div>
              <Controller
                name='goals'
                disabled={!selectedPlan}
                rules={{ required: 'Goal is Required' }}
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder='Select Goal'
                    {...field}
                    className='w-full h-[38px]'
                    options={[
                      { value: 'Maintenance', label: 'Maintenance' },
                      { value: 'Escalation', label: 'Escalation' }
                    ]}
                  />
                )}
              />
              <p className='text-red text-sm'>{errors?.goals?.message}</p>
            </div>
          )}
        </div>
        {/* <div className='grid grid-col'>
          <div className='mt-4'>
            <Label className='!font-normal'>Note</Label>
            <Controller
              name='order_note'
              control={control}
              render={({ field }) => (
                <Textarea type='text' row={3} className='mt-2' {...field} />
              )}
            />
          </div>
        </div> */}
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-3' isLoading={isAddPending}>
            Create order
          </Button>
          <button
            type='submit'
            disabled={isSend && isSending}
            className='!w-fit px-2 py-2 border border-orange text-orange rounded-lg font-semibold hover:bg-orange hover:text-white disabled:opacity-50'
            onClick={() => setIsSend(true)}
          >
            {isSend && isSending ? 'Sending' : 'Create & Send to Pharmacy'}
          </button>
        </div>
      </form>
      {contextHolder}
    </>
  );
}
