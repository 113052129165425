import { useDeleteBlacklisted, useDocuments } from './api/useApi';
import { Columns } from './components/Columns';
import React, { useMemo, useState } from 'react';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Filters from './components/Filters';
import HeaderActionButtons from './components/HeaderActionButtons';

export default function Blacklisted() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingId, setProcessingId] = useState('');
  const [blacklistedFilters, setBlacklistedFilters] = useState({
    category: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const { mutateAsync: deleteBlackListedItem, isPending: isDeletePending } =
    useDeleteBlacklisted();
  const { data: BlacklistedData, isPending: isDocumentsLoading } = useDocuments(
    currentPage,
    blacklistedFilters,
    sortingDetails
  );

  const memorizedBlacklistedData = useMemo(() => {
    return BlacklistedData?.blacklists ?? [];
  }, [BlacklistedData?.blacklists]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between mt-3'>
          <h1 className='text-xl font-semibold mb-2'>Blacklisted Items</h1>
          <HeaderActionButtons />
        </div>
        <Filters
          setCurrentPage={setCurrentPage}
          blacklistedFilters={blacklistedFilters}
          setBlacklistedFilters={setBlacklistedFilters}
        />
        <div className='overflow-auto h-[calc(100vh_-_340px)]'>
          <DataTable
            data={memorizedBlacklistedData}
            columns={Columns(
              sortingDetails,
              setSortingDetails,
              deleteBlackListedItem,
              isDeletePending,
              processingId,
              setProcessingId
            )}
            isLoading={isDocumentsLoading}
          />
        </div>
      </div>
      <Pagination
        totalCount={BlacklistedData?.pagination.total_count}
        totalPage={BlacklistedData?.pagination.total_pages}
        perPage={BlacklistedData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
