import {
  BlacklistedFilterInput,
  BlacklistedFormInputs,
  BlacklistedSortingDetails
} from 'types/blacklisted';
import { request } from 'utils/api';

export const getDocuments = async (
  currentPage: number,
  blacklistedFilters: BlacklistedFilterInput,
  sortingDetails: BlacklistedSortingDetails
) => {
  const data = await request({
    url: '/blacklists',
    method: 'GET',
    params: {
      page: currentPage,
      ...blacklistedFilters,
      ...sortingDetails
    }
  });
  return data;
};

export const handleUpdateBlacklisted = async (
  id: string | undefined,
  payload: BlacklistedFormInputs
) => {
  const response = await request({
    url: `/blacklists/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export const handleAddBlacklisted = async (payload: BlacklistedFormInputs) => {
  const response = await request({
    url: '/blacklists',
    method: 'POST',
    data: payload
  });
  return response;
};

export const handleDeleteBlacklisted = async (id: string) => {
  const response = await request({
    url: `/blacklists/${id}`,
    method: 'DELETE',
    data: {}
  });
  return response;
};
