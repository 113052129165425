import PrivateRoute from 'components/appGuards/PrivateRoutes';
import { Home } from 'pages/Home';
import Login from 'pages/auth/Login';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import paths from './path';
import CustomersPage from 'pages/customers/CustomersPage';
import ChatBotPage from 'pages/chatbot/ChatbotPage';
import KnowledgeBasePage from 'pages/knowledgeBase/KnowledgeBasePage';
import KnowledgeBaseForm from 'pages/knowledgeBase/components/KnowledgeBaseForm';
import IntentsPage from 'pages/intents/IntentsPage';
import KnowledgeBaseApprovalsPage from 'pages/knowledgeBaseApprovals/KnowledgeBaseApprovalsPage';
import OrdersPage from 'pages/orders/Orders';
import RetrievalTesting from 'pages/retrievalTesting/RetrievalTesting';
// import CheckInsPage from 'pages/checkins/CheckIns';
import SmsLogsPage from 'pages/settings/pages/smsLogs/SmsLogsPage';
import AiPromptsPage from 'pages/aiPrompts/AiPrompts';
import AiPromptForm from 'pages/aiPrompts/components/AiPromptForm';
import FormRequest from 'pages/formRequest/FormRequest';
import InitialConsultations from 'pages/initialConsultations/InitialConsultations';
import VirtualFollowups from 'pages/settings/pages/virtualFollowups/VirtualFollowups';
import EmailLogsPage from 'pages/settings/pages/emailLogs/EmailLogsPage';
import Settings from 'pages/settings/Settings';
import Profile from 'pages/profile/Profile';
import Packages from 'pages/settings/pages/packages/Packages';
import InvoicePage from 'pages/invoices/InvoicesPage';
import TasksPage from 'pages/tasks/TasksPage';
import UsersPage from 'pages/settings/pages/users/UsersPage';
import ApiLogsPage from 'pages/settings/pages/apiLogs/ApiLogsPage';
import Followups from 'pages/followups/Followups';
import ICReportsPage from 'pages/settings/pages/reports/pages/ICReports/ICReportsPage';
import FollowUpReportsPage from 'pages/settings/pages/reports/pages/followUpReports/FollowUpReportsPage';
import CustomerPage from 'pages/customer/CustomerPage';
import BillingsTab from 'pages/customer/components/tabs/BillingsTab';
import FormsTab from 'pages/customer/components/tabs/FormsTab';
import ErrorPage from 'pages/errorPage/ErrorPage';
import Discounts from 'pages/settings/pages/discounts/Discounts';
import DocumentsTab from 'pages/customer/components/tabs/DocumentsTab';
import Documents from 'pages/settings/pages/documents/Documents';
import Blacklisted from 'pages/settings/pages/blacklisted/Blacklisted';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: paths.profile,
        element: <Profile />
      },
      {
        index: true,
        element: <Navigate replace={true} to={paths.customers} />
      },
      { path: paths.customers, element: <CustomersPage /> },
      { path: paths.orders, element: <OrdersPage /> },
      // { path: paths.checkins, element: <CheckInsPage /> },
      {
        path: paths.customer(),
        element: <CustomerPage />,
        children: [
          {
            index: true,
            element: <Navigate replace={true} to={paths.documentsTab()} />
          },
          {
            path: paths.customerInvoiceForm(),
            element: <BillingsTab />
          },
          { path: paths.formsTab(), element: <FormsTab /> },
          { path: paths.smsLogsTab(), element: <SmsLogsPage /> },
          { path: paths.emailLogsTab(), element: <EmailLogsPage /> },
          { path: paths.documentsTab(), element: <DocumentsTab /> },
          {
            path: paths.billingsTab(),
            element: <BillingsTab />
          },
          {
            path: paths.customerFollowupForm(),
            element: <FormsTab />
          },
          {
            path: paths.customerICForm(),
            element: <FormsTab />
          }
        ]
      },
      {
        path: paths.settings,
        element: <Settings />,
        children: [
          {
            index: true,
            element: <Navigate replace={true} to={paths.smsLogs} />
          },
          { path: paths.smsLogs, element: <SmsLogsPage /> },
          { path: paths.emailLogs, element: <EmailLogsPage /> },
          { path: paths.apiLogs, element: <ApiLogsPage /> },
          { path: paths.packages, element: <Packages /> },
          { path: paths.discounts, element: <Discounts /> },
          { path: paths.documents, element: <Documents /> },
          { path: paths.users, element: <UsersPage /> },
          { path: paths.blacklisted, element: <Blacklisted /> },
          {
            path: paths.reports,
            children: [
              {
                index: true,
                element: <Navigate replace={true} to={paths.icReports} />
              },
              { path: paths.icReports, element: <ICReportsPage /> },
              { path: paths.followUpReports, element: <FollowUpReportsPage /> }
            ]
          },
          {
            path: paths.virtualFollowups,
            element: <VirtualFollowups />
          },
          {
            path: paths.virtualFollowup(),
            element: <VirtualFollowups isSingle={true} />
          }
        ]
      },
      {
        path: paths.chatbot,
        element: <ChatBotPage />,
        children: [
          {
            index: true,
            element: <Navigate replace={true} to={paths.knowledgeBase} />
          },
          { path: paths.knowledgeBase, element: <KnowledgeBasePage /> },
          { path: paths.retrievalTesting, element: <RetrievalTesting /> },
          { path: paths.addKnowledgeBase, element: <KnowledgeBaseForm /> },
          { path: paths.editKnowledgeBase(), element: <KnowledgeBaseForm /> },
          { path: paths.intents, element: <IntentsPage /> },
          {
            path: paths.knowledgeBaseApprovals,
            element: <KnowledgeBaseApprovalsPage />
          }
        ]
      },
      {
        path: paths.aiPrompts,
        element: <AiPromptsPage />
      },
      { path: paths.editAiPrompts(), element: <AiPromptForm /> },
      {
        path: paths.formRequest,
        element: <FormRequest />
      },
      {
        path: paths.initialConsultations,
        element: <InitialConsultations />
      },
      {
        path: paths.initialConsultation(),
        element: <InitialConsultations isSingle={true} />
      },
      {
        path: paths.followups,
        element: <Followups />
      },
      {
        path: paths.followup(),
        element: <Followups isSingle={true} />
      },

      {
        path: paths.invoices,
        element: <InvoicePage />
      },
      {
        path: paths.invoice(),
        element: <InvoicePage isSingle={true} />
      },
      {
        path: paths.tasks,
        element: <TasksPage />
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  }
]);
