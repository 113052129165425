import { request } from 'utils/api';
import {
  InitialConsultationFormInputs,
  InitialConsultationsFilter
} from 'types/initialConsultations';

export const getInitialConsultations = async (
  currentPage: number,
  params?: InitialConsultationsFilter,
  sortingDetails?: any,
  clientId?: string
) => {
  const data = await request({
    url: '/form-requests',
    method: 'GET',
    params: {
      page: currentPage,
      per_page: clientId ? 10 : null,
      ...(clientId ? {} : params),
      ...sortingDetails,
      client_record_id: clientId || null
    }
  });
  return data;
};

export const getInitialConsultation = async (id: string) => {
  const data = await request({
    url: `/form-requests/${id}`,
    method: 'GET'
  });
  return data;
};

export const syncInitialConsultations = async (id: string) => {
  const data = await request({
    url: `/form-requests/sync-forms/${id}`,
    method: 'GET'
  });
  return data;
};

export const handleSendTaskMessage = async (
  payload: { task_message: string },
  Id: string
) => {
  const response = await request({
    url: `form-requests/send-task-email/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export const handleArchiveData = async (ids: string[]) => {
  const response = await request({
    url: 'form-requests/status-update',
    method: 'PUT',
    data: { ids: [...ids] }
  });
  return response;
};

export const getDropdownValues = async (id: string) => {
  const data = await request({
    url: `/form-requests/drop-downs/${id}`,
    method: 'GET'
  });
  return data;
};

export const handleUpdateInitialConsultations = async (
  payload: InitialConsultationFormInputs,
  Id: string
) => {
  const response = await request({
    url: `form-requests/${Id}`,
    method: 'PUT',
    data: { hide_notification: true, ...payload }
  });
  return response;
};

export const handleUpdateReviewedICs = async (
  payload: InitialConsultationFormInputs,
  Id: string
) => {
  const response = await request({
    url: `form-requests/update-session-notes/${Id}`,
    method: 'PUT',
    data: { ...payload, hide_notification: true }
  });
  return response;
};

export const handleSessionNotes = async (
  payload: {
    status?: string;
  },
  Id: string
) => {
  const response = await request({
    url: `/form-requests/make-session-note/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export const handleRefreshAIPrompts = async (
  columnName: string,
  id: string
) => {
  const response = await request({
    url: `form-requests/refresh-ai-prompts/${id}`,
    method: 'PUT',
    data: { column_name: columnName }
  });
  return response;
};

export const handleRecreateOrder = async (
  payload: InitialConsultationFormInputs,
  Id: string
) => {
  const response = await request({
    url: `form-requests/place-new-order/${Id}`,
    method: 'PUT',
    data: { ...payload, hide_notification: true }
  });
  return response;
};

export const handleCreateICInvoice = async (Id: string) => {
  const response = await request({
    url: `form-requests/create-invoice/${Id}`,
    method: 'PUT',
    data: {}
  });
  return response;
};

export const getUnlinkedInvoices = async (id: string) => {
  const data = await request({
    url: `/invoices/client/${id}`,
    method: 'GET'
  });
  return data;
};
