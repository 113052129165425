import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import {
  getOrders,
  getOrderStatuses,
  getOrderTimeline,
  handleAddOrder,
  handleSendOrderToPharmacy,
  handleUpdateOrder
} from '.';
import { OrderFilterInput, OrderFormInputs } from 'types/order';

export const useOrders = (
  currentPage: any,
  orderFilters: OrderFilterInput,
  sortingDetails: any
) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isDeleted = queryParams.get('is_deleted');
  return useQuery({
    queryKey: ['orders', currentPage, orderFilters, sortingDetails],
    queryFn: () =>
      getOrders(currentPage, orderFilters, sortingDetails, '', isDeleted)
  });
};

export const useOrderStatuses = () => {
  return useQuery({
    queryKey: ['order_statuses'],
    queryFn: () => getOrderStatuses()
  });
};

export const useOrderTimeline = (orderId: string) => {
  return useQuery({
    queryKey: ['order_timeline', orderId],
    queryFn: () => getOrderTimeline(orderId)
  });
};

export const useUpdateOrder = (
  infoId: string,
  options?: { onSuccess?: () => void }
): UseMutationResult<any, unknown, OrderFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: OrderFormInputs) => handleUpdateOrder(data, infoId),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });

  return mutation;
};

export const useCreateOrder = (
  clientId: string,
  options?: {
    onSuccess?: () => void;
  }
): UseMutationResult<any, unknown, OrderFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: OrderFormInputs) => handleAddOrder(data, clientId),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });

  return mutation;
};

export const useSendOrderToPharmacy = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, any, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => handleSendOrderToPharmacy(data),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });

  return mutation;
};
