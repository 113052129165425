import { tasksSlice } from './tasksSlice';
import { helperSlice } from './helperSlice';
import { ordersSlice } from './ordersSlice';
import { invoicesSlice } from './invoicesSlice';
import { followUpsSlice } from './followUpsSlice';
import { customersSlice } from './customersSlice';
import { initialConsultationsSlice } from './initialConsultationsSlice';

export const slices = [
  tasksSlice,
  helperSlice,
  ordersSlice,
  invoicesSlice,
  customersSlice,
  followUpsSlice,
  initialConsultationsSlice
];
