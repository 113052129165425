import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import Button from 'components/buttons/Button';
import { FollowupsFilter } from 'types/follow-ups';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import RangePicker from 'components/rangePicker/RangePicker';
import { useUsers } from 'pages/settings/pages/users/api/useApi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Label } from 'components/label/Label';
import { sessionTypeOptions } from 'constants/sessionTypeOptions';
import { useFollowUpsSlice } from 'store/components/stateHooks/useFollowUpsSlice';
import { getCookie } from 'utils/cookies';

interface FiltersProps {
  setCurrentPage: any;
}

export default function Filters({ setCurrentPage }: FiltersProps) {
  const { data: usersData } = useUsers(1, 30);
  const {
    followUpsSlice: { filters },
    updateFollowUpsSlice,
    resetFollowUpsSlice
  } = useFollowUpsSlice();
  const role = getCookie('role');
  const { control, handleSubmit, reset } = useForm<FollowupsFilter>({
    defaultValues: { ...filters }
  });
  useEffect(() => {
    reset({ ...filters });
  }, [filters, reset]);

  const onSubmit: SubmitHandler<FollowupsFilter> = (data) => {
    data.session_date_gte = data.session_date?.[0];
    data.session_date_lte = data.session_date?.[1];
    const updatedData = {
      ...data,
      session_date: data.session_date || [],
      session_date_gte: data.session_date?.[0] || '',
      session_date_lte: data.session_date?.[1] || ''
    };
    updateFollowUpsSlice({ filters: { ...updatedData } });
    setCurrentPage(1);
  };
  return (
    <div className='flex justify-between items-center'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='filters flex gap-1 items-center my-4'>
          <div>
            <Label className='text-sm !font-normal m-1'>First Name</Label>
            <Controller
              name='first_name'
              control={control}
              render={({ field }) => (
                <Input type='text' className='w-[100px] h-[2rem]' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Last Name</Label>
            <Controller
              name='last_name'
              control={control}
              render={({ field }) => (
                <Input className='w-[100px] h-[2rem]' type='text' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Email</Label>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input type='email' className='h-[2rem]' {...field} />
              )}
            />
          </div>
          {/* <div>
            <Label className='text-sm !font-normal m-1'>Has Form</Label>
            <Controller
              name='has_virtual_form'
              control={control}
              render={({ field }) => (
                <Select
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' }
                  ]}
                  className='w-[150px] h-[2rem]'
                  {...field}
                />
              )}
            />
          </div> */}
          <div>
            <Label className='text-sm !font-normal m-1'>Session Date</Label>
            <Controller
              control={control}
              name='session_date'
              render={({ field }) => (
                <RangePicker
                  className='!px-2.5 !py-1'
                  format='MM/DD/YYYY'
                  placeholder={['Start Date', 'End Date']}
                  {...field}
                  onChange={(_, dateStrings: [string, string]) => {
                    console.log(dateStrings);
                    field.onChange(dateStrings);
                  }}
                  value={
                    field.value
                      ? [
                          field.value[0] ? dayjs(field.value[0]) : null,
                          field.value[1] ? dayjs(field.value[1]) : null
                        ]
                      : null
                  }
                />
              )}
            />
          </div>
          {role === 'Super Admin' && (
            <div>
              <Label className='text-sm !font-normal m-1'>Consultant</Label>
              <Controller
                name='consultant_id'
                control={control}
                render={({ field }) => (
                  <Select
                    options={usersData?.users
                      ?.filter(
                        (data: { pb_consultant_id: string }) =>
                          !!data.pb_consultant_id
                      )
                      .map(
                        (data: {
                          pb_consultant_id: string;
                          first_name: string;
                          last_name: string;
                        }) => ({
                          value: data.pb_consultant_id,
                          label: `${data.first_name} ${data.last_name}`
                        })
                      )}
                    className='w-[150px] h-[2rem]'
                    {...field}
                  />
                )}
              />
            </div>
          )}
          <div>
            <Label className='text-sm !font-normal m-1'>Session Type</Label>
            <Controller
              name='session_type'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className='w-[180px] h-[1.9rem]]'
                  options={sessionTypeOptions}
                />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Status</Label>
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <Select
                  placeholder='Select Status'
                  {...field}
                  className='w-[110px] h-[1.9rem]]'
                  options={[
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Reviewed', value: 'Reviewed' },
                    { label: 'Archive', value: 'Archive' },
                    { label: 'Follow-up', value: 'Follow-up' },
                    { label: 'Duplicate', value: 'Duplicate' },
                    { label: 'Form Pending', value: 'Form Pending' },
                    { label: 'All', value: '' }
                  ]}
                />
              )}
            />
          </div>
          <div>
            <Label htmlFor='completed' className='text-sm !font-normal m-1'>
              Form Completed
            </Label>
            <Controller
              name='completed'
              control={control}
              render={({ field }) => (
                <Select
                  id='completed'
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' }
                  ]}
                  className='w-[140px]'
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Invoice</Label>
            <Controller
              name='has_pb_invoice'
              control={control}
              render={({ field }) => (
                <Select
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' }
                  ]}
                  className='w-[80px]'
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>VF Form</Label>
            <Controller
              name='has_form'
              control={control}
              render={({ field }) => (
                <Select
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' }
                  ]}
                  className='w-[80px]'
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Label className='text-sm !font-normal m-1'>Order Placed</Label>
            <Controller
              name='has_order_placed'
              control={control}
              render={({ field }) => (
                <Select
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' }
                  ]}
                  className='w-[110px]'
                  {...field}
                />
              )}
            />
          </div>
          <Button className='w-max px-3.5 h-[38px] mt-6'>
            <SearchOutlined className='!text-white' />
          </Button>
          <button
            onClick={() => resetFollowUpsSlice()}
            className='border border-orange rounded py-2 px-3 mt-6'
          >
            <MdOutlineFilterAltOff className='text-orange' />
          </button>
        </div>
      </form>
    </div>
  );
}
