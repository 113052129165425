import {
  semaglutideDoseOptions,
  tirzepatideDoseOptions,
  sermorelinDoseOptions,
  nadDoseOptions,
  nadNasalSprayDoseOptions,
  glutathioneDoseOptions
} from 'constants/doseOptions';

export const getDoseValueOptions = (medication: string) => {
  if (['Semaglutide', 'Wegovy', 'Ozempic'].includes(medication)) {
    return semaglutideDoseOptions;
  } else if (['Tirzepatide', 'Zepbound', 'Mounjaro'].includes(medication)) {
    return tirzepatideDoseOptions;
  } else if (medication === 'Sermorelin') {
    return sermorelinDoseOptions;
  } else if (medication === 'NAD+') {
    return nadDoseOptions;
  } else if (medication === 'NAD+ Nasal Spray') {
    return nadNasalSprayDoseOptions;
  } else if (medication === 'Glutathione') {
    return glutathioneDoseOptions;
  } else {
    return []; // fallback for unknown medications
  }
};
