const paths = {
  profile: '/profile',
  customers: '/customers',
  orders: '/orders',
  checkins: '/checkins',
  customer: (id = ':id') => `/customers/${id}`,
  billingsTab: () => 'billings',
  formsTab: () => 'forms',
  smsLogsTab: () => 'sms-logs',
  emailLogsTab: () => 'email-logs',
  documentsTab: () => 'documents',
  customerFollowupForm: (
    id = ':id',
    formId = ':form_id',
    formType = ':form_type'
  ) => `/customers/${id}/forms/followups/${formType}/${formId}`,
  customerICForm: (id = ':id', formId = ':form_id', formType = ':form_type') =>
    `/customers/${id}/forms/${formType}/${formId}`,
  customerInvoiceForm: (
    id = ':id',
    formId = ':form_id',
    formType = ':form_type'
  ) => `/customers/${id}/billings/invoices/${formType}/${formId}`,
  chatbot: '/chatbot',
  knowledgeBase: '/chatbot/knowledge-base',
  addKnowledgeBase: '/chatbot/add-knowledge-base',
  editKnowledgeBase: (id = ':id') => `/chatbot/knowledge-base/${id}`,
  intents: '/chatbot/intents',
  retrievalTesting: '/chatbot/retrieval-testing',
  knowledgeBaseApprovals: '/chatbot/knowledge-base-approvals',
  aiPrompts: '/ai-prompts',
  editAiPrompts: (id = ':id') => `/ai-prompts/${id}`,
  formRequest: '/form-request',
  settings: '/settings',
  smsLogs: '/settings/sms-logs',
  emailLogs: '/settings/email-logs',
  apiLogs: '/settings/api-logs',
  packages: '/settings/packages',
  discounts: '/settings/discounts',
  documents: '/settings/documents',
  blacklisted: '/settings/blacklisted',
  users: '/settings/users',
  reports: '/settings/reports',
  icReports: '/settings/reports/initial-consultations',
  followUpReports: '/settings/reports/sessions',
  initialConsultations: '/initial-consultations',
  initialConsultation: (id = ':id') => `/initial-consultations/${id}`,
  virtualFollowups: '/settings/followup-forms',
  virtualFollowup: (id = ':id') => `/settings/virtual-followups/${id}`,
  followups: '/followups',
  followup: (id = ':id', formType = ':form_type') =>
    `/followups/${formType}/${id}`,
  invoices: '/invoices',
  invoice: (id = ':form_id', formType = ':form_type') =>
    `/invoices/${formType}/${id}`,
  tasks: '/tasks'
};

export default paths;
