import { request } from 'utils/api';
import { PackagesFormInputs } from 'types/packages';

export const getPackages = async (currentPage: number) => {
  const data = await request({
    url: '/packages',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};

export const handleUpdatePackage = async (
  payload: PackagesFormInputs,
  id: string
) => {
  const response = await request({
    url: `/packages/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export const handleAddPackage = async (payload: PackagesFormInputs) => {
  const response = await request({
    url: '/packages',
    method: 'POST',
    data: payload
  });
  return response;
};

export const handleDeletePackage = async (id: string) => {
  const response = await request({
    url: `/packages/${id}`,
    method: 'DELETE'
  });
  return response;
};

export const getPackagesName = async () => {
  const data = await request({
    url: '/packages/pb_packages',
    method: 'GET'
  });
  return data;
};
