import { FaSort } from 'react-icons/fa';
import formatDate from 'utils/helpers/date';
import { ColumnDef } from '@tanstack/react-table';
import {
  BlacklistedResponse,
  BlacklistedSortingDetails
} from 'types/blacklisted';
import Drawer from 'components/drawer/Drawer';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  SyncOutlined
} from '@ant-design/icons';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import BlacklistedForm from './BlacklistedForm';

export const Columns = (
  sortingDetails: BlacklistedSortingDetails,
  setSortingDetails: any,
  deleteBlackListedItem: any,
  isDeletePending: boolean,
  processingId: string,
  setProcessingId: any
): ColumnDef<BlacklistedResponse>[] => {
  return [
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Created At</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'created_at',
                sort_order:
                  sortingDetails?.sort_by === 'created_at' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'created_at',
      cell: (info) => formatDate(info.getValue<string>())
    },
    {
      header: 'Category',
      accessorKey: 'category'
    },
    {
      header: 'Description',
      accessorKey: 'description'
    },
    {
      header: 'Value',
      accessorKey: '',
      cell: (info) => {
        let isAddress = info.row.original.category === 'Address';
        let currAddress = info.row.original.address;
        return isAddress
          ? `${currAddress.street}, ${currAddress.city}, ${currAddress.state}, ${currAddress.country} (${currAddress.postal_code})`
          : info.row.original.value;
      }
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Updated At</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'updated_at',
                sort_order:
                  sortingDetails?.sort_by === 'updated_at' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'updated_at',
      cell: (info) => formatDate(info.getValue<string>())
    },
    {
      header: 'Action',
      accessorKey: '',
      cell: (info) => (
        <div className='flex flex-row gap-2'>
          <Drawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            button={
              <>
                <EditOutlined className='mr-1' />
                Edit
              </>
            }
            title='Edit Blacklist Item'
            closeIcon={<ArrowRightOutlined />}
          >
            <BlacklistedForm info={info.row.original} />
          </Drawer>
          <PopupConfirm
            title='Remove from Blacklist'
            description='Are you sure you want to remove this?'
            onConfirm={() => {
              setProcessingId(info.row.original?.id);
              deleteBlackListedItem(info.row.original?.id);
            }}
          >
            <button className='text-red'>
              {isDeletePending && processingId === info.row.original?.id ? (
                <SyncOutlined spin />
              ) : (
                <DeleteOutlined />
              )}
            </button>
          </PopupConfirm>
        </div>
      )
    }
  ];
};
