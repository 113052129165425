import React from 'react';
import { ConfigProvider, Space, Switch as AntdSwitch, SwitchProps } from 'antd';

interface CustomSwitchProps extends SwitchProps {
  checkedLabel?: React.ReactNode;
  uncheckedLabel?: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
}

const Switch: React.FC<CustomSwitchProps> = ({
  checkedLabel,
  uncheckedLabel,
  direction = 'horizontal',
  defaultChecked,
  ...props
}) => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'rgb(252 103 32)'
      }
    }}
  >
    <Space direction={direction}>
      <AntdSwitch
        checkedChildren={checkedLabel}
        unCheckedChildren={uncheckedLabel}
        defaultChecked={defaultChecked}
        {...props}
      />
    </Space>
  </ConfigProvider>
);

export default Switch;
