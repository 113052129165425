import { SmsLogFilters, SmsLogSortingDetails } from 'types/smsLogs';
import { request } from 'utils/api';

export const getSmsLogs = async (
  currentPage: number,
  smsLogFilters: SmsLogFilters,
  sortingDetails: SmsLogSortingDetails,
  clientId?: string
) => {
  const data = await request({
    url: '/sms-logs',
    method: 'GET',
    params: {
      page: currentPage,
      ...smsLogFilters,
      ...sortingDetails,
      per_page: clientId ? 20 : null,
      client_record_id: clientId
    }
  });
  return data;
};
