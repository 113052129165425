import { useAppStore } from 'store/store';

export const useFollowUpsSlice = () => {
  const followUpsSlice = useAppStore((state) => state.followUpsSlice);
  const updateFollowUpsSlice = useAppStore(
    (state) => state.updateFollowUpsSlice
  );
  const resetFollowUpsSlice = useAppStore((state) => state.resetFollowUpsSlice);

  return { followUpsSlice, updateFollowUpsSlice, resetFollowUpsSlice };
};
