import React from 'react';
import { Spin } from 'antd';
import paths from 'constants/path';
import { Link } from 'react-router-dom';
import { FaSort } from 'react-icons/fa';
import formatDate from 'utils/helpers/date';
import { joinName } from 'utils/helpers/name';
import { SyncOutlined } from '@ant-design/icons';
import Tooltip from 'components/tooltip/Tooltip';
import { useRefreshInvoice } from '../api/useApi';
import { InvoicesResponse } from 'types/invoices';
import { ColumnDef } from '@tanstack/react-table';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';

export const Columns = (
  automateInvoice: (id: string) => void,
  isAutomationPending: boolean,
  setProcessingId: any,
  processingId: string,
  setSortingDetails: any,
  sortingDetails: any,
  clientId?: string,
  updateHelper?: any
): ColumnDef<InvoicesResponse>[] => {
  const { mutateAsync: refreshInvoice, isPending: refreshingInvoice } =
    useRefreshInvoice();
  const getFormRoute = (info: any) => {
    const formId =
      info.row.original.form_request_id ||
      info.row.original.virtual_follow_up_id;
    const formType = info.row.original.form_request_id ? 'ic' : 'vf';
    return clientId
      ? paths.customerInvoiceForm(clientId, formId, formType)
      : paths.invoice(formId, formType);
  };

  const allColumns: ColumnDef<InvoicesResponse>[] = [
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Created At</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'date_created',
                sort_order:
                  sortingDetails?.sort_by === 'date_created' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'date_created',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: 'Name',
      accessorKey: 'first_name',
      cell: (info) => (
        <a
          href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
          rel='noopener noreferrer'
          target='_blank'
          className='flex items-center gap-2'
        >
          <p className='underline text-orange underline-offset-4'>
            {joinName(info.getValue<string>(), info.row.original.last_name)}
          </p>
        </a>
      )
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (info) => (
        <Link
          to={paths.customer(String(info.row.original.client_record_id))}
          onClick={() => {
            updateHelper({ customerSourceRoute: paths.invoices });
          }}
          className='text-orange underline underline-offset-4'
        >
          {info.getValue<string>()}
        </Link>
      )
    },
    {
      header: 'Invoice Id',
      accessorKey: 'pb_invoice_id',
      cell: (info) => (
        <Tooltip title='Click to open Invoice in PB'>
          <a
            href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}/billing/${info.row.original.pb_invoice_id}/view`}
            rel='noopener noreferrer'
            target='_blank'
            className='flex items-center gap-2'
          >
            <p className='underline text-orange underline-offset-4'>
              {info.getValue<string>()}
            </p>
          </a>
        </Tooltip>
      )
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (info) =>
        info.getValue() && (
          <p
            className={`border rounded-full py-1 px-2 text-xs text-center w-[4rem] ${getStatusColorClasses(info.getValue() as string)}`}
          >
            {capitalizeFirstLetter(info.getValue() as string)}
          </p>
        )
    },
    {
      header: 'Invoice Amount ($)',
      accessorKey: 'total_amount'
    },
    {
      header: 'Amount Paid ($)',
      accessorKey: 'amount_paid'
    },
    {
      header: 'Due Amount ($)',
      accessorKey: 'due_amount'
    },
    {
      header: 'Refunded Amount {$}',
      accessorKey: 'refunded_amount'
    },
    {
      header: 'Payment Date',
      accessorKey: 'payment_date',
      cell: (info) =>
        (info.getValue() as string) !== 'N/A' &&
        formatDate(info.getValue() as string)
    },
    {
      header: 'Refunded',
      accessorKey: 'refunded',
      cell: (info) => (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
        >
          {info.getValue() ? 'Yes' : 'No'}
        </p>
      )
    },
    {
      header: 'Updated At',
      accessorKey: 'updated_at',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: 'Action',
      accessorKey: 'email',
      cell: (info) => (
        <div className='text-nowrap'>
          {!clientId && (
            <button
              disabled={isAutomationPending || info.row.original.ic_status}
              className={`text-orange border border-1 p-1 border-orange rounded-lg  cursor-pointer whitespace-nowrap font-medium mr-0.5 ml-2 hover:bg-orange hover:text-white ${processingId === info.row.original.id && isAutomationPending && 'bg-orange'} disabled:cursor-not-allowed disabled:opacity-50 w-36`}
              onClick={() => {
                setProcessingId(String(info.row.original.id));
                automateInvoice(String(info.row.original.id));
              }}
            >
              {processingId === info.row.original.id && isAutomationPending ? (
                <Spin />
              ) : info.row.original.ic_status ? (
                <Tooltip title='Automation Done!'>Invoice Automation</Tooltip>
              ) : (
                'Invoice Automation'
              )}
            </button>
          )}
          <Link to={getFormRoute(info)}>
            <button
              disabled={Boolean(
                !info.row.original.form_request_id ||
                  info.row.original.virtual_follow_up_id
              )}
              className='text-orange border border-1 p-1 border-orange rounded-lg  cursor-pointer whitespace-nowrap font-medium mr-0.5 ml-2 hover:bg-orange hover:text-white disabled:cursor-not-allowed disabled:opacity-50 w-30'
            >
              {info.row.original.form_request_id ? (
                'View Form'
              ) : (
                <Tooltip title='Form not present.'>View Form</Tooltip>
              )}
            </button>
          </Link>
          <button
            disabled={refreshingInvoice}
            className='text-orange ml-2 text-lg disabled:opacity-50'
            onClick={() => {
              setProcessingId(info.row.original.id);
              refreshInvoice(info.row.original.id);
            }}
          >
            <Tooltip title='Refresh Status'>
              {refreshingInvoice && processingId === info.row.original.id ? (
                <SyncOutlined className='cursor-not-allowed' spin />
              ) : (
                <SyncOutlined className='cursor-pointer' />
              )}
            </Tooltip>
          </button>
        </div>
      )
    }
  ];

  // Filter out 'Name' and 'Email' columns if customersPageView
  return clientId
    ? allColumns.filter(
        (column) => column.header !== 'Name' && column.header !== 'Email'
      )
    : allColumns;
};
