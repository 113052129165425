import { useState } from 'react';
import { handleSendOrderToPharmacy } from '../apis';
import { useSendOrderToPharmacy } from '../apis/useApi';
import useConfirmModal from 'components/confirmModal/ConfirmModal';

export const usePharmacyButton = () => {
  const [sending, setSending] = useState(false);
  const { showConfirm, contextHolder } = useConfirmModal();
  const { mutateAsync: sendOrderToPharmacy, isPending } =
    useSendOrderToPharmacy();
  const sendToPharmacy = async (
    orderId: string,
    pharmacy: string,
    orderDetails?: any,
    address?: any
  ) => {
    setSending(true);
    const res = orderDetails
      ? null
      : await handleSendOrderToPharmacy({ order_id: orderId });
    if (res?.consent === 'required') {
      showConfirm({
        title: 'Warning',
        content:
          'The same medication was ordered less than 10 days ago. Proceeding may create a duplicate. Continue?',
        okText: 'Continue',
        cancelText: 'Cancel',
        onConfirm: async () => {
          let resp = await handleSendOrderToPharmacy({
            order_id: orderId,
            consent: true
          });
          sendToPharmacy(orderId, pharmacy, resp?.order_details, resp?.address);
        },
        wrapClassName: 'mt-[10rem]'
      });
    } else {
      if (orderDetails || res?.order_details) {
        showConfirm({
          title: `Order Details - ${pharmacy}`,
          okText: 'Continue',
          cancelText: 'Cancel',
          pharmacy: pharmacy,
          orderDetails: orderDetails ?? res?.order_details,
          address: address ?? res?.address,
          onConfirm: () =>
            sendOrderToPharmacy({
              order_id: orderId,
              order_confirmation: true,
              consent: true
            }),
          wrapClassName: 'mt-[10rem]'
        });
      }
    }
    setSending(false);
  };
  let sendingToPharmacy = sending || isPending;
  return { contextHolder, sendToPharmacy, sendingToPharmacy };
};
