import { StateCreator } from 'zustand';
import { HelperSlice } from '../types/helper';
import paths from 'constants/path';

const initialState = {
  customerSourceRoute: paths.customers
};

export const helperSlice: StateCreator<HelperSlice> = (set) => ({
  helper: initialState,
  updateHelper: (data: any) => {
    set((state) => ({
      helper: {
        ...state.helper,
        ...data
      }
    }));
  },
  resetHelper: () => set({ helper: initialState })
});
