import React from 'react';
import { Avatar } from 'antd';
import Tooltip from 'components/tooltip/Tooltip';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import { useHelper } from 'store/components/stateHooks/useHelper';
import {
  ArrowLeftOutlined,
  MailOutlined,
  PhoneOutlined,
  SyncOutlined
} from '@ant-design/icons';
import {
  useCancelServices,
  useCancelSessions,
  useCustomerData,
  useRefreshClient,
  useSendVerificationLink
} from '../api/useApi';
import CopyToClipboard from 'components/copyToClipboard/CopyToClipBoard';
import formatDate from 'utils/helpers/date';
import { AddressFormatter } from 'utils/helpers/AddressFormatter';

export default function Sidebar() {
  const { id } = useParams();
  const { helper, resetHelper } = useHelper();
  const { data: customerData } = useCustomerData(String(id));
  const { mutate: refreshClient, isPending: refreshingClient } =
    useRefreshClient();
  const { mutate: cancelSessions, isPending: cancelingSessions } =
    useCancelSessions();
  const { mutate: cancelServices, isPending: cancelingServices } =
    useCancelServices();
  const { mutate: sendVerificationLink, isPending: sendingLink } =
    useSendVerificationLink();
  return (
    <div className='bg-white rounded-xl border border-light-grey h-full'>
      <div className='bg-light-orange rounded-xl py-4 px-3'>
        <div className='flex justify-between'>
          <Link to={helper.customerSourceRoute}>
            <ArrowLeftOutlined
              onClick={() => resetHelper()}
              className='text-orange bg-light-orange p-2 rounded-full'
            />
          </Link>
          <div className='bg-light-orange text-base p-2 rounded-full text-orange'>
            {refreshingClient ? (
              <SyncOutlined spin />
            ) : (
              <Tooltip title='Refresh Client.'>
                <SyncOutlined onClick={() => refreshClient(String(id))} />
              </Tooltip>
            )}
          </div>
        </div>
        <Avatar
          className='w-20 h-20 block mx-auto'
          src='https://api.dicebear.com/7.x/miniavs/svg?seed=1'
        />
        <div className='flex flex-row items-center justify-center mt-4 gap-1 text-xl font-semibold'>
          <p>{`${customerData?.first_name} ${customerData?.last_name}`}</p>
          {customerData?.verification_status && (
            <Tooltip title='Verified'>
              <RiVerifiedBadgeFill className='text-green' />
            </Tooltip>
          )}
        </div>
        {customerData?.blacklisted && (
          <div className='text-center'>
            <span className='text-sm text-grey border border-grey bg-light-grey px-4 rounded-xl text-center'>
              Blacklisted
            </span>
          </div>
        )}
      </div>
      <div className='p-5'>
        <div className='flex gap-2 items-center'>
          <MailOutlined className='text-silver' />
          <span className='text-sm'>
            <CopyToClipboard fieldName='email'>
              {customerData?.email ? String(customerData?.email) : ''}
            </CopyToClipboard>
          </span>
        </div>
        <div className='flex gap-2 items-center mt-4'>
          <PhoneOutlined className='w-4 h-4 text-silver' rotate={90} />
          <span className='text-sm'>{customerData?.mobile_phone}</span>
        </div>
      </div>
      <div className='border-t border-light-grey mx-5' />
      <div className='p-5'>
        <p className='text-sm text-silver'>Patient ID</p>
        <p className='font-semibold text-sm'>{customerData?.id}</p>
        <p className='text-sm text-silver mt-4'>Date of Birth</p>
        <p className='font-semibold text-sm'>
          {formatDate(
            customerData?.profile?.dayOfBirth ??
              customerData?.profile?.dateOfBirth ??
              ''
          )}{' '}
          ({customerData?.age} Years)
        </p>
        <p className='text-sm text-silver mt-4'>Gender</p>
        <p className='font-semibold text-sm'>
          {customerData?.profile.genderIdentity === 'Man/Boy'
            ? 'Male'
            : 'Female'}
        </p>
        <p className='text-sm text-silver mt-4'>Location</p>
        <p className='font-semibold text-sm'>
          <AddressFormatter address={customerData?.profile.address} />
        </p>
      </div>
      <div className='border-t border-light-grey mx-5' />
      <div className='flex flex-row justify-between px-6 py-3'>
        <Link
          to={`https://my.practicebetter.io/#/p/clients/${customerData?.id}`}
          className='text-orange text-sm flex flex-row gap-1'
          target='_blank'
        >
          Practice Better
          <FaExternalLinkAlt className='mt-[2px]' />
        </Link>
        {customerData?.ghl && (
          <Link
            to={String(customerData?.ghl)}
            className='text-orange text-sm flex flex-row gap-1 mr-3'
            target='_blank'
          >
            GHL
            <FaExternalLinkAlt className='mt-[2px]' />
          </Link>
        )}
      </div>
      <div className='border-t border-light-grey mx-5 pt-1' />
      {!customerData?.verification_status && (
        <PopupConfirm
          title='Send Verification Link'
          description={
            <span>
              {customerData?.verification_link_sent_at && (
                <span>
                  You sent verification link last on{' '}
                  <b>{formatDate(customerData?.verification_link_sent_at)}</b>
                </span>
              )}
              <p>
                Are you sure you want to send verification link to this client?
              </p>
            </span>
          }
          onConfirm={() => sendVerificationLink(String(id))}
        >
          <button className='text-sm text-white p-1 flex flex flex-row border border-orange bg-orange rounded-lg px-3 mx-5 mt-2'>
            {sendingLink ? 'Sending...' : 'Send Verification Link'}
          </button>
        </PopupConfirm>
      )}
      {customerData?.is_active && (
        <div className='flex flex-col justify-between px-5 py-2'>
          <PopupConfirm
            title='Cancel Client Sessions'
            description='Are you sure you want to cancel sessions of this client?'
            onConfirm={() => cancelSessions(String(id))}
          >
            <button className='text-sm text-white p-1 flex flex flex-row border border-orange bg-orange rounded-lg mb-2 px-3'>
              {cancelingSessions ? 'Canceling...' : 'Cancel Sessions'}
            </button>
          </PopupConfirm>
          <PopupConfirm
            title='Cancel Client Services'
            description='Are you sure you want to cancel all services of this client?'
            onConfirm={() => cancelServices(String(id))}
          >
            <button className='text-sm text-white p-1 flex flex flex-row border border-orange bg-orange rounded-lg px-3'>
              {cancelingServices ? 'Canceling...' : 'Cancel Services'}
            </button>
          </PopupConfirm>
        </div>
      )}
    </div>
  );
}
