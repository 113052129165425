import React from 'react';
import { Label } from 'components/label/Label';
import Button from 'components/buttons/Button';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { BlacklistedFilterInput } from 'types/blacklisted';
import Select from 'components/select/Select';

type FiltersProps = {
  setBlacklistedFilters: (data: BlacklistedFilterInput) => void;
  setCurrentPage: (page: number) => void;
  blacklistedFilters: Partial<BlacklistedFilterInput>;
};

export default function Filters({
  setBlacklistedFilters,
  setCurrentPage,
  blacklistedFilters
}: FiltersProps) {
  const { control, handleSubmit, reset } = useForm<BlacklistedFilterInput>({
    defaultValues: {
      ...blacklistedFilters
    }
  });

  const onSubmit: SubmitHandler<BlacklistedFilterInput> = (data) => {
    setBlacklistedFilters(data);
    setCurrentPage(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center mb-5'>
        <div className='w-40'>
          <Label className='text-sm !font-normal m-1'>Category</Label>
          <Controller
            name='category'
            control={control}
            render={({ field }) => (
              <Select
                className='h-[2rem] w-[10rem]'
                {...field}
                placeholder='Select'
                options={[
                  { value: 'Email', label: 'Email' },
                  { value: 'Mobile', label: 'Mobile' },
                  { value: 'Address', label: 'Address' }
                ]}
              />
            )}
          />
        </div>
        <Button type='submit' className='w-max px-3.5 h-[38px] mt-6'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() =>
            reset({
              category: ''
            })
          }
          className='border border-orange rounded py-2 px-3 mt-6'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
