import { OrderFilterInput, OrderFormInputs } from 'types/order';
import { request } from 'utils/api';

export const getOrders = async (
  currentPage: number,
  params?: OrderFilterInput,
  sortingDetails?: any,
  isDownload?: any,
  isDeleted?: any
) => {
  const data = await request({
    url: '/orders',
    method: 'GET',
    params: {
      page: currentPage,
      ...params,
      ...sortingDetails,
      download: isDownload,
      is_deleted: isDeleted
    }
  });
  return data;
};

export const getOrderStatuses = async () => {
  const data = await request({
    url: '/orders/ups-dropdown',
    method: 'GET'
  });
  return data;
};

export const getOrderTimeline = async (id: string) => {
  const data = await request({
    url: `/orders/order-timeline/${id}`,
    method: 'GET'
  });
  return data;
};

export const handleUpdateOrder = async (
  payload: OrderFormInputs,
  id: string
) => {
  const response = await request({
    url: `/orders/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export const handleAddOrder = async (
  payload: OrderFormInputs,
  clientId: string
) => {
  const response = await request({
    url: '/orders',
    method: 'POST',
    data: payload,
    params: { client_id: clientId }
  });
  return response;
};

export const handleSendOrderToPharmacy = async (payload: any) => {
  const response = await request({
    url: `/orders/send-to-pharmacy/${payload.order_id}`,
    method: 'POST',
    data: { ...payload }
  });
  return response;
};
