import { useQuery } from '@tanstack/react-query';
import { ProfileResponse } from 'types/profile';
import { getProfile } from '.';
import { getCookie } from 'utils/cookies';

export const useProfile = () => {
  const token = getCookie('token');
  return useQuery<ProfileResponse>({
    queryKey: ['profile_data', token],
    queryFn: () => getProfile(),
    enabled: Boolean(token)
  });
};
