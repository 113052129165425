import React from 'react';
import { Link } from 'react-router-dom';
import formatDate from 'utils/helpers/date';
import Tooltip from 'components/tooltip/Tooltip';
import { useRefreshInvoice } from 'pages/invoices/api/useApi';
import { LinkOutlined, SyncOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';

export default function ReviewedFormDetails({ formData }: { formData: any }) {
  const { mutateAsync: refreshInvoice, isPending: refreshingInvoice } =
    useRefreshInvoice();
  const fieldsLeft = [
    { label: 'Reviewed At', value: formatDate(formData?.reviewed_at) },
    {
      label: 'Payment Date',
      value: formatDate(formData?.pb_invoice?.payment_date)
    },
    {
      label: 'Order Date',
      value: formatDate(formData?.order_date)
    }
  ];

  const fieldsCenter = [
    {
      label: 'Invoice Status',
      value: formData?.pb_invoice?.status
        ? capitalizeFirstLetter(formData?.pb_invoice?.status)
        : 'Not Available'
    },
    {
      label: 'Amount Paid',
      value: formData?.pb_invoice?.amount_paid
    }
  ];

  const fieldsRight = [
    {
      label: 'PB Invoice Link',
      value: formData?.pb_invoice?.pb_link ? (
        <Link to={formData?.pb_invoice?.pb_link} target='_blank'>
          <LinkOutlined />
        </Link>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Refresh Invoice',
      value: formData?.pb_invoice?.id ? (
        <Tooltip title='Refresh Status'>
          {refreshingInvoice ? (
            <SyncOutlined className='cursor-not-allowed' spin />
          ) : (
            <SyncOutlined
              className='cursor-pointer'
              onClick={() => refreshInvoice(formData?.pb_invoice?.id)}
            />
          )}
        </Tooltip>
      ) : (
        'No Invoice'
      )
    }
  ];

  return (
    <div className='border border-1 rounded p-2 my-3 mx-5 bg-lime-100 text-lime-800  border-lime-800'>
      <div className='flex flex-row text-xs'>
        {[fieldsLeft, fieldsCenter, fieldsRight].map((fields, index) => (
          <div
            key={index}
            className={`flex flex-col w-1/2 ${index !== 2 ? 'border-r mr-3' : ''}`}
          >
            {fields.map((field, i) => (
              <span key={i} className='flex flex-row'>
                <span className='font-medium'>{field.label}:</span>
                <span className='ml-1'>{field.value}</span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
