import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PharmacyOrderDetails from 'pages/orders/components/PharmacyOrderDetails';

interface ConfirmModalProps {
  title?: string;
  content?: any;
  okText?: string;
  cancelText?: string;
  pharmacy?: string;
  icon?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  orderDetails?: any;
  address?: any;
  width?: number;
  centered?: boolean;
  wrapClassName?: string;
}

export default function useConfirmModal() {
  const [modal, contextHolder] = Modal.useModal();
  const showConfirm = ({
    title = 'Confirm',
    content = 'Are you sure?',
    okText = 'OK',
    cancelText = 'Cancel',
    pharmacy,
    icon = <ExclamationCircleOutlined />,
    onConfirm,
    onCancel,
    width = 800,
    orderDetails,
    address,
    centered = true,
    wrapClassName
  }: ConfirmModalProps) => {
    modal.confirm({
      title,
      content:
        title === `Order Details - ${pharmacy}` && orderDetails ? (
          <PharmacyOrderDetails
            orderDetails={orderDetails}
            pharmacy={pharmacy}
            address={address}
          />
        ) : (
          content
        ),
      okText,
      cancelText,
      icon,
      onOk: onConfirm,
      onCancel,
      wrapClassName,
      width,
      centered
    });
  };

  return { showConfirm, contextHolder };
}
