import React from 'react';
import { notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Tooltip from 'components/tooltip/Tooltip';

interface CopyToClipboardProps {
  children: string;
  fieldName?: string;
  iconClasses?: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  children,
  fieldName,
  iconClasses
}) => {
  const copyText = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          notification.success({
            message: `The ${fieldName ?? 'text'} has been successfully copied.`
          });
        })
        .catch(() => {
          notification.error({
            message: 'An error occurred while copying to the clipboard.'
          });
        });
    } else {
      notification.warning({
        message: 'Your browser does not supported.'
      });
    }
  };

  return (
    <div className='d-flex flex-row'>
      <span>{children}</span>
      {children && (
        <Tooltip title={`Copy ${fieldName ?? 'text'}`}>
          <CopyOutlined
            className={`text-orange mx-1 ${iconClasses}`}
            onClick={() => copyText(children)}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default CopyToClipboard;
