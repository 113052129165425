import { useDocuments } from './api/useApi';
import { Columns } from './components/Columns';
import React, { useMemo, useState } from 'react';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Filters from './components/Filters';

export default function Documents({ clientId }: { clientId?: string }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [documentsFilters, setDocumentsFilters] = useState({
    file_name: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });
  const { data: DocumentsData, isPending: isDocumentsLoading } = useDocuments(
    currentPage,
    documentsFilters,
    sortingDetails,
    clientId
  );

  const memorizedDocumentsData = useMemo(() => {
    return DocumentsData?.documents ?? [];
  }, [DocumentsData?.documents]);

  return (
    <div className={`${!clientId && 'p-5 bg-[#FAFBFC]'}`}>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between mt-3'>
          <h4 className='text-xl font-semibold mb-3'>Documents</h4>
        </div>
        {!clientId && (
          <Filters
            setCurrentPage={setCurrentPage}
            documentsFilters={documentsFilters}
            setDocumentsFilters={setDocumentsFilters}
          />
        )}
        <div
          className={`overflow-auto ${clientId ? 'h-[calc(100vh_-_330px)]' : 'h-[calc(100vh_-_360px)]'}`}
        >
          <DataTable
            data={memorizedDocumentsData}
            columns={Columns(sortingDetails, setSortingDetails, clientId)}
            isLoading={isDocumentsLoading}
          />
        </div>
      </div>
      <Pagination
        totalCount={DocumentsData?.pagination.total_count}
        totalPage={DocumentsData?.pagination.total_pages}
        perPage={DocumentsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
