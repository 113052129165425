import { SmsLogFilters, SmsLogSortingDetails } from 'types/smsLogs';
import { getSmsLogs } from '.';
import { useQuery } from '@tanstack/react-query';

export const useSmsLogs = (
  currentPage: number,
  smsLogFilters: SmsLogFilters,
  sortingDetails: SmsLogSortingDetails,
  clientId?: string
) => {
  return useQuery({
    queryKey: [
      'sms-logs',
      currentPage,
      smsLogFilters,
      sortingDetails,
      clientId
    ],
    queryFn: () =>
      getSmsLogs(currentPage, smsLogFilters, sortingDetails, clientId)
  });
};
