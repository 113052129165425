import React from 'react';
import Orders from '../Orders';
import { useParams } from 'react-router-dom';
import InvoicesPage from 'pages/invoices/InvoicesPage';

export default function BillingsTab() {
  const { id, form_id } = useParams();
  return (
    <div>
      <Orders />
      <InvoicesPage clientId={id} isSingle={Boolean(form_id)} />
    </div>
  );
}
