import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import ReportPage from '../../ReportPage';
import Filters from './components/Filters';
import { Columns } from './components/Columns';
import { useFollowUpsReports } from './api/useApi';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import FollowUpsAnalytics from './components/FollowUpsAnalytics';
import { getFollowUpReports } from './api';
import {
  downloadXLSX,
  reorderObjectKeys,
  transformCSVData
} from 'utils/helpers/csvDownloader';

export default function FollowUpReportsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [followUpReportsFilters, setFollowUpReportsFilters] = useState({
    session_date: [
      dayjs().startOf('month').format('MM/DD/YYYY'),
      dayjs().endOf('month').format('MM/DD/YYYY')
    ],
    session_date_gte: dayjs().startOf('month').format('MM/DD/YYYY'),
    session_date_lte: dayjs().endOf('month').format('MM/DD/YYYY'),
    consultant_id: '',
    session_type: '',
    completed: '',
    order_placed: '',
    has_session_note: '',
    has_reviewed: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'session_date',
    sort_order: 'desc'
  });
  const [downloadingData, setDownloadingData] = useState(false);

  const downloadTasksCSV = async () => {
    setDownloadingData(true);
    const keyOrder = [
      'name',
      'email',
      'ai_processed',
      'reviewed_at',
      'session_type',
      'consultant',
      'client_record_id',
      'form_request_id',
      'order_placed'
    ];
    let response = await getFollowUpReports(
      currentPage,
      followUpReportsFilters,
      sortingDetails,
      true
    );
    if (response) {
      const reorderedResponse = reorderObjectKeys(response, keyOrder);
      downloadXLSX(
        transformCSVData(reorderedResponse),
        `${followUpReportsFilters?.session_date?.[0] ? `Follow ups Report-${followUpReportsFilters?.session_date?.[0]} to ${followUpReportsFilters?.session_date?.[1]}` : 'Follow ups Report'}`
      );
    }
    setDownloadingData(false);
  };

  const { data: FollowUpReportsData, isLoading } = useFollowUpsReports(
    currentPage,
    followUpReportsFilters,
    sortingDetails
  );
  const memoizedFollowUpsReportData = useMemo(() => {
    return FollowUpReportsData?.sessions ?? [];
  }, [FollowUpReportsData?.sessions]);

  return (
    <ReportPage name='followUp_reports'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5 shadow-xl mb-4'>
        <div className='flex flex-row justify-between'>
          <h1 className='text-xl font-semibold'>Sessions Report</h1>
          <button
            disabled={downloadingData}
            className={`border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 ${downloadingData && 'opacity-50'}`}
            onClick={downloadTasksCSV}
          >
            {downloadingData ? 'Downloading...' : 'Download'}
          </button>
        </div>
        <FollowUpsAnalytics
          analytics_data={FollowUpReportsData?.analytics_data}
          dataLoading={isLoading}
        />
        <Filters
          setCurrentPage={setCurrentPage}
          followUpReportsFilters={followUpReportsFilters}
          setFollowUpReportsFilters={setFollowUpReportsFilters}
        />
        <div className='overflow-auto h-[calc(100vh_-_415px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedFollowUpsReportData}
            columns={Columns(sortingDetails, setSortingDetails)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={FollowUpReportsData?.pagination.total_count}
        totalPage={FollowUpReportsData?.pagination.total_pages}
        perPage={FollowUpReportsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </ReportPage>
  );
}
