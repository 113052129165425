export const getICPackageStatus = (state: string, formId?: string) => {
  const ICForms = [
    { id: '678a087f8639ad4f5c852292', states: ['AR', 'KS', 'MI', 'MN', 'MS'] },
    { id: '678a07358639ad4f5c851fc4', states: ['CA'] },
    { id: '67901483b91d415245e2be6c', states: ['AR', 'KS', 'MI', 'MN', 'MS'] },
    { id: '67901066b91d415245e277f0', states: ['CA'] },
    {
      id: '67d138817568c354bc68be83',
      states: ['AL', 'AR', 'CA', 'KS', 'MI', 'MN', 'MS']
    },
    {
      id: '67d139407568c354bc68c1b0',
      states: ['AL', 'AR', 'CA', 'KS', 'MI', 'MN', 'MS']
    }
  ];
  const formData = ICForms.find((form) => form.id === formId);
  return formData ? formData.states.includes(state) : true;
};
