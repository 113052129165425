import React from 'react';
import { FaSort } from 'react-icons/fa';
import DiscountForm from './DiscountForm';
import formatDate from 'utils/helpers/date';
import Drawer from 'components/drawer/Drawer';
import Switch from 'components/switch/Switch';
import { ColumnDef } from '@tanstack/react-table';
import { DiscountsResponse } from 'types/discounts';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  SyncOutlined
} from '@ant-design/icons';

export const Columns = (
  deleteDiscount: any,
  isDeletePending: boolean,
  updateDiscount: any,
  processingDiscountId: string,
  setProcessingDiscountId: any,
  sortingDetails: any,
  setSortingDetails: any
): ColumnDef<DiscountsResponse>[] => [
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Created At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Name',
    accessorKey: 'name'
  },
  {
    header: 'Code',
    accessorKey: 'code'
  },

  {
    header: 'Description',
    accessorKey: 'description'
  },
  {
    header: 'Amount',
    accessorKey: 'amount'
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Updated At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'updated_at',
              sort_order:
                sortingDetails?.sort_by === 'updated_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: (info) => (
      <PopupConfirm
        title={info.getValue() ? 'Deactivate Discount' : 'Activate Discount'}
        description={`Are you sure you want to ${info.getValue() ? 'deactivate discount' : 'activate discount'} ?`}
        onConfirm={() => {
          updateDiscount({
            id: String(info.row.original.id),
            status: !info.getValue()
          });
        }}
      >
        <Switch
          checkedLabel='Active'
          uncheckedLabel='Inactive'
          defaultChecked
          value={Boolean(info.getValue())}
        />
      </PopupConfirm>
    )
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        <Drawer
          styles={{
            header: { display: 'flex', flexDirection: 'row-reverse' }
          }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Discount'
          closeIcon={<ArrowRightOutlined />}
        >
          <DiscountForm info={info.row.original} />
        </Drawer>
        <PopupConfirm
          title='Delete Discount'
          description='Are you sure you want to delete discount?'
          onConfirm={() => {
            setProcessingDiscountId(info.row.original?.id);
            deleteDiscount(info.row.original?.id);
          }}
        >
          <button className='text-red'>
            {isDeletePending &&
            processingDiscountId === info.row.original?.id ? (
              <SyncOutlined spin />
            ) : (
              <DeleteOutlined />
            )}
          </button>
        </PopupConfirm>
      </div>
    )
  }
];
