import React from 'react';
import { Tooltip as TooltipComponent } from 'antd';

interface TooltipProps {
  title: string;
  className?: string;
  children: React.ReactNode;
}

export default function Tooltip({ title, children, className }: TooltipProps) {
  return (
    <TooltipComponent className={className} title={title}>
      {children}
    </TooltipComponent>
  );
}
