import { SyncOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

interface HeaderActionButtonsProps {
  selectedRows: any;
  archiveData: any;
  isSyncPending: boolean;
  isArchivePending: boolean;
  syncVirtualFollowupsForms: any;
  downloadingData: boolean;
  downloadTasksCSV: any;
}

const HeaderActionButtons: React.FC<HeaderActionButtonsProps> = ({
  archiveData,
  selectedRows,
  isSyncPending,
  isArchivePending,
  syncVirtualFollowupsForms,
  downloadingData,
  downloadTasksCSV
}) => {
  return (
    <div className='flex gap-5'>
      {/* <button
        disabled={selectedRows.length <= 1 || isArchivePending}
        className='border border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed'
        onClick={() => archiveData(selectedRows)}
      >
        {isArchivePending ? <Spin size='small' /> : 'Archive'}
      </button> */}
      <button
        disabled={isSyncPending}
        className='border border-orange px-2 py-1 bg-orange text-white rounded-lg w-38 disabled:opacity-50 disabled:cursor-not-allowed'
        onClick={syncVirtualFollowupsForms}
      >
        {isSyncPending ? (
          <Spin size='small' />
        ) : (
          <>
            <SyncOutlined className='mr-1' />
            Sync
          </>
        )}
      </button>
      <button
        disabled={downloadingData}
        className={`border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 ${downloadingData && 'opacity-50'}`}
        onClick={downloadTasksCSV}
      >
        {downloadingData ? 'Downloading...' : 'Download'}
      </button>
    </div>
  );
};

export default HeaderActionButtons;
