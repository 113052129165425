import { getDoseValueOptions } from 'utils/helpers/medicationDoseOptions';

interface DoseOption {
  value: string;
  label: string;
}

export const getAdjustDoseOptions = (
  medication: string,
  currentDose: number | null
): DoseOption[] => {
  let doseOptions = getDoseValueOptions(medication);
  doseOptions = doseOptions.sort((a, b) => Number(a.value) - Number(b.value));

  if (currentDose === null) {
    currentDose = Number(doseOptions[0].value);
  }

  const currentIndex =
    doseOptions.findIndex(
      (option) => Number(option.value) === Number(currentDose)
    ) < 0
      ? 0
      : doseOptions.findIndex(
          (option) => Number(option.value) === Number(currentDose)
        );
  let result: DoseOption[] = [];

  if (currentIndex < doseOptions.length - 1) {
    result.push({
      value: doseOptions[currentIndex + 1].value,
      label: `Increase to ${doseOptions?.[currentIndex + 1]?.value} mg`
    });
  }

  result.push({
    value: doseOptions?.[currentIndex]?.value,
    label: `Continue with ${doseOptions?.[currentIndex]?.value} mg`
  });

  if (currentIndex > 0) {
    result.push({
      value: doseOptions[currentIndex - 1].value,
      label: `Decrease to ${doseOptions?.[currentIndex - 1]?.value} mg`
    });
  }
  return result;
};
