import { useState } from 'react';
import { Popconfirm as AntdPopconfirm, PopconfirmProps } from 'antd';

interface PopupConfirmProps extends PopconfirmProps {
  onConfirm: () => void;
  onCancel?: () => void;
  confirmLoadingText?: string;
}

const PopupConfirm: React.FC<PopupConfirmProps> = ({
  title = 'Are you sure?',
  description,
  onConfirm,
  onCancel,
  confirmLoadingText = 'Processing...',
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    onConfirm();
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    });
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
  };

  return (
    <AntdPopconfirm
      title={title}
      description={description}
      open={open}
      onConfirm={handleOk}
      okButtonProps={{
        loading: confirmLoading
      }}
      onCancel={handleCancel}
      {...props}
    >
      <div onClick={showPopconfirm}>{children}</div>
    </AntdPopconfirm>
  );
};

export default PopupConfirm;
