import paths from 'constants/path';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';

interface ReportPageProps {
  children: ReactNode;
  name: string;
}

const ReportPage: React.FC<ReportPageProps> = ({ name, children }) => {
  const tabs = [
    {
      name: 'ic_reports',
      label: 'ICs',
      path: paths.icReports
    },
    {
      name: 'followUp_reports',
      label: 'Sessions',
      path: paths.followUpReports
    }
  ];
  const activeTabClasses = 'border-orange bg-light-orange text-orange';
  const nonActiveTabClasses = 'border-grey text-grey';
  return (
    <div className='flex flex-row bg-[#FAFBFC] py-5 pr-5'>
      <div className='flex flex-col py-3 pl-5 text-nowrap w-[14%]'>
        {tabs.map((tab) => (
          <Link to={tab.path}>
            <button
              className={`mb-3 cursor-pointer rounded-xl border w-[6rem] ${name === tab.name ? activeTabClasses : nonActiveTabClasses}  px-3 py-1 text-sm font-medium  shadow-inner`}
              type='button'
            >
              {tab.label}
            </button>
          </Link>
        ))}
      </div>
      <span className='flex-grow w-[90%]'>{children}</span>
    </div>
  );
};

export default ReportPage;
