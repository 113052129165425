import paths from 'constants/path';
import { Order } from 'types/order';
import { FaExternalLinkAlt, FaSort } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import OrderTimeline from './OrderTimeline';
import formatDate from 'utils/helpers/date';
import { joinName } from 'utils/helpers/name';
import Drawer from 'components/drawer/Drawer';
import { RiTimelineView } from 'react-icons/ri';
import Tooltip from 'components/tooltip/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowRightOutlined, EyeOutlined } from '@ant-design/icons';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';
import AmountMatchIndicator from './AmountMatchIndicator';
import PharmacyPayload from './PharmacyPayload';

export const Columns = (
  setSortingDetails: any,
  sortingDetails: any,
  updateHelper: any,
  processingOrderId: string,
  setProcessingOrderId: any,
  sendToPharmacy: any,
  sendingToPharmacy: boolean,
  viewPhramacyPayload: any,
  isDeleted: any
): ColumnDef<Order>[] => {
  const allColumns: ColumnDef<Order>[] = [
    {
      header: '',
      accessorKey: 'amounts_match',
      cell: (info) => (
        <AmountMatchIndicator
          orderSource={info.row.original.order_source}
          paidAmount={info.row.original.paid_invoice_amount}
          isMatched={info.getValue()}
        />
      )
    },
    {
      header: 'Name',
      accessorKey: 'first_name',
      cell: (info) => (
        <a
          href={info.row.original.practice_better_profile}
          rel='noopener noreferrer'
          target='_blank'
          className='flex items-center gap-2'
        >
          <p className='underline text-orange underline-offset-4'>
            {joinName(info.getValue<string>(), info.row.original.last_name)}
          </p>
        </a>
      )
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (info) => (
        <Link
          to={paths.customer(
            info.row.original.practice_better_profile.split('clients/')[1]
          )}
          onClick={() => {
            updateHelper({ customerSourceRoute: paths.orders });
          }}
        >
          <p className='text-orange underline truncate max-w-32'>
            <Tooltip title={info.getValue<string>()}>
              {info.getValue<string>()}
            </Tooltip>
          </p>
        </Link>
      )
    },
    {
      header: 'Medication',
      accessorKey: 'medication',
      cell: (info) =>
        info.getValue() && (
          <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center'>
            {info.getValue() as string}
          </p>
        )
    },
    // {
    //   header: 'Initial Dose (mg/week)',
    //   accessorKey: 'initial_dose'
    // },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Order Date</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'order_date',
                sort_order:
                  sortingDetails?.sort_by === 'order_date' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'order_date',
      cell: (info) => formatDate(info.getValue() as string)
    },
    { header: 'Pharmacy', accessorKey: 'pharmacy' },
    { header: 'Quantity', accessorKey: 'quantity' },
    { header: 'Plan Duration', accessorKey: 'plan_duration' },
    {
      header: 'Send to Pharmacy',
      accessorKey: 'pharmacy_order_id',
      cell: (info) => (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
        >
          {info.getValue() ? 'Yes' : 'No'}
        </p>
      )
    },
    { header: 'Pharmacy Order Id', accessorKey: 'pharmacy_order_id' },
    {
      header: 'Tracking No.',
      accessorKey: 'tracking_number',
      cell: (info) =>
        info.cell.row.original.tracking_number ? (
          <Link
            to={
              info.cell.row.original.shipping_carrier === 'UPS'
                ? `https://www.ups.com/track?loc=en_US&tracknum=${info.getValue()}`
                : `https://www.fedex.com/wtrk/track/?tracknumbers=${info.getValue()}`
            }
            className='text-orange underline underline-offset-4 cursor-pointer'
            target='_blank'
          >
            <Tooltip
              title={
                info.cell.row.original.shipping_carrier === 'UPS'
                  ? 'Click to open on UPS'
                  : 'Click to open on FedEx'
              }
            >
              {info.getValue<string>()}
            </Tooltip>
          </Link>
        ) : (
          <p className='text-light-grey'>In Process</p>
        )
    },
    { header: 'Shipping Carrier', accessorKey: 'shipping_carrier' },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Order Status</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'ups_status',
                sort_order:
                  sortingDetails?.sort_by === 'ups_status' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'ups_status',
      cell: (info) =>
        info.getValue() && (
          <p
            className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
          >
            {info.getValue() as string}
          </p>
        )
    },
    { header: 'Order Source', accessorKey: 'order_source' },
    // { header: 'Units', accessorKey: 'units' },
    // {
    //   header: 'Vial Size (mg)',
    //   accessorKey: 'vial_size',
    //   cell: (info) => `${info.getValue() as string}`
    // },
    // {
    //   header: 'Type',
    //   accessorKey: 'parent_order_id',
    //   cell: (info) => (info.getValue() ? 'Child' : 'Parent')
    // },
    {
      header: 'Pharmacy sheet addition',
      accessorKey: 'added_to_sheet',
      cell: (info) => (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
        >
          {info.getValue() ? 'Yes' : 'No'}
        </p>
      )
    },
    {
      header: 'Order Note',
      accessorKey: 'order_note_id',
      cell: (info) => {
        const { client_record_id, order_note_id } = info.row.original || {};
        const noteStatus = order_note_id ? 'Yes' : 'No';
        const cssClasses = `border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(noteStatus)}`;
        return order_note_id ? (
          <Tooltip title='View order note on PB.'>
            <Link
              to={`https://my.practicebetter.io/#/p/clients/${client_record_id}/notes/${order_note_id}/edit`}
              target='_blank'
              className={`${cssClasses} flex flex-row gap-[2px]`}
            >
              <span>{noteStatus}</span>
              <FaExternalLinkAlt className='text-base' />
            </Link>
          </Tooltip>
        ) : (
          <p className={cssClasses}>{noteStatus}</p>
        );
      }
    },
    {
      header: 'Amount Status',
      accessorKey: 'amounts_match',
      cell: (info) => (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[7rem] ${getStatusColorClasses(info?.getValue() ? 'Yes' : info.getValue() === false ? 'No' : 'N/A')}`}
        >
          {info?.getValue()
            ? 'Matched'
            : info.getValue() === false
              ? 'Not Matched'
              : 'N/A'}
        </p>
      )
    },
    {
      header: 'Invoice Amount ($)',
      accessorKey: 'paid_invoice_amount'
    },
    {
      header: 'Created By',
      accessorKey: 'created_by',
      cell: (info) =>
        info.getValue() ?? (
          <p className='text-light-grey'>
            {info.row.original.order_source === 'System Generated - Manual'
              ? 'Untracked'
              : 'Automated'}
          </p>
        )
    },
    // {
    //   header: 'Order Created Email Sent',
    //   accessorKey: 'order_created_email_sent',
    //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
    // },
    // {
    //   header: 'Tracking Sms Sent',
    //   accessorKey: 'sms_sent',
    //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
    // },
    // {
    //   header: 'Order Tracking Email Sent',
    //   accessorKey: 'order_tracking_email_sent',
    //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
    // },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Created At</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'created_at',
                sort_order:
                  sortingDetails?.sort_by === 'created_at' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'created_at',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Updated At</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'updated_at',
                sort_order:
                  sortingDetails?.sort_by === 'updated_at' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'updated_at',
      cell: (info) => formatDate(info.getValue() as string)
    },
    { header: 'Is Deleted', accessorKey: 'is_deleted' },
    { header: 'Deleted By', accessorKey: 'order_deleted_by_user' },
    { header: 'Deleted At', accessorKey: 'deleted_at' },
    {
      header: 'Action',
      accessorKey: '',
      cell: (info) => (
        <div className='flex align-items gap-3'>
          {/* <Drawer
            styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
            width='50%'
            button={
              <>
                <EditOutlined className='mr-1' />
                Edit
              </>
            }
            title='Edit Order Details'
            closeIcon={<ArrowRightOutlined />}
          >
            <OrderForm info={info.row.original} />
          </Drawer> */}
          {viewPhramacyPayload === 'true' && (
            <Drawer
              styles={{
                header: { display: 'flex', flexDirection: 'row-reverse' }
              }}
              width='50%'
              button={<EyeOutlined className='mr-1' />}
              title='Pharmacy Payload'
              closeIcon={<ArrowRightOutlined />}
            >
              <PharmacyPayload info={info.row.original} setIsClose={''} />
            </Drawer>
          )}
          <Link
            to={paths.customer(
              info.row.original.practice_better_profile.split('clients/')[1]
            )}
            className='text-orange underline-offset-4'
          >
            View
          </Link>
          {info.row.original?.tracking_number && (
            <Drawer
              styles={{
                header: { display: 'flex', flexDirection: 'row-reverse' }
              }}
              width='50%'
              button={
                <Tooltip title='Timeline'>
                  <RiTimelineView className='cursor-pointer text-base' />
                </Tooltip>
              }
              title='Order Timeline'
              closeIcon={<ArrowRightOutlined />}
            >
              <OrderTimeline orderId={info.row.original.id} />
            </Drawer>
          )}
          {['Vios', 'Hallandale', 'Boothwyn'].includes(
            info?.row?.original?.pharmacy
          ) && (
            <button
              disabled={Boolean(
                info?.row?.original?.pharmacy_order_id ||
                  sendingToPharmacy ||
                  !info?.row?.original?.send_to_pharmacy_button
              )}
              className='text-orange underline-offset-4 cursor-pointer text-sm text-nowrap disabled:opacity-50'
              onClick={() => {
                setProcessingOrderId(info.row.original.id);
                sendToPharmacy(
                  info.row.original.id,
                  info.row.original.pharmacy
                );
              }}
            >
              {info?.row?.original?.id === processingOrderId &&
              sendingToPharmacy
                ? 'Sending...'
                : info?.row?.original?.pharmacy_order_id ||
                    !info?.row?.original?.send_to_pharmacy_button
                  ? 'Sent to Pharmacy'
                  : 'Send to Pharmacy'}
            </button>
          )}
        </div>
      )
    }
  ];
  return isDeleted === 'true' || isDeleted === 'false'
    ? allColumns
    : allColumns.filter(
        (column) =>
          column.header !== 'Is Deleted' &&
          column.header !== 'Deleted By' &&
          column.header !== 'Deleted At'
      );
};
