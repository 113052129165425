export const medicationOptions = [
  { value: 'Semaglutide', label: 'Semaglutide' },
  { value: 'Tirzepatide', label: 'Tirzepatide' },
  { value: 'Wegovy', label: 'Wegovy' },
  { value: 'Ozempic', label: 'Ozempic' },
  { value: 'Mounjaro', label: 'Mounjaro' },
  { value: 'Zepbound', label: 'Zepbound' },
  { value: 'Glutathione', label: 'Glutathione' },
  { value: 'Sermorelin', label: 'Sermorelin' },
  { value: 'NAD+ Nasal Spray', label: 'NAD+ Nasal Spray' },
  { value: 'NAD+', label: 'NAD+' }
];
