import { Spin } from 'antd';
import { getTasks } from '../api';
import React, { useState } from 'react';
import { useCreateBulkSessions } from '../api/useApi';
import { downloadXLSX, transformCSVData } from 'utils/helpers/csvDownloader';
import { useTasksSlice } from 'store/components/stateHooks/useTasksSlice';

interface HeaderActionButtonsProps {
  selectedRows: any[];
  isStatusUpdating: boolean;
  updateStatus: (rows: any[]) => void;
  currentPage: any;
  handleSuccess: () => void;
}

const HeaderActionButtons: React.FC<HeaderActionButtonsProps> = ({
  selectedRows,
  isStatusUpdating,
  updateStatus,
  currentPage,
  handleSuccess
}) => {
  const [downloadingData, setDownloadingData] = useState(false);
  const {
    tasksSlice: { filters }
  } = useTasksSlice();

  const downloadTasksCSV = async () => {
    setDownloadingData(true);
    let response = await getTasks(currentPage, filters, true);
    if (response) {
      downloadXLSX(transformCSVData(response), 'tasks');
      setDownloadingData(false);
    }
  };

  const {
    mutateAsync: createBulkSessions,
    isPending: isBulkSessionCreationPending
  } = useCreateBulkSessions(selectedRows, { onSuccess: handleSuccess });

  return (
    <div className='flex flex-row gap-2'>
      <button
        disabled={!(selectedRows.length > 1) || isStatusUpdating}
        className='border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-34 disabled:opacity-50 disabled:cursor-not-allowed'
        onClick={() => updateStatus([...selectedRows])}
      >
        {isStatusUpdating ? 'Completing...' : 'Mark Complete'}
      </button>
      <button
        className='border border-1 border-orange py-1 bg-orange text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed w-36'
        disabled={!(selectedRows.length > 1) || isBulkSessionCreationPending}
        onClick={() => createBulkSessions()}
      >
        {isBulkSessionCreationPending ? <Spin /> : 'Create Sessions'}
      </button>
      <button
        disabled={downloadingData}
        className={`border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 ${downloadingData && 'opacity-50'}`}
        onClick={downloadTasksCSV}
      >
        {downloadingData ? 'Downloading...' : 'Download'}
      </button>
    </div>
  );
};

export default HeaderActionButtons;
