import React from 'react';
import Input from 'components/input/Input';
import Button from 'components/buttons/Button';
import { Label } from 'components/label/Label';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import Select from 'components/select/Select';
import { SmsLogFilters } from 'types/smsLogs';

type FiltersProps = {
  setFilters: (data: SmsLogFilters) => void;
  setCurrentPage: (page: number) => void;
  filters: Partial<SmsLogFilters>;
};

export default function Filters({
  setFilters,
  setCurrentPage,
  filters
}: FiltersProps) {
  const { control, handleSubmit, reset } = useForm<SmsLogFilters>({
    defaultValues: {
      ...filters
    }
  });

  const onSubmit: SubmitHandler<SmsLogFilters> = (data) => {
    setFilters(data);
    setCurrentPage(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center my-4'>
        <div className='w-40'>
          <Label className='text-sm !font-normal m-1'>To</Label>
          <Controller
            name='to_number'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Status</Label>
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[160px] h-[2rem]'
                options={[
                  { label: 'Failed', value: 'Failed' },
                  { label: 'Sent', value: 'Sent' }
                ]}
              />
            )}
          />
        </div>
        <Button type='submit' className='w-max px-3.5 h-[38px] mt-6'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() =>
            reset({
              to_number: '',
              status: ''
            })
          }
          className='border border-orange rounded py-2 px-3 mt-6'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
