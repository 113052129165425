import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request } from 'utils/api';
import DataTable from 'components/dataTable/DataTable';
import { ColumnDef } from '@tanstack/react-table';
import { Order } from 'types/order';
import Pagination from 'components/pagination/Pagination';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EyeOutlined
} from '@ant-design/icons';
import formatDate from 'utils/helpers/date';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/buttons/Button';
import OrderForm from './OrderForm';
import OrderTimeline from 'pages/orders/components/OrderTimeline';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Drawer } from 'antd';
import CustomDrawer from 'components/drawer/Drawer';
import { useProfile } from 'pages/profile/api/useApi';
// import { useSendOrderToPharmacy } from 'pages/orders/apis/useApi';
import { useCustomerData } from '../api/useApi';
import { usePharmacyButton } from 'pages/orders/components/usePharmacyButton';
import AmountMatchIndicator from 'pages/orders/components/AmountMatchIndicator';

const getOrders = async (currentPage: number, id: string | undefined) => {
  const data = await request({
    url: '/client-orders',
    method: 'GET',
    params: { client_id: id, page: currentPage, per_page: 10 }
  });
  return data;
};

export const handleDeleteOrder = async (id: string) => {
  const response = await request({
    url: `/orders/${id}`,
    method: 'DELETE'
  });
  return response;
};

export default function Orders() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingOrderId, setProcessingOrderId] = useState('');
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [addOrderModal, setAddOrderModal] = useState(false);
  const { data: customerData } = useCustomerData(String(id));
  const { data: profileData } = useProfile();
  const { contextHolder, sendToPharmacy, sendingToPharmacy } =
    usePharmacyButton();
  // const {
  //   mutateAsync: sendOrderToPharmacy,
  //   isPending: sendingOrderToPharmacy
  // } = useSendOrderToPharmacy({
  //   onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders'] })
  // });

  const { data: OrdersData, isLoading } = useQuery({
    queryKey: ['orders', id, currentPage],
    queryFn: () => getOrders(currentPage, id)
  });

  const { mutateAsync: deleteOrder } = useMutation({
    mutationFn: (orderId: string) => handleDeleteOrder(orderId),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
      }
    }
  });

  const ordersMemorizedData = useMemo(() => {
    return OrdersData?.orders ?? [];
  }, [OrdersData?.orders]);

  return (
    <>
      <div className='border border-light-grey p-4 rounded-xl'>
        <div className='flex justify-between items-center mb-4'>
          <h4 className='text-xl font-semibold'>Order</h4>
          {profileData?.is_assistant &&
          (OrdersData?.has_recent_order || !customerData?.is_active) ? (
            <PopupConfirm
              title='Warning'
              description={
                OrdersData?.has_recent_order && !customerData?.is_active
                  ? 'This patient is canceled and also ordered in the last 20 days. Proceed?'
                  : OrdersData?.has_recent_order
                    ? 'This customer ordered in the last 20 days. Proceed?'
                    : 'This patient is canceled. Do you want to proceed?'
              }
              onConfirm={() => setAddOrderModal(true)}
            >
              <Button className='!px-3 text-sm !w-fit'>+ Manual Order</Button>
            </PopupConfirm>
          ) : (
            profileData?.is_assistant && (
              <button
                className='!px-3 text-sm !w-fit border border-orange bg-orange text-white py-1 rounded-lg'
                onClick={() => setAddOrderModal(true)}
              >
                + Manual Order
              </button>
            )
          )}
          <Drawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            open={addOrderModal}
            title='Add Manual Order'
            closeIcon={
              <ArrowRightOutlined onClick={() => setAddOrderModal(false)} />
            }
          >
            <OrderForm setAddOrderModal={setAddOrderModal} />
          </Drawer>
        </div>
        <div className='overflow-auto h-[calc(100vh_-_430px)]'>
          <DataTable
            data={ordersMemorizedData}
            columns={columns(
              deleteOrder,
              // sendOrderToPharmacy,
              // sendingOrderToPharmacy,
              processingOrderId,
              setProcessingOrderId,
              sendToPharmacy,
              sendingToPharmacy
            )}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Pagination
        totalCount={OrdersData?.pagination.total_count}
        totalPage={OrdersData?.pagination.total_pages}
        perPage={OrdersData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {contextHolder}
    </>
  );
}

const columns = (
  deleteOrder: any,
  // sendOrderToPharmacy: any,
  // sendingOrderToPharmacy: boolean,
  processingOrderId: string,
  setProcessingOrderId: any,
  sendToPharmacy: any,
  sendingToPharmacy: boolean
): ColumnDef<Order>[] => [
  {
    header: '',
    accessorKey: 'amounts_match',
    cell: (info) => {
      return !info.row.original.parent_order_id ? (
        <AmountMatchIndicator
          orderSource={info.row.original.order_source}
          paidAmount={info.row.original.paid_invoice_amount}
          isMatched={info.getValue()}
        />
      ) : (
        ''
      );
    }
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Order Date',
    accessorKey: 'order_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Medication',
    accessorKey: 'medication',
    cell: (info) =>
      info.cell.row.original.medication ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },

  {
    header: 'Tracking No.',
    accessorKey: 'tracking_number',
    cell: (info) =>
      info.cell.row.original.tracking_number ? (
        <Link
          to={
            info.cell.row.original.shipping_carrier === 'UPS'
              ? `https://www.ups.com/track?loc=en_US&tracknum=${info.getValue()}`
              : `https://www.fedex.com/wtrk/track/?tracknumbers=${info.getValue()}`
          }
          className='text-orange underline underline-offset-4 cursor-pointer'
          target='_blank'
        >
          <Tooltip
            title={
              info.cell.row.original.shipping_carrier === 'UPS'
                ? 'Click to open on UPS'
                : 'Click to open on FedEx'
            }
          >
            {info.getValue<string>()}
          </Tooltip>
        </Link>
      ) : (
        <p className='text-light-grey'>In Process</p>
      )
  },
  { header: 'Shipping Carrier', accessorKey: 'shipping_carrier' },
  {
    header: 'Pharmacy',
    accessorKey: 'pharmacy',
    cell: (info) => info.getValue()
  },
  {
    header: 'Send to Pharmacy',
    accessorKey: 'pharmacy_order_id',
    cell: (info) => (
      <p
        className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
      >
        {info.getValue() ? 'Yes' : 'No'}
      </p>
    )
  },
  {
    header: 'Vial Size (mg/ml)',
    accessorKey: 'vial_size'
  },
  {
    header: 'Dose',
    accessorKey: 'initial_dose'
  },
  {
    header: 'Units',
    accessorKey: 'units',
    cell: (info) => info.getValue()
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity'
  },
  {
    header: 'Plan Duration',
    accessorKey: 'plan_duration'
  },
  // {
  //   header: 'Order Created Email Sent',
  //   accessorKey: 'order_created_email_sent',
  //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  // },
  // {
  //   header: 'Tracking Sms Sent',
  //   accessorKey: 'sms_sent',
  //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  // },
  // {
  //   header: 'Order Tracking Email Sent',
  //   accessorKey: 'order_tracking_email_sent',
  //   cell: (info) => ((info.getValue() as string) ? 'Yes' : 'No')
  // },
  {
    header: 'Parent Order',
    accessorKey: 'parent_order_id',
    cell: (info) => (
      <p
        className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'No' : 'Yes')}`}
      >
        {info.getValue() ? 'No' : 'Yes'}
      </p>
    )
  },
  { header: 'Order Source', accessorKey: 'order_source' },
  {
    header: 'Created By',
    accessorKey: 'created_by',
    cell: (info) =>
      info.getValue() ?? (
        <p className='text-light-grey'>
          {info.row.original.parent_order_id
            ? 'Child Order'
            : info.row.original.order_source === 'System Generated - Manual'
              ? 'Untracked'
              : 'Automated'}
        </p>
      )
  },
  {
    header: 'Pharmacy sheet addition',
    accessorKey: 'added_to_sheet',
    cell: (info) => (
      <p
        className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
      >
        {info.getValue() ? 'Yes' : 'No'}
      </p>
    )
  },
  { header: 'Pharmacy Order Id', accessorKey: 'pharmacy_order_id' },
  {
    header: 'Order Note',
    accessorKey: 'order_note_id',
    cell: (info) => {
      const { client_record_id, order_note_id } = info.row.original || {};
      const noteStatus = order_note_id ? 'Yes' : 'No';
      const cssClasses = `border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(noteStatus)}`;
      return order_note_id ? (
        <Tooltip title='View order note on PB.'>
          <Link
            to={`https://my.practicebetter.io/#/p/clients/${client_record_id}/notes/${order_note_id}/edit`}
            target='_blank'
            className={`${cssClasses} flex flex-row gap-[2px]`}
          >
            <span>{noteStatus}</span>
            <FaExternalLinkAlt className='text-base' />
          </Link>
        </Tooltip>
      ) : (
        <p className={cssClasses}>{noteStatus}</p>
      );
    }
  },
  {
    header: 'Status',
    accessorKey: 'ups_status',
    cell: (info) =>
      info.getValue() && (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
        >
          {info.getValue() as string}
        </p>
      )
  },
  {
    header: 'Amount Status',
    accessorKey: 'amounts_match',
    cell: (info) => (
      <p
        className={`border rounded-full py-1 px-2 text-xs text-center w-[7rem] ${getStatusColorClasses(info?.getValue() ? 'Yes' : info.getValue() === false ? 'No' : 'N/A')}`}
      >
        {info?.getValue()
          ? 'Matched'
          : info.getValue() === false
            ? 'Not Matched'
            : 'N/A'}
      </p>
    )
  },
  {
    header: 'Invoice Amount ($)',
    accessorKey: 'paid_invoice_amount'
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        {!info.row.original?.parent_order_id && (
          <PopupConfirm
            title='Delete Order'
            description='Are you sure you want to delete order? (Note: You need to delete it from sheet manually)'
            onConfirm={() => deleteOrder(info.row.original?.id)}
          >
            <button className='text-red'>
              <DeleteOutlined />
            </button>
          </PopupConfirm>
        )}
        {/* <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Order Details'
          closeIcon={<ArrowRightOutlined />}
        >
          <OrderForm info={info.row.original} />
        </Drawer> */}
        {info.row.original?.tracking_number && (
          <CustomDrawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            button={
              <Tooltip title='Timeline'>
                <EyeOutlined className='mr-1 cursor-pointer' />
              </Tooltip>
            }
            title='Order Timeline'
            closeIcon={<ArrowRightOutlined />}
          >
            <OrderTimeline orderId={info.row.original.id} />
          </CustomDrawer>
        )}
        {!info?.row?.original.parent_order_id &&
          ['Vios', 'Hallandale', 'Boothwyn'].includes(
            info?.row?.original?.pharmacy
          ) && (
            <button
              disabled={Boolean(
                info?.row?.original?.pharmacy_order_id ||
                  sendingToPharmacy ||
                  !info?.row?.original?.send_to_pharmacy_button
              )}
              className='text-orange underline-offset-4 cursor-pointer text-sm text-nowrap disabled:opacity-50'
              onClick={() => {
                setProcessingOrderId(info?.row?.original.id);
                sendToPharmacy(
                  info?.row?.original.id,
                  info?.row?.original.pharmacy
                );
              }}
            >
              {info?.row?.original?.id === processingOrderId &&
              sendingToPharmacy
                ? 'Sending...'
                : info?.row?.original?.pharmacy_order_id ||
                    !info?.row?.original?.send_to_pharmacy_button
                  ? 'Sent to Pharmacy'
                  : 'Send to Pharmacy'}
            </button>
          )}
      </div>
    )
  }
];
