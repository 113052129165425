import { Address } from 'types/customer';

export const AddressFormatter = (address?: Address | any) => {
  if (!address?.address) return <p>Address not available</p>;
  const { unit, street, locality, region, postalCode, country } =
    address?.address;
  return (
    <div className='ml-1'>
      <span className='text-orange text-sm'>
        Street: <span className='text-grey'>{street}</span>
      </span>
      <br />
      <span className='text-orange text-sm'>
        Unit: <span className='text-grey'>{unit}</span>
      </span>
      <br />
      <span className='text-orange text-sm'>
        Locality: <span className='text-grey'>{locality}</span>
      </span>
      <span className='text-orange text-sm ml-3'>
        Region: <span className='text-grey'>{region}</span>
      </span>
      <br />
      <span className='text-orange text-sm'>
        Country: <span className='text-grey'>{country}</span>
      </span>
      <span className='text-orange text-sm ml-3'>
        Postal Code: <span className='text-grey'>{postalCode}</span>
      </span>
    </div>
  );
};
