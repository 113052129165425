import React, { useMemo, useState } from 'react';
import { ConfigProvider, Select as SelectComponent, SelectProps } from 'antd';

interface CustomSelectProps extends SelectProps {
  data?: Array<{ [key: string]: any }>;
  labelKey?: string;
  valueKey?: string;
  showSearch?: boolean;
}
export default function Select({
  data,
  labelKey = 'label',
  valueKey = 'value',
  options = [],
  placeholder,
  showSearch = false,
  ...props
}: CustomSelectProps) {
  const [searchValue, setSearchValue] = useState('');

  const baseOptions = useMemo(() => {
    return (
      (data ?? options)?.map((item) => ({
        label: item[labelKey],
        value: item[valueKey]
      })) ?? []
    );
  }, [data, labelKey, valueKey, options]);

  const filteredOptions = useMemo(() => {
    if (showSearch && searchValue) {
      return baseOptions.filter(
        (option) =>
          option.label &&
          option.label
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      );
    }
    return baseOptions;
  }, [baseOptions, searchValue, showSearch]);

  // Handle value selection and reset search state
  const handleSelect = (value: any, option: any) => {
    setSearchValue(''); // Reset search field when an option is selected
    props.onSelect?.(value, option); // Call the original onSelect if provided
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'rgb(252 103 32)'
        }
      }}
    >
      <SelectComponent
        placeholder={placeholder}
        options={filteredOptions}
        showSearch={showSearch}
        onSearch={showSearch ? setSearchValue : undefined}
        filterOption={false}
        onSelect={handleSelect} // Reset search on selection
        {...props}
      />
    </ConfigProvider>
  );
}
