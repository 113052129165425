import { InfoCircleOutlined } from '@ant-design/icons';
import {
  statesForHallandale,
  statesForRedRock,
  statesForVios
} from 'constants/pharmacyWiseStates';

export default function ICPackageDetails({
  getSelectedNames,
  clientState,
  currentPackage,
  isStarterPack,
  isPackageAvailable,
  formId
}: {
  getSelectedNames: any;
  clientState: string;
  currentPackage: {
    pb_package_name: string;
    invoice_amount: number;
    discount_tag: string;
    payment_plan_amount: number;
    payment_plan_duration: string;
    state_invoice_amount: number;
    state_payment_plan_amount: number;
    invoice_amount_starter: number;
    invoice_amount_starter_redrock: number;
    invoice_amount_starter_vios: number;
    invoice_amount_redrock: number;
    invoice_amount_vios: number;
    invoice_amount_boothwyn: number;
    invoice_amount_starter_boothwyn: number;
  };
  isStarterPack: boolean;
  isPackageAvailable: boolean;
  formId?: string;
}) {
  const fieldsLeft = [
    {
      label: 'Invoice Amount',
      value: currentPackage.invoice_amount
        ? `$${currentPackage.invoice_amount}`
        : 'N/A'
    },
    {
      label: 'Invoice Amount(Red Rock)',
      value: currentPackage.invoice_amount_redrock
        ? `$${currentPackage.invoice_amount_redrock}`
        : 'N/A'
    },
    {
      label: 'Invoice Amount(Boothwyn)',
      value: currentPackage.invoice_amount_boothwyn
        ? `$${currentPackage.invoice_amount_boothwyn}`
        : 'N/A'
    },
    {
      label: 'Invoice Amount(Vios)',
      value: currentPackage.invoice_amount_vios
        ? `$${currentPackage.invoice_amount_vios}`
        : 'N/A'
    },
    { label: 'Discount Tag', value: currentPackage.discount_tag },
    {
      label: 'Payment Plan Amount',
      value: currentPackage.payment_plan_amount
        ? `$${currentPackage.payment_plan_amount}`
        : 'N/A'
    }
  ];
  const fieldsRight = [
    {
      label: 'Starter Package',
      value: isStarterPack ? 'Yes' : 'No'
    },
    {
      label: 'Starter Invoice Amount',
      value: currentPackage.invoice_amount_starter
        ? `$${currentPackage.invoice_amount_starter}`
        : 'N/A'
    },
    {
      label: 'Starter Invoice Amount (Redrock)',
      value: currentPackage.invoice_amount_starter_redrock
        ? `$${currentPackage.invoice_amount_starter_redrock}`
        : 'N/A'
    },
    {
      label: 'Starter Invoice Amount (Boothwyn)',
      value: currentPackage.invoice_amount_starter_boothwyn
        ? `$${currentPackage.invoice_amount_starter_boothwyn}`
        : 'N/A'
    },
    {
      label: 'Starter Invoice Amount (Vios)',
      value: currentPackage.invoice_amount_starter_vios
        ? `$${currentPackage.invoice_amount_starter_vios}`
        : 'N/A'
    },
    {
      label: 'Payment Plan Duration',
      value: currentPackage.payment_plan_duration
    }
  ];

  // to display pharmacy specific  fields only
  const CommonFields = [
    'Payment Plan Amount',
    'Discount Tag',
    'Starter Package',
    'Payment Plan Duration'
  ];
  const RedRockFields = [
    'Invoice Amount(Red Rock)',
    'Starter Invoice Amount (Redrock)'
  ];
  const BoothwynFields = [
    'Invoice Amount(Boothwyn)',
    'Starter Invoice Amount (Boothwyn)'
  ];
  const ViosFields = ['Invoice Amount(Vios)', 'Starter Invoice Amount (Vios)'];
  const HallandaleFields = ['Invoice Amount', 'Starter Invoice Amount'];
  function getFieldsForState(state: string): string[] {
    if (
      ['67d138817568c354bc68be83', '67d139407568c354bc68c1b0'].includes(
        String(formId)
      )
    ) {
      return [...CommonFields, ...BoothwynFields];
    } else if (
      ['67d135d17568c354bc68b70d', '67d4844488106d5b16fdb991'].includes(
        String(formId)
      )
    ) {
      return [...CommonFields, ...HallandaleFields];
    } else if (statesForHallandale.includes(state)) {
      return [...CommonFields, ...HallandaleFields];
    } else if (statesForVios.includes(state)) {
      return [...CommonFields, ...ViosFields];
    } else if (statesForRedRock.includes(state)) {
      return [...CommonFields, ...RedRockFields];
    } else {
      return [...CommonFields, ...HallandaleFields];
    }
  }

  return (
    <>
      <div
        className={`border border-1 rounded p-2 my-3 mx-1 bg-light-orange ${!isPackageAvailable && '!border-orange shadow-red shadow-sm'}`}
      >
        <div className='font-medium border-b m-2 pb-1 text-xs'>
          Package ({getSelectedNames(currentPackage.pb_package_name)})
        </div>
        <div className='flex flex-row text-xs'>
          {[fieldsLeft, fieldsRight].map((fields, index) => (
            <div
              key={index}
              className={`flex flex-col w-1/2 ${index === 0 ? 'border-r mr-3' : ''}`}
            >
              {fields.map(
                (field, i) =>
                  getFieldsForState(clientState).includes(field.label) && (
                    <span key={i} className='flex flex-row'>
                      <span className='font-medium'>{field.label} :</span>
                      <span className='ml-1'>{field.value}</span>
                    </span>
                  )
              )}
            </div>
          ))}
        </div>
      </div>
      {!isPackageAvailable && (
        <div className='flex flex-row text-red mx-6 mt-[-5px]'>
          <InfoCircleOutlined className='mb-[20px] mr-1' />
          <span>
            The customer is from {clientState} and has filled out the incorrect
            form. This plan is not available in {clientState}.
          </span>
        </div>
      )}
    </>
  );
}
