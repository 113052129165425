import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import {
  getDocuments,
  handleAddBlacklisted,
  handleDeleteBlacklisted,
  handleUpdateBlacklisted
} from '.';
import {
  BlacklistedFilterInput,
  BlacklistedFormInputs,
  BlacklistedSortingDetails
} from 'types/blacklisted';

export const useDocuments = (
  currentPage: number,
  documentsFilters: BlacklistedFilterInput,
  sortingDetails: BlacklistedSortingDetails,
  clientId?: string
) => {
  return useQuery({
    queryKey: [
      'blacklisted',
      currentPage,
      documentsFilters,
      sortingDetails,
      clientId
    ],
    queryFn: () => getDocuments(currentPage, documentsFilters, sortingDetails)
  });
};

export const useUpdateBlacklisted = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, BlacklistedFormInputs, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: BlacklistedFormInputs) =>
      handleUpdateBlacklisted(data.id, data),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['blacklisted'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });
  return mutation;
};

export const useCreateBlacklisted = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, BlacklistedFormInputs, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: BlacklistedFormInputs) => handleAddBlacklisted(data),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['blacklisted'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });
  return mutation;
};

export const useDeleteBlacklisted = (options?: {
  onSuccess?: () => void;
}): UseMutationResult<any, unknown, string, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => handleDeleteBlacklisted(id),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['blacklisted'] });
        options?.onSuccess && options.onSuccess();
      }
    }
  });
  return mutation;
};
