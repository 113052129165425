import { Modal } from 'antd';
import Filters from './components/Filters';
import { useParams } from 'react-router-dom';
import Drawer from 'components/drawer/Drawer';
import { columns } from './components/Columns';
import React, { useMemo, useState } from 'react';
import DataTable from 'components/dataTable/DataTable';
import SendEmailForm from './components/SendEmailForm';
import Pagination from 'components/pagination/Pagination';
import { RowSelectionState } from '@tanstack/react-table';
import { useHelper } from 'store/components/stateHooks/useHelper';
import HeaderActionButtons from './components/HeaderActionButtons';
import { useArchiveData, useInitialConsultations } from './api/useApi';
import InitialConsultationsForm from './components/initialConsultationForm/InitialConsultationsForm';
import { useInitialConsultationsSlice } from 'store/components/stateHooks/useInitialConsultationsSlice';
import paths from 'constants/path';

export default function InitialConsultations({
  isSingle,
  clientId
}: {
  isSingle?: boolean;
  clientId?: string;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingModalId, setProcessingModalId] = useState('');
  const { id, form_id } = useParams();
  const { updateHelper } = useHelper();
  const {
    initialConsultationsSlice: { filters }
  } = useInitialConsultationsSlice();
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const handleSuccess = () => {
    setRowSelection({});
  };

  const { mutateAsync: archiveData, isPending: isArchivePending } =
    useArchiveData({ onSuccess: handleSuccess });
  const { data: InitialConsultationsData, isLoading } = useInitialConsultations(
    currentPage,
    filters,
    sortingDetails,
    clientId
  );
  const initialConsultationsMemorizedData = useMemo(() => {
    return InitialConsultationsData?.form_requests ?? [];
  }, [InitialConsultationsData?.form_requests]);

  return (
    <div className={`${clientId ? 'bg-white' : 'p-5 bg-[#FAFBFC]'}`}>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between'>
          <h1 className={`text-xl font-semibold ${clientId && 'mb-5'}`}>
            Initial Consultations
          </h1>
          {!clientId && (
            <HeaderActionButtons
              selectedRows={selectedRows}
              isArchivePending={isArchivePending}
              archiveData={archiveData}
            />
          )}
        </div>
        {!clientId && <Filters setCurrentPage={setCurrentPage} />}
        <div
          className={`overflow-auto ${clientId ? 'h-[calc(100vh_-_430px)]' : 'h-[calc(100vh_-_310px)]'}`}
        >
          <DataTable
            isLoading={isLoading}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            data={initialConsultationsMemorizedData}
            columns={columns(
              archiveData,
              setSortingDetails,
              sortingDetails,
              setProcessingModalId,
              clientId,
              updateHelper
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={InitialConsultationsData?.pagination.per_page}
        totalPage={InitialConsultationsData?.pagination.total_pages}
        totalCount={InitialConsultationsData?.pagination.total_count}
      />
      <Modal
        open={Boolean(processingModalId)}
        onCancel={() => setProcessingModalId('')}
        footer={[]}
        maskClosable={false}
      >
        <SendEmailForm
          key={processingModalId}
          setProcessingModalId={setProcessingModalId}
          processingModalId={processingModalId}
        />
      </Modal>
      <Drawer
        width='90%'
        button={null}
        closeIcon={null}
        open={Boolean(isSingle)}
        styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
      >
        <InitialConsultationsForm
          isSingle={isSingle}
          formId={clientId ? String(form_id) : String(id)}
          backUrl={
            clientId
              ? `/customers/${clientId}/forms`
              : paths.initialConsultations
          }
        />
      </Drawer>
    </div>
  );
}
