import { useQuery } from '@tanstack/react-query';
import { getDocuments } from '.';
import { DocumentsFilterInput, DocumentsSortingDetails } from 'types/documents';

export const useDocuments = (
  currentPage: number,
  documentsFilters: DocumentsFilterInput,
  sortingDetails: DocumentsSortingDetails,
  clientId?: string
) => {
  return useQuery({
    queryKey: [
      'documents',
      currentPage,
      documentsFilters,
      sortingDetails,
      clientId
    ],
    queryFn: () =>
      getDocuments(currentPage, documentsFilters, sortingDetails, clientId)
  });
};
