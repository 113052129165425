import { EmailLogFilters, EmailLogSortingDetails } from 'types/emailLogs';
import { getEmailLogs } from '.';
import { useQuery } from '@tanstack/react-query';

export const useEmailLogs = (
  currentPage: number,
  emailLogFilters: EmailLogFilters,
  sortingDetails: EmailLogSortingDetails,
  clientId?: string
) => {
  return useQuery({
    queryKey: [
      'email-logs',
      currentPage,
      emailLogFilters,
      sortingDetails,
      clientId
    ],
    queryFn: () =>
      getEmailLogs(currentPage, emailLogFilters, sortingDetails, clientId)
  });
};
