import {
  getCustomer,
  handleCancelServices,
  handleCancelSessions,
  handleRefreshClient,
  handleSendVerificationLink
} from '.';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Customer } from '../../../types/customer';

export const useCustomerData = (id: string) => {
  return useQuery<Customer>({
    queryKey: ['customer', id],
    queryFn: () => getCustomer(id)
  });
};

export const useRefreshClient = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => handleRefreshClient(String(id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer'] });
      queryClient.refetchQueries({ queryKey: ['customer'] });
      options?.onSuccess && options.onSuccess();
    }
  });
};

export const useCancelServices = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => handleCancelServices(String(id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cancel_services'] });
      queryClient.refetchQueries({ queryKey: ['customer'] });
      options?.onSuccess && options.onSuccess();
    }
  });
};

export const useCancelSessions = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => handleCancelSessions(String(id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cancel_sessions'] });
      queryClient.refetchQueries({ queryKey: ['customer'] });
      options?.onSuccess && options.onSuccess();
    }
  });
};

export const useSendVerificationLink = (options?: {
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => handleSendVerificationLink(String(id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer'] });
      queryClient.refetchQueries({ queryKey: ['customer'] });
      options?.onSuccess && options.onSuccess();
    }
  });
};
