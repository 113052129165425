import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOrders } from './apis/useApi';
import Filters from './components/Filters';
import { Columns } from './components/Columns';
import OrdersKPIs from './components/OrdersKPIs';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { useHelper } from 'store/components/stateHooks/useHelper';
import { useOrdersSlice } from 'store/components/stateHooks/useOrdersSlice';
import { getOrders } from './apis';
import {
  downloadXLSX,
  reorderObjectKeys,
  transformCSVData
} from 'utils/helpers/csvDownloader';
import { usePharmacyButton } from './components/usePharmacyButton';

export default function OrdersPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingOrderId, setProcessingOrderId] = useState('');
  const { contextHolder, sendToPharmacy, sendingToPharmacy } =
    usePharmacyButton();
  const { updateHelper } = useHelper();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const viewPhramacyPayload = queryParams.get('order_json');
  const isDeleted = queryParams.get('is_deleted');
  const {
    ordersSlice: { filters }
  } = useOrdersSlice();

  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });
  const [downloadingData, setDownloadingData] = useState(false);

  const downloadTasksCSV = async () => {
    const keyOrder = [
      'first_name',
      'last_name',
      'email',
      'Added to sheet',
      'practice_better_profile',
      'form_request_id',
      'order_note_id',
      'medication',
      'initial_dose',
      'vial_size',
      'goals',
      'order_source',
      'pharmacy',
      'shipping_carrier',
      'tracking_number'
    ];
    setDownloadingData(true);
    let response = await getOrders(currentPage, filters, sortingDetails, true);
    if (response) {
      const reorderedResponse = reorderObjectKeys(response, keyOrder);
      downloadXLSX(
        transformCSVData(reorderedResponse),
        `${filters?.created_at?.[0] ? `orders-${filters?.created_at?.[0]} to ${filters?.created_at?.[1]}` : 'orders'}`
      );
    }
    setDownloadingData(false);
  };

  const { data: orderData, isLoading } = useOrders(
    currentPage,
    filters,
    sortingDetails
  );
  const customerMemorizedData = useMemo(() => {
    return orderData?.orders ?? [];
  }, [orderData?.orders]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex flex-col justify-between gap-4'>
          <div className='flex flex-row justify-between'>
            <h1 className='text-xl font-semibold'>Orders</h1>
            <button
              disabled={downloadingData}
              className={`border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 ${downloadingData && 'opacity-50'}`}
              onClick={downloadTasksCSV}
            >
              {downloadingData ? 'Downloading...' : 'Download'}
            </button>
          </div>
          <OrdersKPIs
            ordersAnalytics={orderData?.orders_analytics}
            dataLoading={isLoading}
          />
        </div>
        <Filters setCurrentPage={setCurrentPage} />
        <div className='overflow-auto h-[calc(100vh_-_380px)]'>
          <DataTable
            isLoading={isLoading}
            data={customerMemorizedData}
            columns={Columns(
              setSortingDetails,
              sortingDetails,
              updateHelper,
              processingOrderId,
              setProcessingOrderId,
              sendToPharmacy,
              sendingToPharmacy,
              viewPhramacyPayload,
              isDeleted
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={orderData?.pagination.total_count}
        totalPage={orderData?.pagination.total_pages}
        perPage={orderData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {contextHolder}
    </div>
  );
}
