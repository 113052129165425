import { DocumentsFilterInput, DocumentsSortingDetails } from 'types/documents';
import { request } from 'utils/api';

export const getDocuments = async (
  currentPage: number,
  documentsFilters: DocumentsFilterInput,
  sortingDetails: DocumentsSortingDetails,
  clientId?: string
) => {
  const data = await request({
    url: '/documents',
    method: 'GET',
    params: {
      page: currentPage,
      client_record_id: clientId ?? null,
      ...documentsFilters,
      ...sortingDetails
    }
  });
  return data;
};
