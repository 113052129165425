import { request } from 'utils/api';

export const getCustomer = async (id: string | undefined) => {
  const data = await request({
    url: `/clients/${id}`,
    method: 'GET'
  });
  return data;
};

export const handleCancelServices = async (id: string) => {
  const data = await request({
    url: `/clients/${id}/cancel_client_services`,
    method: 'PUT'
  });
  return data;
};

export const handleRefreshClient = async (id: string) => {
  const data = await request({
    url: `/clients/${id}/refresh-client-details`,
    method: 'PUT'
  });
  return data;
};

export const handleCancelSessions = async (id: string) => {
  const data = await request({
    url: `/clients/${id}/cancel_client_sessions`,
    method: 'DELETE'
  });
  return data;
};

export const handleSendVerificationLink = async (id: string) => {
  const data = await request({
    url: `/clients/${id}/send_verification_link`,
    method: 'POST',
    data: {}
  });
  return data;
};
