import { StateCreator } from 'zustand';
import { getDefaultConsultantId } from 'utils/helpers/defaultConsultantId';
import { InitialConsultationsSlice } from '../types/initialConsultationsSliceTypes';

const InitialICFilters = {
  first_name: '',
  last_name: '',
  email: '',
  status: 'Pending',
  consultant_id: getDefaultConsultantId(),
  completed: '',
  completed_at: []
};

const initialState = {
  filters: InitialICFilters
};

export const initialConsultationsSlice: StateCreator<
  InitialConsultationsSlice
> = (set) => ({
  initialConsultationsSlice: initialState,
  updateInitialConsultationsSlice: (data: any) => {
    set((state) => ({
      initialConsultationsSlice: {
        ...state.initialConsultationsSlice,
        ...data
      }
    }));
  },
  resetInitialConsultationsSlice: () =>
    set({ initialConsultationsSlice: initialState })
});
