import React from 'react';
import paths from 'constants/path';
import { FaExternalLinkAlt, FaSort } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import formatDate from 'utils/helpers/date';
import { joinName } from 'utils/helpers/name';
import Tooltip from 'components/tooltip/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import { PbSessionsResponse } from 'types/follow-ups';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import {
  EyeOutlined,
  FolderAddOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';
import { getStatusColorClasses } from 'utils/helpers/statusColourClasses';
import { useHelper } from 'store/components/stateHooks/useHelper';

export const Columns = (
  sortingDetails: any,
  setSortingDetails: any,
  archiveData: any,
  customersPageView?: boolean,
  customerId?: string
): ColumnDef<PbSessionsResponse>[] => {
  const { updateHelper } = useHelper();
  const getFormRoute = (info: any) => {
    const formId = info.row.original.ic_form || info.row.original.id;
    const formType = info.row.original.ic_form ? 'ic' : 'vf';
    return customersPageView
      ? paths.customerFollowupForm(String(customerId), String(formId), formType)
      : paths.followup(String(formId), formType);
  };

  const allColumns: ColumnDef<PbSessionsResponse>[] = [
    {
      header: 'Name',
      accessorKey: 'first_name',
      cell: (info) => (
        <a
          href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
          rel='noopener noreferrer'
          target='_blank'
          className='flex items-center gap-2'
        >
          <p className='underline text-orange underline-offset-4'>
            {joinName(info.getValue<string>(), info.row.original.last_name)}
          </p>
        </a>
      )
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (info) => (
        <Link
          to={paths.customer(String(info.row.original.client_record_id))}
          onClick={() => {
            updateHelper({ customerSourceRoute: paths.followups });
          }}
          className='text-orange underline underline-offset-4'
        >
          {info.getValue<string>()}
        </Link>
      )
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Session Date</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'session_date',
                sort_order:
                  sortingDetails?.sort_by === 'session_date' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'session_date',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: 'Current Medication',
      accessorKey: 'followup.medication',
      cell: (info) =>
        info.getValue() ? (
          <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
            {capitalizeFirstLetter(info.getValue() as string)}
          </p>
        ) : (
          '---'
        )
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Form Completed</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'completed',
                sort_order:
                  sortingDetails?.sort_by === 'completed' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'followup.completed',
      cell: (info) =>
        info.row.original.followup && (
          <p
            className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
          >
            {info.getValue() ? 'Yes' : 'No'}
          </p>
        )
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Draft Date</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'date_created',
                sort_order:
                  sortingDetails?.sort_by === 'date_created' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'date_created',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Status</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'status',
                sort_order:
                  sortingDetails?.sort_by === 'status' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'followup.status',
      cell: (info) =>
        info.getValue() && (
          <p
            className={`border rounded-full py-1 px-2 text-xs text-center w-[8rem] ${getStatusColorClasses(info.getValue() as string)}`}
          >
            {info.getValue() as string}
          </p>
        )
    },
    {
      header: 'Consultant',
      accessorKey: 'consultant'
    },
    {
      header: 'Session Type',
      accessorKey: 'session_type',
      cell: (info) =>
        info.getValue() && (
          <Tooltip className='text-nowrap' title={String(info.getValue())}>
            {String(info.getValue()).length < 30
              ? String(info.getValue())
              : `${String(info.getValue()).slice(0, 22)}`}
            <InfoCircleOutlined className='ml-1' />
          </Tooltip>
        )
    },
    // {
    //   header: 'Invoice Id',
    //   accessorKey: 'pb_invoice_id',
    //   cell: (info) => (
    //     <a
    //       href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}/billing/${info.row.original.pb_invoice_id}/view`}
    //       rel='noopener noreferrer'
    //       target='_blank'
    //       className='flex items-center gap-2 text-nowrap'
    //     >
    //       <p className='underline text-orange underline-offset-4'>
    //         {info.getValue<string>()}
    //       </p>
    //     </a>
    //   )
    // },
    {
      header: 'Order Placed',
      accessorKey: 'placed_order',
      cell: (info) => (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
        >
          {info.getValue() ? 'Yes' : 'No'}
        </p>
      )
    },
    {
      header: 'Associated Invoice',
      accessorKey: 'pb_invoice_id',
      cell: (info) => {
        const { client_record_id, pb_invoice_id } = info.row.original || {};
        const noteStatus = pb_invoice_id ? 'Yes' : 'No';
        const cssClasses = `border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(noteStatus)}`;
        return pb_invoice_id ? (
          <Tooltip title='Go to PB Invoice.'>
            <Link
              to={`https://my.practicebetter.io/#/p/clients/${client_record_id}/billing/${pb_invoice_id}/view`}
              target='_blank'
              className={`${cssClasses} flex flex-row gap-[2px]`}
            >
              <span>{noteStatus}</span>
              <FaExternalLinkAlt className='text-base' />
            </Link>
          </Tooltip>
        ) : (
          <p className={cssClasses}>{noteStatus}</p>
        );
      }
    },
    {
      header: () => 'Payment Date',
      accessorKey: 'pb_invoice_dict.payment_date',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: () => 'Invoice Date',
      accessorKey: 'pb_invoice_dict.date_created',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: 'Associated Form',
      accessorKey: 'placed_order',
      cell: (info) => (
        <p
          className={`border rounded-full py-1 px-2 text-xs text-center w-[3rem] ${getStatusColorClasses(info.getValue() ? 'Yes' : 'No')}`}
        >
          {info.getValue() ? 'Yes' : 'No'}
        </p>
      )
    },
    {
      header: 'Action',
      accessorKey: 'followup.id',
      cell: (info) =>
        info.row.original.id && (
          <div className='flex items-center gap-3 text-base'>
            <Tooltip title='Review details.'>
              <Link
                to={getFormRoute(info)}
                className='text-orange underline underline-offset-4'
              >
                <EyeOutlined className='mr-1 cursor-pointer' />
              </Link>
            </Tooltip>
            {/* <Tooltip title='Go to PB Invoice.'>
              <Link
                to={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}/billing/${info.row.original.pb_invoice_id}/view`}
                className='text-orange underline underline-offset-4'
              >
                <LinkOutlined className='mr-1 cursor-pointer' />
              </Link>
            </Tooltip> */}
            {!customersPageView &&
              info.row.original.followup?.status !== 'Archive' && (
                <PopupConfirm
                  title='Archive Record'
                  description='Are you sure you want to archive?'
                  onConfirm={() =>
                    archiveData({
                      status: 'Archive',
                      ids: [String(info.getValue())]
                    })
                  }
                >
                  <Tooltip title='Move to archives.'>
                    <FolderAddOutlined className='text-orange cursor-pointer text-lg' />
                  </Tooltip>
                </PopupConfirm>
              )}
          </div>
        )
    }
  ];

  // Filter out 'Name' and 'Email' columns if customersPageView
  return customersPageView
    ? allColumns.filter(
        (column) => column.header !== 'Name' && column.header !== 'Email'
      )
    : allColumns;
};
