import React, { useMemo, useState } from 'react';
import { getCookie } from 'utils/cookies';
import Filters from './components/Filters';
import { useParams } from 'react-router-dom';
import Drawer from 'components/drawer/Drawer';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { RowSelectionState } from '@tanstack/react-table';
import HeaderActionButtons from './components/HeaderActionButtons';
import VirtualFollowupsForm from './components/virtualFollowupForm/VirtualFollowupsForm';
import {
  useArchiveData,
  useSyncVirtualFollowups,
  useVirtualFollowups
} from './api/useApi';
import { useHelper } from 'store/components/stateHooks/useHelper';
import { getVirtualFollowups } from './api';
import {
  downloadXLSX,
  reorderObjectKeys,
  transformCSVData
} from 'utils/helpers/csvDownloader';
import paths from 'constants/path';

export default function VirtualFollowups({ isSingle }: { isSingle?: boolean }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const { id } = useParams();
  const handleSuccess = () => {
    setRowSelection({});
  };

  const { updateHelper } = useHelper();
  const role = getCookie('role');
  const pbConsultantId = getCookie('pb_consultant_id');
  const default_pb_consultant_id =
    role === 'Super Admin' || !pbConsultantId || pbConsultantId === 'null'
      ? ''
      : String(pbConsultantId);

  const [virtualFollowupsFilters, setVirtualFollowupsFilters] = useState({
    first_name: '',
    last_name: '',
    email: '',
    status: 'Pending',
    consultant_id: default_pb_consultant_id,
    session_date: [],
    form_completed: '',
    session_exists: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });
  const [downloadingData, setDownloadingData] = useState(false);
  const downloadVFsCSV = async () => {
    const keyOrder = [
      'name',
      'email',
      'ai_processed',
      'completed',
      'completed_at',
      'client_record_id',
      'form_name',
      'form_pb_link',
      'plan_name',
      'consultant',
      'status',
      'reviewed_at',
      'order_date',
      'session_note_id',
      'order_note_id',
      'session_exists'
    ];
    setDownloadingData(true);
    let response = await getVirtualFollowups(
      currentPage,
      virtualFollowupsFilters,
      sortingDetails,
      true
    );
    if (response) {
      const reorderedResponse = reorderObjectKeys(response, keyOrder);
      downloadXLSX(
        transformCSVData(reorderedResponse),
        `${virtualFollowupsFilters?.session_date?.[0] ? `VFs-${virtualFollowupsFilters?.session_date?.[0]} to ${virtualFollowupsFilters?.session_date?.[1]}` : 'VFs'}`
      );
    }
    setDownloadingData(false);
  };

  const { data: VirtualFollowupsData, isLoading } = useVirtualFollowups(
    currentPage,
    virtualFollowupsFilters,
    sortingDetails
  );
  const { isLoading: isSyncPending, refetch: syncVirtualFollowupsForms } =
    useSyncVirtualFollowups();
  const { mutateAsync: archiveData, isPending: isArchivePending } =
    useArchiveData({
      queryKeys: ['virtual_followups'],
      onSuccess: handleSuccess
    });

  const virtualFollowUpsMemorizedData = useMemo(() => {
    return VirtualFollowupsData?.form_requests ?? [];
  }, [VirtualFollowupsData?.form_requests]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between'>
          <h1 className='text-xl font-semibold'>Virtual Follow-ups</h1>
          <HeaderActionButtons
            archiveData={archiveData}
            selectedRows={selectedRows}
            isSyncPending={isSyncPending}
            isArchivePending={isArchivePending}
            syncVirtualFollowupsForms={syncVirtualFollowupsForms}
            downloadingData={downloadingData}
            downloadVFsCSV={downloadVFsCSV}
          />
        </div>
        <Filters
          role={role}
          setCurrentPage={setCurrentPage}
          virtualFollowupsFilters={virtualFollowupsFilters}
          setVirtualFollowupsFilters={setVirtualFollowupsFilters}
          default_pb_consultant_id={default_pb_consultant_id}
        />
        <div className='overflow-auto h-[calc(100vh_-_350px)]'>
          <DataTable
            isLoading={isLoading}
            data={virtualFollowUpsMemorizedData}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            columns={Columns(
              archiveData,
              setSortingDetails,
              sortingDetails,
              updateHelper
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={VirtualFollowupsData?.pagination.total_count}
        totalPage={VirtualFollowupsData?.pagination.total_pages}
        perPage={VirtualFollowupsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <Drawer
        styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
        width='90%'
        open={Boolean(id && isSingle)}
        button={null}
        closeIcon={null}
      >
        <VirtualFollowupsForm
          formId={String(id)}
          isSingle={Boolean(isSingle)}
          backUrl={paths.virtualFollowups}
        />
      </Drawer>
    </div>
  );
}
