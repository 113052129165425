import React from 'react';
import Tooltip from 'components/tooltip/Tooltip';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';

export default function AmountMatchIndicator({
  isMatched,
  paidAmount,
  orderSource
}: {
  isMatched?: any;
  paidAmount?: any;
  orderSource?: string;
}) {
  return isMatched ? (
    <Tooltip title={`Amount matched, invoice paid amount is $${paidAmount}.`}>
      <CheckCircleOutlined className='text-green text-xl' />
    </Tooltip>
  ) : orderSource === 'System Generated - IC' ? (
    <Tooltip
      title={`Amount is not matched, invoice paid amount is ${paidAmount ? `$${paidAmount}` : 'not available.'}.`}
    >
      <CloseCircleOutlined className='text-red text-xl' />
    </Tooltip>
  ) : (
    <Tooltip
      title={`No info available, invoice paid amount is ${paidAmount ? `$${paidAmount}` : 'not available.'}.`}
    >
      <InfoCircleOutlined className='text-yellow-500 text-xl' />
    </Tooltip>
  );
}
