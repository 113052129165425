export const sessionTypeOptions = [
  {
    label:
      'Virtual Forms Deadline – No In-Person Attendance. Adjustments will be sent in the app',
    value:
      'Virtual Forms Deadline – No In-Person Attendance. Adjustments will be sent in the app'
  },
  { label: 'F/u - Self-pay', value: 'F/u - Self-pay' },
  { label: 'Initial Consultation', value: 'Initial Consultation' },
  {
    label:
      'Initial Consultation (Virtual)– No In-Person Attendance. Adjustments will be sent in the app',
    value:
      'Initial Consultation (Virtual)– No In-Person Attendance. Adjustments will be sent in the app'
  },
  {
    label: 'Weight Loss Program - Insurance',
    value: 'Weight Loss Program - Insurance'
  },
  {
    label: 'Initial Consultation - Insurance',
    value: 'Initial Consultation - Insurance'
  },
  { label: 'Virtual Forms Deadline', value: 'Virtual Forms Deadline' },
  {
    label: 'Weight Loss Program (delivered medications)',
    value: 'Weight Loss Program (delivered medications)'
  },
  { label: 'Initial consultation', value: 'Initial consultation' },
  {
    label: 'Initial consultation- Self-pay',
    value: 'Initial consultation- Self-pay'
  },
  { label: 'Virtual check-in', value: 'Virtual check-in' },
  {
    label: 'Monthly Weight Loss Program Membership',
    value: 'Monthly Weight Loss Program Membership'
  },
  {
    label: 'Access Zappy - Initial Consult/Follow Up',
    value: 'Access Zappy - Initial Consult/Follow Up'
  },
  {
    label:
      'Weight Loss Program (Insurance, Medication pending insurance approval)',
    value:
      'Weight Loss Program (Insurance, Medication pending insurance approval)'
  },
  {
    label: 'Initial consultation- Insurance',
    value: 'Initial consultation- Insurance'
  },
  {
    label: 'Monthly Weight Loss Membership-insurance',
    value: 'Monthly Weight Loss Membership-insurance'
  },
  { label: 'F/u - Insurance', value: 'F/u - Insurance' },
  { label: 'Weight loss program', value: 'Weight loss program' },
  {
    label: 'Access Zappy Bi-weekly Progress Check-ins',
    value: 'Access Zappy Bi-weekly Progress Check-ins'
  },
  {
    label:
      'Virtual Initial Consultation – No In-Person Attendance. Adjustments will be sent in the app',
    value:
      'Virtual Initial Consultation – No In-Person Attendance. Adjustments will be sent in the app'
  },
  {
    label: 'Initial Consultation - Self Pay',
    value: 'Initial Consultation - Self Pay'
  },
  {
    label: 'Monthly Weight Loss Program Membership with medications',
    value: 'Monthly Weight Loss Program Membership with medications'
  },
  { label: 'Follow Up Appointment', value: 'Follow Up Appointment' },
  {
    label: 'Monthly Weight Loss Membership- self pay',
    value: 'Monthly Weight Loss Membership- self pay'
  },
  {
    label: 'Online monthly check-in deadline: Email only (no phone or video)',
    value: 'Online monthly check-in deadline: Email only (no phone or video)'
  }
];
