import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import {
  getVirtualFollowup,
  getVirtualFollowups,
  handleArchiveData,
  handleRefreshAIPrompts,
  handleSendEmail,
  handleSessionNotes,
  handleUpdateReviewedVFs,
  handleUpdateVirtualFollowups,
  syncVirtualFollowups
} from '.';
import {
  VirtualFollowupsFilter,
  VirtualFollowupsFormInputs
} from 'types/virtualFollowups';

// virtual followups
export const useVirtualFollowups = (
  currentPage: number,
  followupsFilters: VirtualFollowupsFilter,
  sortingDetails?: any
) => {
  return useQuery({
    queryKey: [
      'virtual_followups',
      currentPage,
      followupsFilters,
      sortingDetails
    ],
    queryFn: () =>
      getVirtualFollowups(currentPage, followupsFilters, sortingDetails)
  });
};

// virtual followup
export const useVirtualFollowup = (id: string | null, isSingle: boolean) => {
  return useQuery({
    queryKey: ['virtual_followup', id],
    queryFn: () => getVirtualFollowup(String(id)),
    enabled: Boolean(isSingle && id)
  });
};

// syncing virtual followups
export const useSyncVirtualFollowups = () => {
  return useQuery({
    queryKey: ['sync_follow-ups'],
    queryFn: () => syncVirtualFollowups('6517100f59761126ff10ba53'),
    enabled: false
  });
};

// archiving data
export const useArchiveData = (option?: {
  queryKeys?: string[];
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => handleArchiveData(data),
    onSuccess: () => {
      option?.onSuccess && option.onSuccess();
      queryClient.invalidateQueries({
        queryKey: option?.queryKeys || []
      });
      queryClient.refetchQueries({
        queryKey: option?.queryKeys || ['virtual_followup', 'pb_sessions']
      });
    }
  });
};

// update virtual followups
export const useUpdateVirtualFollowups = (
  id: string,
  queryKeys?: string[]
): UseMutationResult<any, unknown, VirtualFollowupsFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: VirtualFollowupsFormInputs) =>
      handleUpdateVirtualFollowups(data, String(id)),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({
          queryKey: ['virtual_followup']
        });
      }
    }
  });
  return mutation;
};

export const useUpdateReviewedVFs = (
  id: string
): UseMutationResult<any, unknown, VirtualFollowupsFormInputs, unknown> => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: VirtualFollowupsFormInputs) =>
      handleUpdateReviewedVFs(data, String(id)),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({
          queryKey: ['virtual_followups']
        });
        queryClient.refetchQueries({ queryKey: ['virtual_followups'] });
      }
    }
  });
  return mutation;
};

// refresh AI prompts
export const useRefreshAIPrompts = (options?: {
  onSuccess?: (data: any, type: string) => void;
  id?: String;
}): UseMutationResult<any, unknown, string, unknown> => {
  return useMutation({
    mutationFn: (columnName: string) =>
      handleRefreshAIPrompts(columnName, String(options?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        options?.onSuccess?.(data, 'promptRefreshed');
      }
    }
  });
};

// creating session notes
export const useCreateSessionNotes = (options?: {
  onSuccess?: (data: any, type?: string) => void;
  id: String;
}): UseMutationResult<any, unknown, { status?: string }, unknown> => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: { status?: string }) =>
      handleSessionNotes(data, String(options?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['virtual_followups'] });
        queryClient.refetchQueries({ queryKey: ['virtual_followups'] });
        options?.onSuccess?.(data, 'sessionCreated');
      }
    }
  });

  return mutation;
};

// send email
export const useSendEmail = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (Id: string) => handleSendEmail(Id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['send_email'] });
      queryClient.refetchQueries({ queryKey: ['virtual_followups'] });
    }
  });
};
