import React, { useMemo, useState } from 'react';
import Filters from './components/Filters';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import HeaderActionButtons from './components/HeaderActionButtons';
import {
  useDeleteDiscount,
  useDiscounts,
  useUpdateDiscount
} from './api/useApi';

export default function Discounts() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingDiscountId, setProcessingDiscountId] = useState('');
  const [discountsFilters, setDiscountsFilters] = useState({
    name: '',
    code: '',
    amount: '',
    status: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });
  const { data: discountsData, isLoading } = useDiscounts(
    currentPage,
    discountsFilters,
    sortingDetails
  );
  const { mutateAsync: deleteDiscount, isPending: isDeletePending } =
    useDeleteDiscount();
  const { mutateAsync: updateDiscount } = useUpdateDiscount();
  const memoizedDiscountsData = useMemo(() => {
    return discountsData?.discounts ?? [];
  }, [discountsData?.discounts]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between mt-3'>
          <h1 className='text-xl font-semibold mb-2'>Discounts</h1>
          <HeaderActionButtons />
        </div>
        <Filters
          setCurrentPage={setCurrentPage}
          discountsFilters={discountsFilters}
          setDiscountsFilters={setDiscountsFilters}
        />
        <div className='overflow-auto h-[calc(100vh_-_350px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedDiscountsData}
            columns={Columns(
              deleteDiscount,
              isDeletePending,
              updateDiscount,
              processingDiscountId,
              setProcessingDiscountId,
              sortingDetails,
              setSortingDetails
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={discountsData?.pagination.total_count}
        totalPage={discountsData?.pagination.total_pages}
        perPage={discountsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
