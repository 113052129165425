import { Modal } from 'antd';
import { useState } from 'react';
import { FaSort } from 'react-icons/fa';
import formatDate from 'utils/helpers/date';
import { EyeOutlined } from '@ant-design/icons';
import { ColumnDef } from '@tanstack/react-table';
import DocumentViewer from 'components/documentViewer/DocumentViewer';
import CopyToClipboard from 'components/copyToClipboard/CopyToClipBoard';
import { DocumentsResponse, DocumentsSortingDetails } from 'types/documents';

export const Columns = (
  sortingDetails: DocumentsSortingDetails,
  setSortingDetails: any,
  clientId?: string
): ColumnDef<DocumentsResponse>[] => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [processingId, setProcessingId] = useState('');
  const allColumns: ColumnDef<DocumentsResponse>[] = [
    {
      header: () => (
        <div className='flex items-center'>
          <span className='px-1 text-nowrap'>Created At</span>
          <FaSort
            className='cursor-pointer'
            onClick={() =>
              setSortingDetails({
                sort_by: 'created_at',
                sort_order:
                  sortingDetails?.sort_by === 'created_at' &&
                  sortingDetails?.sort_order === 'desc'
                    ? 'asc'
                    : 'desc'
              })
            }
          />
        </div>
      ),
      accessorKey: 'created_at',
      cell: (info) => formatDate(info.getValue<string>())
    },
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Email',
      accessorKey: 'email'
    },
    {
      header: 'File Name',
      accessorKey: 'file_name'
    },
    {
      header: 'Url',
      accessorKey: 'url',
      cell: (info) => (
        <CopyToClipboard fieldName='Url'>
          {info.row.original.url.slice(0, 40)}
        </CopyToClipboard>
      )
    },
    {
      header: 'Resource',
      accessorKey: 'resource'
    },
    {
      header: 'Updated At',
      accessorKey: 'updated_at',
      cell: (info) => formatDate(info.getValue() as string)
    },
    {
      header: 'Action',
      accessorKey: '',
      cell: (info) => {
        const fileUrl = info.row.original.url;
        return (
          <>
            <EyeOutlined
              className='text-orange'
              onClick={() => {
                setIsModalOpen(true);
                setProcessingId(info.row.original.id);
              }}
            />
            <Modal
              title='Document'
              open={isModalOpen && info.row.original.id === processingId}
              onCancel={() => {
                setIsModalOpen(false);
                setProcessingId('');
              }}
              footer={null}
            >
              <DocumentViewer fileUrl={fileUrl} />
            </Modal>
          </>
        );
      }
    }
  ];
  // Filter out 'Name' and 'Email' columns if customersPageView
  return clientId
    ? allColumns.filter(
        (column) => column.header !== 'Name' && column.header !== 'Email'
      )
    : allColumns;
};
