import { FollowupsFilter } from 'types/follow-ups';
import { request } from 'utils/api';

export const getPbSessions = async (
  currentPage: number,
  params?: FollowupsFilter,
  sortingDetails?: any,
  id?: string,
  isDownload?: boolean
) => {
  const data = await request({
    url: '/pb-sessions',
    method: 'GET',
    params: {
      client_id: id,
      page: currentPage,
      per_page: id ? 10 : null,
      ...(id ? {} : params),
      ...sortingDetails,
      download: isDownload
    }
  });
  return data;
};
