import React, { useMemo, useState } from 'react';
import Filters from './components/Filters';
import { useParams } from 'react-router-dom';
import Drawer from 'components/drawer/Drawer';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { useAutomateInvoice, useInvoices } from './api/useApi';
import HeaderActionButtons from './components/HeaderActionButtons';
import InitialConsultationsForm from 'pages/initialConsultations/components/initialConsultationForm/InitialConsultationsForm';
import VirtualFollowupsForm from 'pages/settings/pages/virtualFollowups/components/virtualFollowupForm/VirtualFollowupsForm';
import { useHelper } from 'store/components/stateHooks/useHelper';
import { useInvoicesSlice } from 'store/components/stateHooks/useInvoicesSlice';
import paths from 'constants/path';

export default function InvoicesPage({
  isSingle,
  clientId
}: {
  isSingle?: boolean;
  clientId?: string;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingId, setProcessingId] = useState('');
  const { form_id, form_type } = useParams();
  const { updateHelper } = useHelper();
  const {
    invoicesSlice: { filters }
  } = useInvoicesSlice();

  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const { data: invoicesData, isLoading } = useInvoices(
    currentPage,
    filters,
    sortingDetails,
    clientId
  );
  const { mutateAsync: automateInvoice, isPending: isAutomationPending } =
    useAutomateInvoice();

  const packages = useMemo(() => {
    return invoicesData?.invoices ?? [];
  }, [invoicesData?.invoices]);

  return (
    <div className={`${clientId ? 'bg-white pt-5' : 'p-5 bg-[#FAFBFC]'}`}>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between mb-3'>
          <h1 className='text-xl font-semibold mb-2'>Invoices</h1>
          {!clientId && <HeaderActionButtons />}
        </div>
        {!clientId && <Filters setCurrentPage={setCurrentPage} />}
        <div
          className={`overflow-auto ${clientId ? 'h-[calc(100vh_-_440px)]' : 'h-[calc(100vh_-_320px)]'}`}
        >
          <DataTable
            isLoading={isLoading}
            data={packages}
            columns={Columns(
              automateInvoice,
              isAutomationPending,
              setProcessingId,
              processingId,
              setSortingDetails,
              sortingDetails,
              clientId,
              updateHelper
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={invoicesData?.pagination.total_count}
        totalPage={invoicesData?.pagination.total_pages}
        perPage={invoicesData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Drawer
        styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
        width='90%'
        open={Boolean(isSingle)}
        button={null}
        closeIcon={null}
      >
        {form_type === 'ic' ? (
          <InitialConsultationsForm
            isSingle={isSingle}
            formId={String(form_id)}
            backUrl={
              clientId ? `/customers/${clientId}/billings` : paths.invoices
            }
          />
        ) : (
          <VirtualFollowupsForm
            isSingle={Boolean(isSingle)}
            formId={String(form_id)}
            backUrl={clientId ? paths.billingsTab() : paths.invoices}
          />
        )}
      </Drawer>
    </div>
  );
}
