import React, { useMemo, useState } from 'react';
import { useEmailLogs } from './api/useApi';
import { Columns } from './components/Columns';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { useParams } from 'react-router-dom';
import Filters from './components/Filters';

export default function EmailLogsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();
  const [emailLogFilters, setEmailLogFilters] = useState({
    name: '',
    status: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'desc'
  });

  const { data: emailLogsData, isLoading } = useEmailLogs(
    currentPage,
    emailLogFilters,
    sortingDetails,
    id
  );

  const memoizedEmailLogsData = useMemo(() => {
    return emailLogsData?.email_logs ?? [];
  }, [emailLogsData?.email_logs]);

  return (
    <div className={`${id ? 'bg-white pt-5' : 'p-5 bg-[#FAFBFC]'}`}>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Email Logs</h1>
        {!id && (
          <Filters
            setFilters={setEmailLogFilters}
            setCurrentPage={setCurrentPage}
            filters={emailLogFilters}
          />
        )}
        <div
          className={`overflow-auto ${id ? 'h-[calc(100vh_-_280px)]' : 'h-[calc(100vh_-_350px)]'}`}
        >
          <DataTable
            isLoading={isLoading}
            data={memoizedEmailLogsData}
            columns={Columns(sortingDetails, setSortingDetails)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={emailLogsData?.pagination.total_count}
        totalPage={emailLogsData?.pagination.total_pages}
        perPage={emailLogsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
