import React from 'react';
import { useParams } from 'react-router-dom';
import Followups from 'pages/followups/Followups';
import InitialConsultations from 'pages/initialConsultations/InitialConsultations';

export default function FormsTab() {
  const { id, form_id, form_type } = useParams();
  return (
    <div>
      <InitialConsultations
        clientId={id}
        isSingle={Boolean(form_id && form_type === 'ic')}
      />
      <Followups
        isSingle={Boolean(form_id && form_type === 'vf')}
        customersPageView={true}
        clientId={id}
      />
    </div>
  );
}
