import { Image, Alert } from 'antd';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

export interface DocumentViewerProps {
  fileUrl: string;
  style?: React.CSSProperties;
  className?: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({
  fileUrl,
  style,
  className
}) => {
  const defaultLayout = defaultLayoutPlugin();

  const renderContent = () => {
    if (!fileUrl)
      return <Alert message='No file selected' type='info' showIcon />;

    const fileType = fileUrl.split('.').pop()?.toLowerCase();

    if (fileType === 'pdf') {
      return (
        <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js'>
          <div style={{ maxWidth: '50vh', maxHeight: '40vh' }}>
            <Viewer fileUrl={fileUrl} plugins={[defaultLayout]} />
          </div>
        </Worker>
      );
    }

    if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'].includes(fileType || '')) {
      return (
        <Image
          src={fileUrl}
          alt='Document Preview'
          style={{ maxWidth: '50vh', maxHeight: '40vh' }}
        />
      );
    }

    return <Alert message='Unsupported file format' type='error' showIcon />;
  };

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '40vh',
        maxHeight: '40vh',
        minWidth: '56vh',
        maxWidth: '60vh',
        ...style
      }}
    >
      {renderContent()}
    </div>
  );
};

export default DocumentViewer;
