import React from 'react';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import { Label } from 'components/label/Label';
import Button from 'components/buttons/Button';
import { SearchOutlined } from '@ant-design/icons';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { DiscountsFilterInput } from 'types/discounts';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';

type FiltersProps = {
  setDiscountsFilters: (data: DiscountsFilterInput) => void;
  setCurrentPage: (page: number) => void;
  discountsFilters: Partial<DiscountsFilterInput>;
};

export default function Filters({
  setDiscountsFilters,
  setCurrentPage,
  discountsFilters
}: FiltersProps) {
  const { control, handleSubmit, reset } = useForm<DiscountsFilterInput>({
    defaultValues: {
      ...discountsFilters
    }
  });

  const onSubmit: SubmitHandler<DiscountsFilterInput> = (data) => {
    setDiscountsFilters(data);
    setCurrentPage(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='filters flex gap-6 items-center mb-5'>
        <div className='w-40'>
          <Label className='text-sm !font-normal m-1'>Name</Label>
          <Controller
            name='name'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Code</Label>
          <Controller
            name='code'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Amount</Label>
          <Controller
            name='amount'
            control={control}
            render={({ field }) => (
              <Input type='text' id='name' className='h-[2rem]' {...field} />
            )}
          />
        </div>
        <div>
          <Label className='text-sm !font-normal m-1'>Status</Label>
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className='w-[160px] h-[2rem]'
                options={[
                  { label: 'Active', value: 'true' },
                  { label: 'Inactive', value: 'false' }
                ]}
              />
            )}
          />
        </div>
        <Button type='submit' className='w-max px-3.5 h-[38px] mt-6'>
          <SearchOutlined className='!text-white' />
        </Button>
        <button
          onClick={() =>
            reset({
              name: '',
              code: '',
              status: '',
              amount: ''
            })
          }
          className='border border-orange rounded py-2 px-3 mt-6'
        >
          <MdOutlineFilterAltOff className='text-orange' />
        </button>
      </div>
    </form>
  );
}
