import React from 'react';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';

export default function PharmacyOrderDetails({
  orderDetails,
  pharmacy,
  address
}: {
  orderDetails: any;
  pharmacy: any;
  address: any;
}) {
  const formatAddress = () => {
    if (!address) return null;
    if (['Boothwyn'].includes(pharmacy)) {
      return (
        <span>
          {address.address1}
          {address.address2 ? `, ${address.address2}` : ''}, {address.city},{' '}
          {address.state} {address.zipCode}
        </span>
      );
    } else {
      return (
        <span>
          {address.addressLine1}
          {address.addressLine2 ? `, ${address.addressLine2}` : ''},{' '}
          {address.city}, {address.state} {address.zipCode}
          {address.country &&
          address.country !== 'US' &&
          address.country !== 'USA'
            ? `, ${address.country}`
            : ''}
        </span>
      );
    }
  };

  return (
    <div>
      <div className='mb-3 px-2 border border-gray-200 rounded-lg bg-gray-50 mx-[-2rem] w-full py-2'>
        <p className='flex items-center'>
          <span className='font-bold mr-2'>Shipping Address:</span>
          {formatAddress()}
        </p>
      </div>

      <div className='ml-[-2rem] overflow-auto h-[20rem]'>
        {orderDetails?.map((detail: any, index: number) => {
          return ['Boothwyn'].includes(pharmacy) ? (
            <p
              key={index}
              className='my-1 border p-2 bg-light-orange rounded-lg'
            >
              <b>SKU:</b> {detail.sku}
              <br />
              <b>Instructions:</b> {detail.instructions}
              <br /> <b>Notes:</b> {detail.notes}
            </p>
          ) : (
            <p
              key={index}
              className='my-1 border p-2 bg-light-orange rounded-lg'
            >
              <b>Dose:</b> {detail.dose} mg
              <br />
              <b>Drug Name:</b> {detail.drugName}
              <br /> <b>Drug Strength:</b> {detail.drugStrength}
              <br />
              <b>Quantity:</b> {detail.quantity}{' '}
              {capitalizeFirstLetter(detail.quantityUnits)}
              <br />
              <b>Directions:</b> {detail.directions}
            </p>
          );
        })}
      </div>
    </div>
  );
}
