import Tab from 'components/tab/Tab';
import Sidebar from './components/Sidebar';
import { Outlet, useLocation, useParams } from 'react-router-dom';

export default function CustomerPage({ formType }: { formType?: string }) {
  const { pathname } = useLocation();
  const { id } = useParams();
  const items = [
    {
      parent: 'customers',
      key: `customers/${id}/documents`,
      label: 'Documents'
    },
    {
      parent: 'customers',
      key: `customers/${id}/billings`,
      label: 'Orders & Invoices'
    },
    {
      parent: 'customers',
      key: `customers/${id}/forms`,
      label: 'ICs & Follow-ups'
    },
    {
      parent: 'customers',
      key: `customers/${id}/sms-logs`,
      label: 'Sms Logs'
    },
    {
      parent: 'customers',
      key: `customers/${id}/email-logs`,
      label: 'Email Logs'
    }
  ];

  return (
    <div className='flex flex-row py-4 pl-5 gap-3'>
      <div className='w-[20%]'>
        <Sidebar />
      </div>
      <div className='w-[80%]'>
        <div className='sticky top-[58px] z-50 bg-white'>
          <Tab
            items={items}
            defaultActiveKey='documents'
            activeKey={`${pathname.split('/')[1]}/${pathname.split('/')[2]}/${pathname.split('/')[3]}`}
          />
        </div>
        <div className='h-[40rem] overflow-auto p-4'>
          <Outlet context={{ formType }} />
        </div>
      </div>
    </div>
  );
}
