import React, { useMemo, useState } from 'react';
import ReportPage from '../../ReportPage';
import Filters from './components/Filters';
import { useICReports } from './api/useApi';
import { Columns } from './components/Columns';
import ICAnalytics from './components/ICAnalytics';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { getICReports } from './api';
import {
  downloadXLSX,
  reorderObjectKeys,
  transformCSVData
} from 'utils/helpers/csvDownloader';

export default function ICReportsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [icReportsFilters, setICReportsFilters] = useState({
    reviewed_at: [],
    consultant_id: '',
    email: '',
    ai_processed: '',
    has_pp: '',
    completed: '',
    has_package: '',
    has_sessions: '',
    has_order: '',
    invoice_status: '',
    has_order_note: '',
    has_reviewed: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'reviewed_at',
    sort_order: 'desc'
  });
  const [downloadingData, setDownloadingData] = useState(false);

  const downloadTasksCSV = async () => {
    const keyOrder = [
      'name',
      'email',
      'created_at',
      'completed_at',
      'ai_processed',
      'reviewed_at',
      'consultant',
      'client_record_id',
      'form_request_id'
    ];
    setDownloadingData(true);
    let response = await getICReports(
      currentPage,
      icReportsFilters,
      sortingDetails,
      true
    );
    if (response) {
      const reorderedResponse = reorderObjectKeys(response, keyOrder);
      downloadXLSX(
        transformCSVData(reorderedResponse),
        `${icReportsFilters?.reviewed_at?.[0] ? `IC Reports-${icReportsFilters?.reviewed_at?.[0]} to ${icReportsFilters?.reviewed_at?.[1]}` : 'IC Reports'}`
      );
    }
    setDownloadingData(false);
  };

  const { data: ICReportsData, isLoading } = useICReports(
    currentPage,
    icReportsFilters,
    sortingDetails
  );
  const memoizedUPSLogsData = useMemo(() => {
    return ICReportsData?.ics ?? [];
  }, [ICReportsData?.ics]);

  return (
    <ReportPage name='ic_reports'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5 shadow-xl mb-4'>
        <div className='flex flex-row justify-between'>
          <h1 className='text-xl font-semibold'>
            Initial Consultation's Report
          </h1>
          <button
            disabled={downloadingData}
            className={`border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 ${downloadingData && 'opacity-50'}`}
            onClick={downloadTasksCSV}
          >
            {downloadingData ? 'Downloading...' : 'Download'}
          </button>
        </div>
        <ICAnalytics
          analytics_data={ICReportsData?.analytics_data}
          dataLoading={isLoading}
        />
        <Filters
          setCurrentPage={setCurrentPage}
          icReportsFilters={icReportsFilters}
          setICReportsFilters={setICReportsFilters}
        />
        <div className='overflow-auto h-[calc(100vh_-_415px)]'>
          <DataTable
            isLoading={isLoading}
            data={memoizedUPSLogsData}
            columns={Columns(sortingDetails, setSortingDetails)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={ICReportsData?.pagination.total_count}
        totalPage={ICReportsData?.pagination.total_pages}
        perPage={ICReportsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </ReportPage>
  );
}
